'use client';

import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { cx } from '@/core/utils';
import { formatTime, useArenaStore } from '@/game';

type AttackTimerScaleProps = {
  expiry: number | undefined;
  title?: string;
  className?: string;
};

const MAX_TIME = 120;

export function AttackTimer(props: AttackTimerScaleProps) {
  const { expiry, title = 'Attack time', className } = props;
  const [timer, setTimer] = useState(MAX_TIME);

  const { setIsExpired } = useArenaStore();

  let intervalId: string | number | NodeJS.Timeout | undefined;

  useEffect(() => {
    if (!expiry) {
      return setTimer(0);
    }

    if (!timer) {
      const timeLeft = dayjs(expiry * 1000).diff(dayjs(), 'second');

      if (timeLeft > 10) {
        setIsExpired(false);

        return setTimer(timeLeft);
      }

      if (timeLeft <= 0) {
        setTimeout(() => {
          setIsExpired(true);
        }, 2000);
      }

      return setTimer(0);
    }

    const timeLeft = dayjs(expiry * 1000).diff(dayjs(), 'second');

    if (timeLeft <= 0) {
      setTimer(0);

      return setIsExpired(true);
    }

    intervalId = setInterval(() => {
      const timeLeft = dayjs(expiry * 1000).diff(dayjs(), 'second');

      setIsExpired(false);
      setTimer(timeLeft);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer, expiry]);

  const elapsedTime = Math.floor((MAX_TIME - timer) / (MAX_TIME / 50));
  const remainingTime = 50 - elapsedTime;

  return (
    <div className={cx('relative mb-4 mt-2.5 w-full', 'tall:mb-7 tall:mt-5', className)}>
      <AttackTimerFrame />

      <div className={cx('absolute top-[30%] flex h-[30%] w-full px-[4%]')}>
        <div className={cx('item-stretch grid w-full grid-cols-[repeat(50,1fr)] gap-0.5')}>
          {[...Array(Math.max(remainingTime, 0))].map((_, i) => (
            <div
              key={i}
              className={cx('bg-red-900 glow-red', timer <= 60 && 'animate-pulse')}
            ></div>
          ))}
          {[...Array(Number.isFinite(elapsedTime) ? Math.max(elapsedTime, 0) : 0)].map((_, i) => (
            <div key={i} className={cx('bg-red-900 opacity-20')}></div>
          ))}
        </div>
      </div>

      <div
        className={cx(
          'absolute bottom-0 flex h-[33%] items-center text-[0.5rem]',
          title.includes('Defence') ? 'right-[1%]' : 'right-[4%]',
          '2xl:right-[4%]'
        )}
      >
        {`${title}: ${formatTime(timer)}`}
      </div>
    </div>
  );
}

export function AttackTimerFrame() {
  return (
    <svg
      width="315"
      height="41"
      viewBox="0 0 315 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('h-auto w-full')}
    >
      <path
        d="M314.597 40.6654H11.3977C11.301 40.6654 11.1882 40.6179 11.1237 40.5545L0.112849 29.7347C0.112849 29.7347 0 29.5605 0 29.4654V1.0456C0 0.839659 0.177335 0.665405 0.386911 0.665405H314.613C314.823 0.665405 315 0.839659 315 1.0456V40.2852C315 40.4912 314.823 40.6654 314.613 40.6654H314.597ZM11.5428 39.905H314.21V1.4258H0.773822V29.307L11.559 39.905H11.5428Z"
        fill="#991D26"
      />
      <path
        opacity="0.18"
        d="M13.3184 35.5938L5.04822 27.4513V5.62439H309.956V35.7076L13.3184 35.5938Z"
        fill="#991D26"
      />
      <path
        d="M272.57 26.8524H217.29C213.76 26.8524 210.374 28.2306 207.876 30.6861L199.46 38.9554V40.3019H314.455V26.8682L272.57 26.8524Z"
        fill="#991D26"
      />
      <path d="M314.616 1.04626H305.717V3.96112H314.616V1.04626Z" fill="#991D26" />
    </svg>
  );
}
