'use client';

import Image, { StaticImageData } from 'next/image';
import { useRouter } from 'next/navigation';
import * as React from 'react';

import { useAccount } from '@/core/hooks/useAccount';
import cyberspace from '@/assets/images/backgrounds/cyberspace.png';
import { routes, ZERO_ADDRESS } from '@/core/lib';
import { truncAddress } from '@/core/utils';
import { Background, GameSearchFooterIcon, GameSearchHeaderIcon, Main } from '@/ui';
import { blurIfStatic } from '@/utils/images';
import hacking from '@/assets/images/gifs/hacking.gif';
import {
  GameStatuses,
  useAudio,
  useCancelGame,
  useExpireGame,
  useGame,
  usePlayer,
} from '@/core/hooks';
import binanceArena from '@/assets/images/arenas/binance.jpg';
import eth from '@/assets/images/arenas/eth.jpg';
import mcd from '@/assets/images/arenas/mcd.jpg';
import sec from '@/assets/images/arenas/sec.jpg';
import twitter from '@/assets/images/arenas/twitter.jpg';
import binanceBg from '@/assets/images/arenas/backgrounds/binance-bg.png';
import mcdBg from '@/assets/images/arenas/backgrounds/mcd-bg.png';
import secBg from '@/assets/images/arenas/backgrounds/sec-bg.png';
import twitterBg from '@/assets/images/arenas/backgrounds/twitter-bg.png';
import ethBg from '@/assets/images/arenas/backgrounds/eth-bg.png';
import { useSettingsStore } from '@/play/store';
import { useWinsAndLosses } from '@/core/hooks/useWinsAndLosses';

import { GameCancelModal } from './GameCancelModal';
import { GenericUserAvatar } from './UserAvatar';
import { Avatar } from './Avatar';

import { useArenaStore, useGameEventsStore } from '../store';

export type Arena = {
  id: number;
  logo: StaticImageData;
  name: string;
  background: StaticImageData;
  audio: string;
};

export const ARENAS: Arena[] = [
  { id: 1, logo: twitter, name: 'twitter', background: twitterBg, audio: '/audio/twitter-bg.mp3' },
  { id: 2, logo: mcd, name: 'mc donalds', background: mcdBg, audio: '/audio/mcd-bg.mp3' },
  {
    id: 3,
    logo: binanceArena,
    name: 'binance hq',
    background: binanceBg,
    audio: '/audio/binance-bg.mp3',
  },
  { id: 4, logo: sec, name: 'sec', background: secBg, audio: '/audio/sec-bg.mp3' },
  { id: 5, logo: eth, name: 'eth foundation', background: ethBg, audio: '/audio/eth-bg.mp3' },
];

type State =
  | {
      hasFoundOpponent: false;
    }
  | {
      hasFoundOpponent: true;
      countdown: number;
    };

type Action = 'FOUND_OPPONENT' | 'COUNTDOWN';

export default function Search() {
  const router = useRouter();
  const { address } = useAccount();
  const {
    activeGameId,
    setActiveArenaId,
    setActiveGameId,
    setPlayerId,
    setIsSearching,
    playerId,
    activeBlind,
  } = useArenaStore();
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);
  const { gameStarted } = useGameEventsStore();

  const { status, arena } = useGame({ gameId: activeGameId || 0, shouldWatch: true });
  const searchAudio = useAudio({ src: '/audio/search-enemy.mp3', volume: 0.5, loop: true });
  const foundOpponentAudio = useAudio({ src: '/audio/found-opponent.mp3#t=1.5', volume: 0.5 });
  const { isSfx } = useSettingsStore();

  const { playerTwo, playerOne } = usePlayer({
    gameId: activeGameId || 0,
    shouldWatch: true,
  });

  const opponentAddress = address === playerOne! ? playerTwo! : playerOne!;

  const { data: winsAndLossesData } = useWinsAndLosses({
    playerAddress: opponentAddress,
    isEnabled: !!address && !!playerOne && !!playerTwo && opponentAddress !== ZERO_ADDRESS,
  });

  const {
    write: exitGame,
    isSuccess: isExitGameSuccess,
    isLoading: isExitGameLoading,
  } = useCancelGame({
    gameId: activeGameId || 0,
    status,
  });

  const { write: expireGame } = useExpireGame({
    gameId: activeGameId || 0,
    status,
  });

  const isExpire =
    status === GameStatuses.NotCreated ||
    status === GameStatuses.Created ||
    status === GameStatuses.Ready;

  const [state, dispatch] = React.useReducer(
    (state: State, action: Action) => {
      if (action === 'COUNTDOWN') {
        if (!state.hasFoundOpponent) {
          router.push(routes.game.lobby);

          throw new Error('Opponent not found');
        }

        if (state.countdown === 0) {
          return state;
        }

        return { ...state, countdown: state.countdown - 1 };
      }

      if (action === 'FOUND_OPPONENT') {
        return { hasFoundOpponent: true, countdown: 10 };
      }

      return state;
    },

    { hasFoundOpponent: false }
  );

  React.useEffect(() => {
    if (status === GameStatuses.Ended) {
      setIsSearching(false);
    }

    if (playerId) {
      return;
    }

    if (status === GameStatuses.Created) {
      return setPlayerId(1);
    }

    if (status === GameStatuses.Joined) {
      return setPlayerId(2);
    }
  }, [status, playerId]);

  React.useEffect(() => {
    if (gameStarted || status === GameStatuses.Started) {
      dispatch('FOUND_OPPONENT');

      searchAudio?.pause();

      if (isSfx) {
        foundOpponentAudio?.play();
      }

      const interval = setInterval(() => {
        dispatch('COUNTDOWN');
      }, 1000);

      return () => {
        clearInterval(interval);
        foundOpponentAudio?.pause();
      };
    }

    return;
  }, [gameStarted, status]);

  React.useEffect(() => {
    if (status === GameStatuses.Ended) {
      if (activeGameId) {
        setActiveGameId(undefined);
      }

      setIsSearching(false);
    }
  }, [status]);

  const countdown = 'countdown' in state ? state.countdown : undefined;

  React.useEffect(() => {
    if (countdown === 0 && !isExitGameLoading && gameStarted?.arenaId) {
      console.log('gameStarted', gameStarted);

      setActiveArenaId(gameStarted.arenaId);
      router.push(routes.game.play);
    }

    if (countdown === 0 && !isExitGameLoading && arena) {
      console.log('arena', arena);

      setActiveArenaId(arena);
      router.push(routes.game.play);
    }
  }, [countdown, router, gameStarted, activeGameId, arena]);

  React.useEffect(() => {
    if (isExitGameSuccess) {
      setActiveGameId(undefined);
      setIsSearching(false);
    }
  }, [isExitGameSuccess]);

  React.useEffect(() => {
    if (isSfx) {
      searchAudio?.play();
    }

    return () => {
      if (searchAudio) {
        searchAudio.pause();
        searchAudio.src = '';
      }

      if (foundOpponentAudio) {
        foundOpponentAudio.pause();
        foundOpponentAudio.src = '';
      }
    };
  }, []);

  const cancelGame = React.useCallback(() => {
    setIsCancelModalOpen(false);

    const quitGame = expireGame || exitGame;

    try {
      quitGame?.();

      if (!activeGameId) {
        setIsSearching(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [expireGame, exitGame]);

  const continueGame = React.useCallback(() => {
    setIsCancelModalOpen(false);

    if (isExpire) {
      return;
    }

    !countdown && router.push(routes.game.play);
  }, [status, countdown, router, isExpire]);

  const handleOpenCancelModal = () => {
    setIsCancelModalOpen(true);
  };

  return (
    <>
      <Background image={cyberspace} />

      <Main className="flex flex-col items-center justify-center">
        <div className="max-w-screen flex flex-col items-center justify-center">
          <GameSearchHeaderIcon className="text-red" />
          <div className="w-full divide-y-[3px] divide-red border-[3px] border-red bg-black">
            <div className="p-8 py-5">
              <p className="text-lime text-glow-lime">
                {state.hasFoundOpponent ? '//:Enemy Found' : '//:Seeking Enemy...'}
                {!!activeGameId && (
                  <span>
                    {' '}
                    - Game ID {activeGameId} - Blind {activeBlind.prize} Ξ
                  </span>
                )}
              </p>
            </div>
            {state.hasFoundOpponent ? (
              <div className="space-y-8 bg-[#010401] p-8">
                <div className="flex items-center justify-between">
                  <div className="text-red">
                    {address && playerOne && playerTwo && (
                      <GenericUserAvatar
                        name={truncAddress({
                          address: address === playerOne ? playerTwo : playerOne,
                          length: 3,
                        })}
                        avatar={<Avatar address={address === playerOne ? playerTwo : playerOne} />}
                      />
                    )}
                  </div>

                  <dl className="space-y-2 text-right">
                    <dt className="font-bold text-red">Wins</dt>
                    {winsAndLossesData && <dd>{winsAndLossesData.wins}</dd>}

                    <dt className="font-bold text-red">Losses</dt>
                    {winsAndLossesData && <dd>{winsAndLossesData.losses}</dd>}
                  </dl>
                </div>

                <button
                  className="font font-title w-full border border-red px-2 py-4 text-red"
                  onClick={handleOpenCancelModal}
                >
                  cancel ({state.countdown})
                </button>
              </div>
            ) : (
              <div>
                <div className="bg-[#010401] p-8">
                  <Image
                    alt="hacking"
                    className="w-full [image-rendering:crisp-edges]"
                    {...blurIfStatic(hacking)}
                  />
                </div>
                <button
                  className="font font-title w-full border border-red px-2 py-4 text-red"
                  onClick={handleOpenCancelModal}
                >
                  cancel
                </button>
              </div>
            )}
          </div>
          <GameSearchFooterIcon className="text-red" />
        </div>
        <GameCancelModal
          isOpen={isCancelModalOpen}
          isLoading={isExitGameLoading}
          isExpire={isExpire}
          handleClose={() => setIsCancelModalOpen(false)}
          cancelGame={cancelGame}
          continueGame={continueGame}
        />
      </Main>
    </>
  );
}
