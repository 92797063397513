import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { GameStatuses } from '@/core/hooks';

export type GameEventsState = {
  status: GameStatuses | null;
  gameCreated:
    | {
        timestamp: string;
        currentGameId: number;
        currentAddress: `0x${string}`;
        currentBlind: number;
      }
    | undefined;
  gameJoined:
    | {
        timestamp: string;
        currentGameId: number;
        currentAddress: `0x${string}`;
        currentBlind: number;
      }
    | undefined;
  gameStarted:
    | {
        timestamp: string;
        gameId: number;
        arenaId: number;
        attacker: number;
      }
    | undefined;
  gameEndPending:
    | {
        timestamp: string;
        playerOne: `0x${string}`;
        playerTwo: `0x${string}`;
        gameId: number;
        potentialWinner: number;
      }
    | undefined;
  gameReanimated:
    | {
        timestamp: string;
        gameId: number;
        attacker: number;
        round: number;
      }
    | undefined;
  isApproved: boolean;
  hasBeenReanimated: boolean;
  setGameCreated: (gameCreated: {
    timestamp: string;
    currentGameId: number;
    currentAddress: `0x${string}`;
    currentBlind: number;
  }) => void;
  setGameJoined: (gameJoined: {
    timestamp: string;
    currentGameId: number;
    currentAddress: `0x${string}`;
    currentBlind: number;
  }) => void;
  setGameStarted: (gameStarted: {
    timestamp: string;
    gameId: number;
    arenaId: number;
    attacker: number;
  }) => void;
  setGameEndPending: (
    gameEndPending:
      | {
          timestamp: string;
          playerOne: `0x${string}`;
          playerTwo: `0x${string}`;
          gameId: number;
          potentialWinner: number;
        }
      | undefined
  ) => void;
  setGameReanimated: (
    gameReanimated:
      | {
          timestamp: string;
          gameId: number;
          attacker: number;
          round: number;
        }
      | undefined
  ) => void;
  setApproved: (isApproved: boolean) => void;
  setHasBeenReanimated: (hasBeenReanimated: boolean) => void;
  clearAll: () => void;
};

export type GameCreated = NonNullable<GameEventsState['gameCreated']>;
export type GameJoined = NonNullable<GameEventsState['gameJoined']>;
export type GameStarted = NonNullable<GameEventsState['gameStarted']>;
export type GameEndPending = NonNullable<GameEventsState['gameEndPending']>;
export type GameReanimated = NonNullable<GameEventsState['gameReanimated']>;

export const useGameEventsStore = create<GameEventsState>()(
  devtools(
    persist(
      (set) => ({
        status: null,
        isApproved: false,
        hasBeenReanimated: false,
        gameCreated: undefined,
        gameJoined: undefined,
        gameStarted: undefined,
        gameEndPending: undefined,
        gameReanimated: undefined,
        setGameCreated: (gameCreated) => set({ gameCreated, status: GameStatuses.Created }),
        setGameJoined: (gameJoined) => set({ gameJoined, status: GameStatuses.Joined }),
        setGameStarted: (gameStarted) => set({ gameStarted, status: GameStatuses.Started }),
        setGameEndPending: (gameEndPending) =>
          set({ gameEndPending, status: GameStatuses.EndPending }),
        setGameReanimated: (gameReanimated) =>
          set({ gameReanimated, status: GameStatuses.Started }),
        setApproved: (isApproved) => set({ isApproved }),
        setHasBeenReanimated: (hasBeenReanimated) => set({ hasBeenReanimated }),
        clearAll: () =>
          set({
            status: null,
            gameCreated: undefined,
            gameJoined: undefined,
            gameStarted: undefined,
            gameEndPending: undefined,
            gameReanimated: undefined,
            hasBeenReanimated: false,
          }),
      }),
      {
        name: 'game-events-store',
      }
    )
  )
);
