import * as React from 'react';

export const BearBoostIcon = React.forwardRef<
  SVGSVGElement,
  React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => {
  return (
    <svg
      ref={ref}
      width="39"
      height="35"
      viewBox="0 0 39 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.9642 9.18766L25.3749 8.10303L23.16 9.42389L18.1661 8.7406L13.1696 9.42389L10.9547 8.10303L10.3653 9.18766L11.8259 11.2604L9.63379 17.9536L12.2501 22.5716L12.1079 17.8368L14.5134 13.7116L13.1925 12.1799L18.1661 12.2282L23.1371 12.1799L21.8188 13.7116L24.2217 17.8368L24.0795 22.5716L26.6958 17.9536L24.5037 11.2604L25.9642 9.18766Z"
        fill="currentColor"
      />
      <path
        d="M25.9642 9.18766L25.3749 8.10303L23.16 9.42389L18.1661 8.7406L13.1696 9.42389L10.9547 8.10303L10.3653 9.18766L11.8259 11.2604L9.63379 17.9536L12.2501 22.5716L12.1079 17.8368L14.5134 13.7116L13.1925 12.1799L18.1661 12.2282L23.1371 12.1799L21.8188 13.7116L24.2217 17.8368L24.0795 22.5716L26.6958 17.9536L24.5037 11.2604L25.9642 9.18766Z"
        fill="currentColor"
      />
      <path
        d="M35.8262 0.0457222H34.6019V0H0.0561523V34.5457H34.6019V23.6232L38.1123 20.1127V2.33183L35.8262 0.0457222ZM24.1518 24.8577L18.1672 28.9574L12.1827 24.8577L8.27092 18.0933L10.2294 12.6117L8.31918 9.98777L10.2979 6.52304L13.5036 8.21984L18.1698 7.55941L22.836 8.21984L26.0416 6.52304L28.0204 9.98777L26.1102 12.6117L28.0686 18.0933L24.1569 24.8577H24.1518Z"
        fill="currentColor"
      />
      <path
        d="M35.8262 0.0457222H34.6019V0H0.0561523V34.5457H34.6019V23.6232L38.1123 20.1127V2.33183L35.8262 0.0457222ZM24.1518 24.8577L18.1672 28.9574L12.1827 24.8577L8.27092 18.0933L10.2294 12.6117L8.31918 9.98777L10.2979 6.52304L13.5036 8.21984L18.1698 7.55941L22.836 8.21984L26.0416 6.52304L28.0204 9.98777L26.1102 12.6117L28.0686 18.0933L24.1569 24.8577H24.1518Z"
        fill="currentColor"
      />
      <path
        d="M18.1675 13.3354L16.0465 13.8536L14.939 18.4005L16.5418 19.7899L18.1675 19.1777L19.7931 19.7899L21.396 18.4005L20.2885 13.8536L18.1675 13.3354ZM18.1675 17.6003L16.4707 16.376L16.7526 15.2456L18.1675 15.0805L19.5823 15.2456L19.8643 16.376L18.1675 17.6003Z"
        fill="currentColor"
      />
      <path
        d="M18.1675 13.3354L16.0465 13.8536L14.939 18.4005L16.5418 19.7899L18.1675 19.1777L19.7931 19.7899L21.396 18.4005L20.2885 13.8536L18.1675 13.3354ZM18.1675 17.6003L16.4707 16.376L16.7526 15.2456L18.1675 15.0805L19.5823 15.2456L19.8643 16.376L18.1675 17.6003Z"
        fill="currentColor"
      />
    </svg>
  );
});

BearBoostIcon.displayName = 'BearBoostIcon';
