import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { generateRandomNumberString } from '../utils';

interface GameSecretsState {
  secret2: string; // 36 digit secret for current round
  secret3: string; // 36 digit secret for next round
  secret4: string; // 36 digit secret for next round
  randomString: string; // 36 digit secret for next round
  init: () => void; // initialize secrets
  next: () => void; // generate new secret for next round
  reset: () => void; // reset secrets
}

export const useGameSecretsStore = create<GameSecretsState>()(
  devtools(
    persist(
      (set, get) => ({
        secret2: '',
        secret3: '',
        secret4: '',
        randomString: '',
        init: () => {
          set({
            secret2: generateRandomNumberString(),
            secret3: generateRandomNumberString(),
            secret4: generateRandomNumberString(),
            randomString: generateRandomNumberString(),
          });
        },
        next: () => {
          set({
            secret2: get().secret3,
            secret3: get().secret4,
            secret4: generateRandomNumberString(),
            randomString: generateRandomNumberString(),
          });
        },
        reset: () => {
          set({
            secret2: '',
            secret3: '',
            secret4: '',
            randomString: '',
          });
        },
      }),
      {
        name: 'degen-game-secret-storage',
      }
    )
  )
);
