import * as React from 'react';

import { cx } from '@/core/utils';

import { CardProperty } from '../store';

type AttackValueBarProps = {
  attackValue: number;
  arenaEffectValue: number;
  healthEffectValue: number;
  propertyName: string;
  activeParameter: CardProperty | null;
  parameter: CardProperty;
  isDisabled?: boolean;
};

export function AttackValueBar(props: AttackValueBarProps) {
  const {
    attackValue,
    arenaEffectValue,
    healthEffectValue,
    propertyName,
    activeParameter,
    parameter,
    isDisabled,
  } = props;

  const maximumValue = 130;

  const [isHighlightLeft, setIsHighLightLeft] = React.useState(true);

  const breakPointForShowingValues = {
    liquidity: 60,
    degeneracy: 45,
    techKnowHow: 55,
    influence: 40,
    shitposter: 40,
    alpha: 25,
  };

  React.useEffect(() => {
    if (arenaEffectValue >= 0) {
      setIsHighLightLeft(false);

      return;
    }

    setIsHighLightLeft(true);
  }, [arenaEffectValue]);

  const isAtBreakPoint =
    attackValue < breakPointForShowingValues[parameter.prop] || attackValue > 100;

  const arenaEffectBarLength = `${Math.abs(arenaEffectValue / maximumValue) * 100}%`;
  const healthEffectBarLength = `${Math.abs(healthEffectValue / maximumValue) * 100}%`;
  const attackValueBarLength = `${Math.abs(attackValue / maximumValue) * 100}%`;

  const effectDisplayValue =
    -healthEffectValue + arenaEffectValue > 0
      ? `+${(-healthEffectValue + arenaEffectValue).toFixed(1)}`
      : `${(-healthEffectValue + arenaEffectValue).toFixed(1)}`;

  return (
    <div className="relative h-full">
      <div
        className={cx(
          'z-0 flex h-full w-full justify-start bg-cyan-neutral',
          isDisabled && '!bg-gray-blue'
        )}
      >
        <div
          className={cx('flex bg-cyan-light', isDisabled && 'bg-gray-600')}
          style={{ width: attackValueBarLength }}
        />
        <div className={cx('flex', isHighlightLeft && 'w-0.5 bg-cyan', isDisabled && 'bg-white')}>
          {' '}
        </div>
        <div
          className={cx('flex', {
            'items-center justify-end bg-gradient-to-l from-green to-cyan-light':
              arenaEffectValue >= 0,
            'justify-begin items-center bg-gradient-to-r from-red to-cyan-neutral':
              arenaEffectValue < 0,
          })}
          style={{ width: arenaEffectBarLength }}
        >
          {!isAtBreakPoint && healthEffectValue + arenaEffectValue !== 0 && (
            <p className="z-10 mx-1 text-xs">{effectDisplayValue}</p>
          )}
        </div>
        <div
          className={cx('flex', !isHighlightLeft && 'w-0.5 bg-cyan', isDisabled && 'text-white ')}
        >
          {' '}
        </div>
        <div
          className={cx(
            'justify-begin flex items-center bg-gradient-to-r from-yellow to-cyan-neutral text-xs',
            isDisabled && 'bg-gray-600'
          )}
          style={{ width: healthEffectBarLength }}
        ></div>
      </div>
      <div className={cx('absolute left-0 top-0 h-full w-full')}>
        <div
          className={cx(
            'mt-[1%] flex items-center justify-between px-2',
            '2xl:px-3',
            'tall:mt-[3%]'
          )}
        >
          <div className="flex items-center">
            <div
              className={cx(
                'text-left text-xs font-bold text-cyan',
                activeParameter &&
                  parameter &&
                  activeParameter?.id === parameter?.id &&
                  'text-glow-cyan',
                isDisabled && 'text-white'
              )}
            >
              <p>{propertyName !== 'techKnowHow' ? propertyName : 'Tech Know-How'}</p>
            </div>
            {isAtBreakPoint && healthEffectValue + arenaEffectValue !== 0 && (
              <p className="ml-1 text-left text-xs text-white">{effectDisplayValue}</p>
            )}
          </div>
          <div
            className={cx(
              'text-left text-xs font-bold text-cyan',
              activeParameter &&
                parameter &&
                activeParameter?.id === parameter?.id &&
                'text-glow-cyan',
              isDisabled && 'text-white'
            )}
          >
            {attackValue}
          </div>
        </div>
      </div>
    </div>
  );
}
