import { useContractRead } from 'wagmi';
import { useMemo } from 'react';

import { BLAST_SEPOLIA_ID, CONTRACTS, DEGEN_CARD_ABI } from '@/core/lib';

type useTokensOfOwnerProps = {
  address: `0x${string}` | undefined;
};

export function useTokensOfOwner(props: useTokensOfOwnerProps) {
  const { address } = props;

  const { data, ...rest } = useContractRead({
    abi: DEGEN_CARD_ABI,
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_CARD,
    functionName: 'tokensOfOwner',
    args: [address as `0x${string}`],
    enabled: !!address,
  });

  const mappedData = useMemo(() => {
    return data?.map(Number) ?? [];
  }, [data]);

  return {
    data: mappedData,
    ...rest,
  };
}
