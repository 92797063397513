import * as React from 'react';
import { Dialog } from '@headlessui/react';

import { Callout } from '@/ui';
import { useAudio } from '@/core/hooks';
import { cx } from '@/core/utils';
import { useAttackValues } from '@/play';
import { useAttackValueStore, useSettingsStore } from '@/play/store';
import { RoundResultCallout } from '@/ui/components/RoundResultCallout';

import type { GameSteps } from './Play';

const callouts = {
  'choose attack': 'Choose Attack',
  'enemy turn': 'Enemy turn',
  'your turn': 'Your Turn',
  'you win': 'You Win',
  'you lose': 'You Lose',
  draw: 'Draw',
  attacking: 'Attacking',
};

type ActiveCalloutProps = {
  currentGameStep: GameSteps;
};

export function ActiveCallout(props: ActiveCalloutProps) {
  const { currentGameStep } = props;
  const [isOpen, setIsOpen] = React.useState(true);
  const audio = useAudio({ src: '/audio/callout.mp3#0.5', volume: 0.5 });
  const { isSfx } = useSettingsStore();
  const { getActiveParameter, getOpponentValues, getPlayerValues } = useAttackValueStore();

  const opponentAttackValue = useAttackValues({
    cardId: getOpponentValues().cardID,
    activeParameter: getActiveParameter(),
    booster: getOpponentValues().boosterId,
  });

  const playerAttackValue = useAttackValues({
    cardId: getPlayerValues().cardID,
    activeParameter: getActiveParameter(),
    booster: getPlayerValues().boosterId,
  });

  React.useEffect(() => {
    if (
      currentGameStep === 'enemy turn' ||
      currentGameStep === 'your turn' ||
      currentGameStep === 'choose attack' ||
      currentGameStep === 'draw'
    ) {
      setIsOpen(true);

      return;
    }

    if (currentGameStep === 'you win' || currentGameStep === 'you lose') {
      setTimeout(() => {
        setIsOpen(true);
      }, 4000);
    }
  }, [currentGameStep]);

  React.useEffect(() => {
    if (isOpen) {
      if (isSfx) {
        audio?.play();
      }

      setTimeout(() => {
        setIsOpen(false);
      }, 4000);
    }

    return () => {
      audio?.pause();
    };
  }, [isOpen]);

  React.useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
        audio.src = '';
      }
    };
  }, []);

  const hasResult =
    currentGameStep === 'you win' || currentGameStep === 'you lose' || currentGameStep === 'draw';

  if (
    currentGameStep === 'shuffle' ||
    currentGameStep === 'attack' ||
    currentGameStep === 'complete turn' ||
    currentGameStep === 'defence'
  ) {
    return null;
  }

  if (hasResult) {
    const enemyValue = opponentAttackValue?.attackValue ?? 0;
    const playerValue = playerAttackValue?.attackValue ?? 0;

    const EnemyPlayerText =
      currentGameStep === 'you win'
        ? 'Enemy Lost'
        : currentGameStep === 'draw'
        ? 'Draw'
        : 'Enemy Won';

    const PlayerText =
      currentGameStep === 'you win' ? 'You Won' : currentGameStep === 'draw' ? 'Draw' : 'You Lost';

    return (
      <>
        {isOpen && (
          <div
            className={cx(
              'absolute z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-70',
              hasResult ? 'backdrop-blur' : ''
            )}
          >
            <Dialog
              open={isOpen}
              onClose={() => setIsOpen(false)}
              className="fixed left-0 top-0 z-50 flex h-full w-screen items-center justify-center bg-black bg-opacity-70"
            >
              <Dialog.Panel
                className={cx(
                  'relative flex animate-pulse flex-col items-center justify-center gap-8',
                  hasResult ? '' : 'animate-pulse'
                )}
              >
                <span className="animate-pulse text-3xl font-extrabold">
                  {callouts[currentGameStep]}
                </span>
                <div className="relative ml-16">
                  <span className="absolute -left-40 -top-2 text-xl font-black text-yellow-thirtiary">
                    {EnemyPlayerText}
                  </span>
                  <span className="absolute -bottom-8 -right-24 text-xl font-black text-yellow-thirtiary">
                    {PlayerText}
                  </span>
                  <span
                    className={cx(
                      'absolute inset-0 -left-44 top-6 flex items-center justify-center text-xl font-black',
                      enemyValue > playerValue ? 'text-green' : 'text-red',
                      currentGameStep === 'draw' && 'text-yellow-thirtiary'
                    )}
                  >
                    {enemyValue.toString()}
                  </span>
                  <span
                    className={cx(
                      'absolute inset-0 left-20 top-6 flex items-center justify-center text-xl font-black',
                      enemyValue > playerValue ? 'text-red' : 'text-green',
                      currentGameStep === 'draw' && 'text-yellow-thirtiary'
                    )}
                  >
                    {playerValue.toString()}
                  </span>
                  <RoundResultCallout />
                </div>
              </Dialog.Panel>
            </Dialog>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {isOpen && (
        <div className="absolute z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50">
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="fixed left-0 top-0 z-50 flex h-full w-screen items-center justify-center bg-black bg-opacity-70"
          >
            <Dialog.Panel className="relative flex animate-pulse items-center justify-center">
              <Callout />
              <span className="absolute text-3xl font-extrabold text-cyan">
                {callouts[currentGameStep]}
              </span>
            </Dialog.Panel>
          </Dialog>
        </div>
      )}
    </>
  );
}
