'use client';

import { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_CARD_ABI } from '@/core/lib';
import { convertBNtoNumber } from '@/core/utils';

export type UseTokenEditionProps = {
  ids: number;
};

export function useTokenEdition(props: UseTokenEditionProps) {
  const { ids } = props;
  const [tokenIds, setTokenIds] = useState<undefined | number[] | number>(undefined);

  const { data, isLoading, isError } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_CARD as `0x${string}`,
    abi: DEGEN_CARD_ABI,
    functionName: 'tokenEdition',
    args: [BigInt(ids ?? 0)],
    enabled: ids !== undefined,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setTokenIds(convertBNtoNumber(data));
  }, [data]);

  return { tokenIds, isLoading, isError };
}
