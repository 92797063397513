'use client';

import { useState, useCallback } from 'react';

export function useToggle(
  initialValue = false
): [isValue: boolean, toggleOrSet: (isValue?: boolean) => void] {
  const [isValue, setValue] = useState(initialValue);

  const toggleOrSet = useCallback((next?: boolean) => {
    if (typeof next === 'boolean') {
      setValue(next);

      return;
    }

    setValue((v) => !v);
  }, []);

  return [isValue, toggleOrSet];
}
