'use client';

import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { useGameGlobalStats } from '@/core/hooks';
import { Stat } from '@/ui';

import { useGameAnimationStore } from '../store';

export function GameGlobalStats() {
  const { totalRounds, totalWinnings, totalRoundsResult, totalWinningsResult } =
    useGameGlobalStats();
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const controlsTitle = useAnimation();
  const controlsStats = useAnimation();
  const { hasSeenGameAnimation } = useGameAnimationStore();

  useEffect(() => {
    if (hasSeenGameAnimation) {
      setIsTitleVisible(true);

      return;
    }

    const titleFlicker = async () => {
      await controlsTitle.start({ opacity: 0 });
      await controlsTitle.start({ opacity: 0.3 });
      await controlsTitle.start({ opacity: 0.2 });
      await controlsTitle.start({ opacity: 0.6 });
      await controlsTitle.start({ opacity: 0.2 });
      await controlsTitle.start({ opacity: 1 });

      setIsTitleVisible(true);
    };

    titleFlicker();
  }, [controlsTitle]);

  useEffect(() => {
    if (hasSeenGameAnimation) {
      return;
    }

    const statsFlicker = async () => {
      await controlsStats.start({ opacity: 0 });
      await controlsStats.start({ opacity: 0.3 });
      await controlsStats.start({ opacity: 0.2 });
      await controlsStats.start({ opacity: 0.5 });
      await controlsStats.start({ opacity: 0.3 });
      await controlsStats.start({ opacity: 1 });
    };

    statsFlicker();
  }, [controlsStats]);

  return (
    <div className="space-y-4">
      <motion.div animate={controlsTitle} transition={{ duration: 0.15 }} className={clsx('flex')}>
        <div className="flex h-12 w-12 grow select-none items-center justify-center bg-red text-xl text-black">
          <p>0.</p>
        </div>

        <div className="flex h-12 w-full items-center border border-red bg-white/5 px-4 backdrop-blur-md">
          <p className={clsx('uppercase text-lime', isTitleVisible ? 'opacity-100' : 'opacity-0')}>
            {'//:Global Game Stats'}
          </p>
        </div>
      </motion.div>

      <motion.div animate={controlsStats} transition={{ duration: 0.15 }}>
        <motion.div className={clsx('grid grid-cols-2 gap-4 md:grid-cols-4')}>
          <Stat title="Received Prize money" animationdDelay={0.2}>
            {totalWinningsResult.isLoading && '-'}
            {totalWinningsResult.isError && '-'}
            {totalWinnings && `${totalWinnings}  ETH`}
          </Stat>
          <Stat title="Total DGW Bonded" animationdDelay={0.5}>
            -
          </Stat>
          <Stat title="Total DGW Burned" animationdDelay={0.8}>
            -
          </Stat>
          <Stat title="PvP Round Played" animationdDelay={1.1}>
            {totalRoundsResult.isLoading && '-'}
            {totalRoundsResult.isError && '-'}
            {totalRounds && totalRounds}
          </Stat>
        </motion.div>
      </motion.div>
    </div>
  );
}
