'use client';

import { useState } from 'react';
import { useContractEvent } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';
import { convertBNtoNumber } from '@/core/utils';

export function useVRFRequestFulfilledEvent() {
  const [randId, setRandId] = useState<number>();
  const [currentGameId, setCurrentGameId] = useState<number>();
  const [randomWords, setRandomWords] = useState<number[]>();

  console.log('render useVRFRequestFulfilled');

  useContractEvent({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    eventName: 'VRFRequestFulfilled',
    listener: (logs) => {
      const log = logs[0];

      if (!log) return;

      const {
        args: { gameId, randId, randomWords },
      } = log;

      console.log('VRFRequestFulfilled');
      console.log('randId', convertBNtoNumber(randId as bigint));
      console.log('gameId', Number(gameId as bigint));
      console.log('randomWords', (randomWords as bigint[]).map(Number));

      randId && setRandId(convertBNtoNumber(randId as bigint));
      gameId && setCurrentGameId(Number(gameId as bigint));
      randomWords && setRandomWords((randomWords as bigint[]).map(Number));
    },
  });

  return { randId, currentGameId, randomWords };
}
