import * as React from 'react';
import { useContractRead } from 'wagmi';

import { useArenaStore } from '@/game';
import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

import { useAccount } from '../../useAccount';

export function useIdentifyPlayer() {
  const { activeGameId } = useArenaStore();
  const { address } = useAccount();
  const [identifyPlayer, setIdentifyPlayer] = React.useState<{
    playerNum: number;
    isAttacker: boolean;
    needsAttack: boolean;
    attackerCard: number;
    attackerPropId: number;
  }>();

  const { data, isError, isLoading, refetch } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'identifyPlayer',
    args: [activeGameId as unknown as bigint, address as unknown as `0x${string}`],
  });

  React.useEffect(() => {
    if (data) {
      setIdentifyPlayer({
        playerNum: Number(data[0]),
        isAttacker: data[1],
        needsAttack: data[2],
        attackerCard: Number(data[3]),
        attackerPropId: Number(data[4]),
      });
    }
  }, [data]);

  React.useEffect(() => {
    console.log('identifyPlayer', identifyPlayer);
  }, [identifyPlayer]);

  return {
    identifyPlayer,
    isLoading,
    isError,
    refetch,
  };
}
