'use client';

import { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';
import dayjs from 'dayjs';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';
import { convertBNtoNumber } from '@/core/utils';

export enum GameStatuses {
  NotCreated = 0,
  Created = 1,
  Joined = 2,
  Ready = 3,
  Started = 4,
  EndPending = 5,
  Ended = 6,
}

export enum GameMode {
  Default = 1,
  Hardcore = 2,
}

export type UseGameProps = {
  gameId: number;
  shouldWatch?: boolean;
};

export function useGame(props: UseGameProps) {
  const { gameId, shouldWatch = false } = props;
  const [status, setStatus] = useState<GameStatuses>(GameStatuses.NotCreated);
  const [gameMode, setGameMode] = useState<number>();
  const [arena, setArena] = useState<number>();
  const [blind, setBlind] = useState<number>();
  const [startTime, setStartTime] = useState<number>();
  const [round, setRound] = useState<number>();
  const [attacker, setAttacker] = useState<number>();
  const [pendingRand, setPendingRand] = useState<number>();
  const [pendingBooster, setPendingBooster] = useState<number>();
  const [property, setProperty] = useState<number>();
  const [expiry, setExpiry] = useState<number>();
  const [winner, setWinner] = useState<number>();
  const [isGameError, setIsGameError] = useState<boolean>();
  const [isGameLoading, setIsGameLoading] = useState<boolean>();

  const { data, isError, isLoading, refetch } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'games',
    args: [BigInt(gameId)],
    watch: shouldWatch,
  });

  useEffect(() => {
    if (isError) {
      setIsGameError(isError);

      return;
    }

    if (isLoading) {
      setIsGameLoading(isLoading);

      return;
    }

    if (data) {
      console.group('Game');
      console.log('timestamp', dayjs().format('HH:mm:ss'));
      console.log('gameId', gameId);
      console.log('status', data[0]);
      console.log('mode', Number(data[1]));
      console.log('arena', Number(data[2]));
      console.log('blind', convertBNtoNumber(data[3]));
      console.log('startTime', Number(data[4]));
      console.log('round', Number(data[5]));
      console.log('attacker', Number(data[6]));
      console.log('pendingRand', convertBNtoNumber(data[7]));
      console.log('pendingBooster', Number(data[8]));
      console.log('property', Number(data[9]));
      console.log('expiry', Number(data[10]));
      console.log('winner', Number(data[11]));
      console.groupEnd();

      setStatus(data[0] as GameStatuses);
      setGameMode(Number(data[1]));
      setArena(Number(data[2]));
      setBlind(convertBNtoNumber(data[3]));
      setStartTime(Number(data[4]));
      setRound(Number(data[5]));
      setAttacker(Number(data[6]));
      setPendingRand(convertBNtoNumber(data[7]));
      setPendingBooster(Number(data[8]));
      setProperty(Number(data[9]));
      setExpiry(Number(data[10]));
      setWinner(Number(data[11]));
    }
  }, [data, isError, isLoading]);

  return {
    isGameError,
    isGameLoading,
    status,
    gameMode,
    arena,
    blind,
    startTime,
    round,
    attacker,
    pendingRand,
    pendingBooster,
    property,
    expiry,
    winner,
    invalidateQuery: refetch,
    refetch,
  };
}
