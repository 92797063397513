export const DEGEN_WARS_ABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'have',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'want',
        type: 'address',
      },
    ],
    name: 'OnlyCoordinatorCanFulfill',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'player',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'opponent',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gameMode',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'blind',
        type: 'uint256',
      },
    ],
    name: 'GameCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'player1',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'player2',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'potentialWinner',
        type: 'uint256',
      },
    ],
    name: 'GameEndPending',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'player1',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'player2',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gameMode',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'blind',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'winner',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'totalRounds',
        type: 'uint256',
      },
    ],
    name: 'GameEnded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'player',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'opponent',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gameMode',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'blind',
        type: 'uint256',
      },
    ],
    name: 'GameJoined',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'attacker',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'roundNumber',
        type: 'uint256',
      },
    ],
    name: 'GameReanimated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'arenaId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'attacker',
        type: 'uint256',
      },
    ],
    name: 'GameStarted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'player',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'roundNumber',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'playerCard',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'order',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'attackerProp',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'boosterId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'isAttacker',
        type: 'bool',
      },
    ],
    name: 'HandPlayed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'player',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'bool',
        name: 'isRefund',
        type: 'bool',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'PayUser',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'playerOne',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'playerTwo',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'roundNumber',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'playerOneCard',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'playerTwoCard',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'attacker',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'attackerProp',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'resultId',
        type: 'uint256',
      },
    ],
    name: 'RoundCompleted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'randId',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'randomWords',
        type: 'uint256[]',
      },
    ],
    name: 'VRFRequestFulfilled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'randId',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint32',
        name: 'numWords',
        type: 'uint32',
      },
    ],
    name: 'VRFRequestSent',
    type: 'event',
  },
  {
    inputs: [],
    name: 'BOOSTER_PRICE_PERCENTAGE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'CARD_PROPERTY_COUNT',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'COORDINATOR',
    outputs: [
      {
        internalType: 'contract VRFCoordinatorV2Interface',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'PROTOCOL_FEE_PERCENTAGE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'STARTING_HAND_SIZE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'TIMEOUT_MINUTES',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'VFR_GAS_LIMIT',
    outputs: [
      {
        internalType: 'uint32',
        name: '',
        type: 'uint32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'VRF_KEY_HASH',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'VRF_REQUEST_CONFS',
    outputs: [
      {
        internalType: 'uint16',
        name: '',
        type: 'uint16',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'VRF_REQUEST_WORDS',
    outputs: [
      {
        internalType: 'uint32',
        name: '',
        type: 'uint32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'VRF_SUB_ID',
    outputs: [
      {
        internalType: 'uint64',
        name: '',
        type: 'uint64',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: '_initVals',
    outputs: [
      {
        internalType: 'address payable',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'secret_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'cardRand2_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'hash3_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'boosterId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'propId_',
        type: 'uint256',
      },
    ],
    name: 'attack',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'cardNft',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'cards',
    outputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'notOnLoan',
        type: 'bool',
      },
      {
        internalType: 'uint256',
        name: 'endurance',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'effect',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256[]',
        name: 'hand_',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256',
        name: 'hash2_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'hash3_',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'opponent_',
        type: 'address',
      },
      {
        internalType: 'enum DegenWarsObjects.GameMode',
        name: 'mode_',
        type: 'uint8',
      },
      {
        internalType: 'bytes8',
        name: 'referralCode',
        type: 'bytes8',
      },
    ],
    name: 'createGame',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'secret_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'cardRand2_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'hash3_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'boosterId_',
        type: 'uint256',
      },
    ],
    name: 'defend',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'degenMarket',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'expireGame',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'finishGame',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'games',
    outputs: [
      {
        internalType: 'enum DegenWarsObjects.GameStatus',
        name: 'status',
        type: 'uint8',
      },
      {
        internalType: 'enum DegenWarsObjects.GameMode',
        name: 'mode',
        type: 'uint8',
      },
      {
        internalType: 'uint256',
        name: 'arena',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'blind',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'startTime',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'round',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'attacker',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'pendingRand',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'pendingBooster',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'property',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'expiry',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'winner',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'player',
        type: 'address',
      },
    ],
    name: 'getActiveGames',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'nftId_',
        type: 'uint256',
      },
    ],
    name: 'getCard',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            internalType: 'bool',
            name: 'notOnLoan',
            type: 'bool',
          },
          {
            internalType: 'uint256',
            name: 'endurance',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'effect',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'gameId',
            type: 'uint256',
          },
          {
            internalType: 'uint256[]',
            name: 'health',
            type: 'uint256[]',
          },
        ],
        internalType: 'struct DegenWarsObjects.Card',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'nftId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'getCardArenaEffect',
    outputs: [
      {
        internalType: 'int256[]',
        name: '',
        type: 'int256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'nftId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'getCardEnduranceLoss',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'nftId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'getCardHealth',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'getGameReserves',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'getPlayerOneHand',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'secret_',
        type: 'uint256',
      },
    ],
    name: 'getPlayerOneTopCard',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'getPlayerTwoHand',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'secret_',
        type: 'uint256',
      },
    ],
    name: 'getPlayerTwoTopCard',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'referrer_',
        type: 'address',
      },
    ],
    name: 'getReferrals',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'playerId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'getUnusedBoosters',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'playerAddy_',
        type: 'address',
      },
    ],
    name: 'identifyPlayer',
    outputs: [
      {
        internalType: 'uint256',
        name: 'playerNum',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: 'isAttacker',
        type: 'bool',
      },
      {
        internalType: 'bool',
        name: 'needsAttack',
        type: 'bool',
      },
      {
        internalType: 'uint256',
        name: 'attackerCard',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'attackerPropId',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'implementation',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address payable',
        name: 'treasury_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'timeoutMinutes_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'protocolFee_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'boosterFee_',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'degenMarket_',
        type: 'address',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'isExpired',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256[]',
        name: 'hand_',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256',
        name: 'hash2_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'hash3_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
      {
        internalType: 'enum DegenWarsObjects.GameMode',
        name: 'mode_',
        type: 'uint8',
      },
      {
        internalType: 'bytes8',
        name: 'referralCode',
        type: 'bytes8',
      },
    ],
    name: 'joinGame',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'passthrough',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'playerOneBoosterUsed',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'playerOneData',
    outputs: [
      {
        internalType: 'address',
        name: 'addy',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'handRand',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'lastCardId',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'playerRecords',
    outputs: [
      {
        internalType: 'uint256',
        name: 'games',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'wins',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'losses',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'draws',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'blinds',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'winnings',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'refunds',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'playerReferral',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'playerTwoBoosterUsed',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'playerTwoData',
    outputs: [
      {
        internalType: 'address',
        name: 'addy',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'handRand',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'lastCardId',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'blind',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'player',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'opponent',
        type: 'address',
      },
    ],
    name: 'quickMatch',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'quitGame',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'randIdToGameId',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'requestId',
        type: 'uint256',
      },
      {
        internalType: 'uint256[]',
        name: 'randomWords',
        type: 'uint256[]',
      },
    ],
    name: 'rawFulfillRandomWords',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
    ],
    name: 'reanimate',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'referralCodes',
    outputs: [
      {
        internalType: 'bytes8',
        name: '',
        type: 'bytes8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'referredUsers',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes8',
        name: '',
        type: 'bytes8',
      },
    ],
    name: 'referrers',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'registerReferrer',
    outputs: [
      {
        internalType: 'bytes8',
        name: '',
        type: 'bytes8',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'addy_',
        type: 'address',
      },
    ],
    name: 'replacePassthrough',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20',
        name: 'token_',
        type: 'address',
      },
    ],
    name: 'rescue',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'username_',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'userbio_',
        type: 'string',
      },
    ],
    name: 'setUserDetail',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalGames',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalRounds',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalWinnings',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'treasury',
    outputs: [
      {
        internalType: 'address payable',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'usedHashes',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'userbio',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'username',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'vrfCoordinator',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const;
