'use client';

import { Switch } from '@headlessui/react';
import clsx from 'clsx';

type SettingsSwitchToggleProps = {
  isEnabled: boolean;
  setEnabled: (isEnabled: boolean) => void;
};

export function SettingsSwitchToggle(props: SettingsSwitchToggleProps) {
  const { isEnabled, setEnabled } = props;

  return (
    <Switch
      checked={isEnabled}
      onChange={setEnabled}
      className={clsx(
        isEnabled ? 'bg-primary-500' : 'bg-purple-light',
        'relative inline-flex h-6 w-11 items-center rounded-full'
      )}
    >
      <span
        className={clsx(
          isEnabled ? 'translate-x-6' : 'translate-x-1',
          'inline-block h-4 w-4 transform rounded-full bg-neutral-500 transition'
        )}
      />
    </Switch>
  );
}
