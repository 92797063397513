import * as React from 'react';

export const PlebBoostIcon = React.forwardRef<SVGSVGElement, React.ComponentPropsWithoutRef<'svg'>>(
  (props, ref) => {
    return (
      <svg
        ref={ref}
        width="39"
        height="35"
        viewBox="0 0 39 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M21.2045 24.4062C20.1859 25.3791 16.5459 26.9286 16.5459 26.9286L21.7176 26.3012L21.207 24.4062H21.2045Z"
          fill="currentColor"
        />
        <path
          d="M21.2045 24.4062C20.1859 25.3791 16.5459 26.9286 16.5459 26.9286L21.7176 26.3012L21.207 24.4062H21.2045Z"
          fill="currentColor"
        />
        <path
          d="M19.3755 19.3176L24.1611 19.9476L24.8749 17.8037C24.8749 17.8037 20.4982 18.0552 19.378 19.3176H19.3755Z"
          fill="currentColor"
        />
        <path
          d="M12.8335 17.8066L13.5473 19.9505L18.3329 19.3206C17.2127 18.0607 12.836 17.8066 12.836 17.8066H12.8335Z"
          fill="currentColor"
        />
        <path
          d="M36.0508 0.0457222H34.8265V0H0.280762V34.5457H34.8265V23.6232L38.3369 20.1127V2.33183L36.0508 0.0457222ZM18.8516 29.656C15.0795 29.656 12.0238 26.2624 12.0238 22.0762H25.6795C25.6795 26.2624 22.6237 29.656 18.8516 29.656ZM26.6854 20.5674C26.6854 20.9992 26.1672 21.3472 25.5245 21.3472H12.1812C11.5411 21.3472 11.0204 20.9992 11.0204 20.5674V17.4837C11.0204 17.0544 11.5411 16.7039 12.1812 16.7039H25.5245C26.1646 16.7039 26.6854 17.0519 26.6854 17.4837V20.5674ZM32.581 15.0807C32.581 15.5252 32.2203 15.8834 31.7783 15.8834H5.82331C5.37879 15.8834 5.02063 15.5227 5.02063 15.0807C5.02063 14.6362 5.38133 14.278 5.82331 14.278H11.5843L12.5394 5.91595C12.5394 5.65686 12.829 5.44349 13.1871 5.44349L16.342 6.62211L18.7729 5.44349L21.4197 6.70085L24.5212 5.44349C24.8793 5.44349 25.1689 5.65432 25.1689 5.91595L26.124 14.278H31.7809C32.2254 14.278 32.5835 14.6387 32.5835 15.0807H32.581Z"
          fill="currentColor"
        />
        <path
          d="M13.5473 19.9476L12.8335 17.8037C12.8335 17.8037 17.2101 18.0552 18.3303 19.3176L13.5447 19.9476H13.5473ZM24.1599 19.9476L19.3743 19.3176C20.4945 18.0577 24.8711 17.8037 24.8711 17.8037L24.1574 19.9476H24.1599Z"
          fill="currentColor"
        />
        <path
          d="M13.5473 19.9476L12.8335 17.8037C12.8335 17.8037 17.2101 18.0552 18.3303 19.3176L13.5447 19.9476H13.5473ZM24.1599 19.9476L19.3743 19.3176C20.4945 18.0577 24.8711 17.8037 24.8711 17.8037L24.1574 19.9476H24.1599Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);

PlebBoostIcon.displayName = 'PlebBoostIcon';
