'use client';

import * as React from 'react';

import { cx } from '@/core/utils';
import { useArenaStore } from '@/game';

type BattleResultsPrizeMoneyAnimationProps = {
  variant: 'win' | 'loss';
};

const numArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const tripleNumArray = [...numArray, ...numArray, ...numArray];

export function BattleResultsPrizeMoneyAnimation(props: BattleResultsPrizeMoneyAnimationProps) {
  const { variant = 'loss' } = props;
  const { activeBlind } = useArenaStore((state) => ({
    activeBlind: state.activeBlind,
  }));

  const prizeMoney = Number(activeBlind.prize) * 2;

  const prizeMoneyAnimations = {
    2: [
      'animate-scrollUpTwo',
      'animate-scrollUpZero1',
      'animate-scrollUpZero2',
      'animate-scrollUpZero1',
    ],
    0.2: [
      'animate-scrollUpZero',
      'animate-scrollUpTwo1',
      'animate-scrollUpZero2',
      'animate-scrollUpZero1',
    ],
    0.1: [
      'animate-scrollUpZero',
      'animate-scrollUpOne',
      'animate-scrollUpZero2',
      'animate-scrollUpZero1',
    ],
    0.02: [
      'animate-scrollUpZero',
      'animate-scrollUpZero1',
      'animate-scrollUpTwo2',
      'animate-scrollUpZero1',
    ],
    0.002: [
      'animate-scrollUpZero',
      'animate-scrollUpZero1',
      'animate-scrollUpZero1',
      'animate-scrollUpTwo2',
    ],
  };

  const body = () => {
    if (variant === 'loss') {
      return (
        <>
          <Digit>
            <Child variant={variant}>0</Child>
          </Digit>
          <Digit>
            <Child variant={variant}>.</Child>
          </Digit>
          <Digit>
            <Child variant={variant}>0</Child>
          </Digit>
          <Digit>
            <Child variant={variant}>0</Child>
          </Digit>
        </>
      );
    }

    const animations = prizeMoneyAnimations[prizeMoney as keyof typeof prizeMoneyAnimations];

    if (animations) {
      return (
        <>
          <Digit>
            <AnimateNumbers className={cx(animations[0])} variant={variant} />
          </Digit>
          <Digit>
            <Child variant={variant}>.</Child>
          </Digit>
          <Digit>
            <AnimateNumbers className={cx(animations[1])} variant={variant} />
          </Digit>
          <Digit>
            <AnimateNumbers className={cx(animations[2])} variant={variant} />
          </Digit>
          <Digit>
            <AnimateNumbers className={cx(animations[3])} variant={variant} />
          </Digit>
        </>
      );
    }

    return null;
  };

  return (
    <div className={cx('flex')}>
      <div className={cx('grid grid-cols-6 gap-x-1')}>{body()}</div>
    </div>
  );
}

type DigitProps = {
  children: React.ReactNode;
};
function Digit(props: DigitProps) {
  const { children } = props;

  return (
    <div
      className={cx(
        'flex h-9 w-9 shrink-0 flex-col items-start overflow-clip border border-purple-light bg-gradient-to-b from-[rgb(58,56,56,0)] to-[rgb(154,7,18,0.18)]'
      )}
    >
      {children}
    </div>
  );
}

type ChildProps = {
  variant: 'win' | 'loss';
  children: React.ReactNode;
};

function Child(props: ChildProps) {
  const { variant, children } = props;

  return (
    <div
      className={cx(
        'flex h-9 w-9 shrink-0 items-center justify-center text-[1.75rem] font-bold leading-none opacity-90',
        variant === 'win' ? 'text-cyan' : 'text-neutral-700'
      )}
    >
      {children}
    </div>
  );
}

type AnimateNumbersProps = {
  className?: string;
  variant: 'win' | 'loss';
};
function AnimateNumbers(props: AnimateNumbersProps) {
  const { className, variant } = props;

  return (
    <div className={cx('flex h-full flex-col', className)}>
      {tripleNumArray.map((num, i) => (
        <Child key={i} variant={variant}>
          {num}
        </Child>
      ))}
    </div>
  );
}
