import * as React from 'react';

import { metadataUrl } from '../lib';

type TraitType =
  | 'Name'
  | 'Category'
  | 'Liquidity'
  | 'Degeneracy'
  | 'Tech Know-How'
  | 'Influence'
  | 'Shitposter'
  | 'Alpha'
  | 'Edition'
  | 'Order';

export type CardMetadata = {
  attributes: {
    trait_type: TraitType;
    value: string;
  }[];
  image: string;
} | null;

export function useCardMetadata({ id }: { id: number | null }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<CardMetadata>(null);
  const [error, setError] = React.useState<unknown>(null);

  const fetchMetadata = async () => {
    setIsLoading(true);
    setError(null);
    setData(null);

    try {
      const response = await fetch(metadataUrl(Number(id)));

      console.log('response', response);
      const json = await response.json();

      setData(json);
    } catch (error) {
      console.error('Error fetching metadata', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!id) return;

    fetchMetadata();
  }, [id]);

  return {
    isLoading,
    data,
    error,
  };
}
