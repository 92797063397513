import clsx from 'clsx';
import * as React from 'react';

import { truncAddress } from '@/core/utils';
import { Avatar } from '@/game';

type BattleResultsPlayerProfileProps = {
  result: 'win' | 'loss';
  address: `0x${string}` | null;
};

export function BattleResultsPlayerProfile(props: BattleResultsPlayerProfileProps) {
  const { address, result } = props;

  return (
    <svg
      width="280"
      height="135"
      viewBox="0 0 280 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={clsx('!w-full')}
    >
      <mask
        id="avatar_image_mask"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="116"
        height="131"
      >
        <path
          d="M17.2463 131.287H116.669H116.686V20.7179L97.8068 1.83862H1.19141V116.829L17.2463 131.287Z"
          fill="#CC2733"
        />
      </mask>

      <foreignObject width="116" height="131" mask="url(#avatar_image_mask)">
        <Avatar address={address ?? undefined} />
      </foreignObject>

      <g mask="url(#mask0_3640_92977)">
        <rect
          width="410.2"
          height="274.027"
          transform="matrix(-1 0 0 1 285.305 -31.7843)"
          fill="url(#pattern0)"
        />
        <rect x="-43.8125" y="2.34961" width="195.876" height="131.152" fill="url(#pattern1)" />
      </g>
      <path
        d="M116.997 131.798H16.2662L0 117.15V0.646362H97.8867L117.014 19.7741V131.798H116.997ZM16.9646 129.958H115.158V20.5405L97.1203 2.50292H1.83956V116.332L16.9815 129.958H16.9646Z"
        fill="#CC2733"
      />
      <path d="M116.079 134.08H45.4102V135H116.079V134.08Z" fill="#CC2733" />
      <path
        d="M1.82303 38.7314L1.17578 38.0842L3.86697 35.4101V1.56616H4.7867V35.7848L1.82303 38.7314Z"
        fill="#CC2733"
      />
      <path d="M116.076 125.905H55.8147L51.0625 130.64L116.076 130.878V125.905Z" fill="#CC2733" />
      <path
        d="M280 128.851H115.618V20.3532L108.43 13.1654H252.117L261.025 22.0735V83.5784L280 102.553V128.851ZM116.537 127.932H279.08V102.928L260.106 83.9532V22.4482L251.743 14.0852H110.661L116.554 19.9785V127.932H116.537Z"
        fill="#CC2733"
      />
      <path
        d="M262.544 31.1398H258.865V24.2416L251.302 16.6791H245V13H252.818L262.544 22.7256V31.1398Z"
        fill="#CC2733"
      />
      <path d="M279.541 126.552H239.957V130.231H279.541V126.552Z" fill="#CC2733" />
      <path d="M230.911 126.552H220.316V130.231H230.911V126.552Z" fill="#CC2733" />
      <path
        d="M262.727 92.7249L256.102 86.0992V62.5601H257.941V85.3327L264.022 91.4134L262.727 92.7249Z"
        fill="#CC2733"
      />
      <path
        d="M254.886 92.7636L253.586 94.0646L256.2 96.6776L257.5 95.3766L254.886 92.7636Z"
        fill="#CC2733"
      />
      <path d="M251.35 29.4827H241.25V32.8552H251.35V29.4827Z" fill="#CC2733" />
      <path d="M251.35 42.1891H241.25V45.5615H251.35V42.1891Z" fill="#CC2733" />
      <path d="M271.809 108.122H265.285V110.643H271.809V108.122Z" fill="#CC2733" />
      <path d="M271.809 113.914H265.285V116.434H271.809V113.914Z" fill="#CC2733" />
      <path d="M132.836 21.7668H126.312V24.2877H132.836V21.7668Z" fill="#CC2733" />
      <foreignObject width="116" height="30" x="135" y="50">
        <div className={clsx(result === 'win' ? 'text-green' : 'text-red', 'font-bold')}>
          {result === 'win' ? 'WINNER' : 'LOSER'}
        </div>
      </foreignObject>
      <foreignObject width="116" height="30" x="135" y="80">
        <div className={clsx('text-sm font-light text-blue')}>
          {truncAddress({ address: address ?? '', length: 4 })}
        </div>
      </foreignObject>

      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image0_3640_92977"
            transform="matrix(0.000244611 0 0 0.000366166 -0.000963497 0)"
          />
        </pattern>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image1_3640_92977"
            transform="matrix(0.000245172 0 0 0.000366166 -0.00211263 0)"
          />
        </pattern>
      </defs>
    </svg>
  );
}
