import * as React from 'react';

import { useGameEventsStore } from '@/game';

import { useIsApprovedForAll } from './web3/queries/useIsApprovedForAll';

export function useIsApproved() {
  const { isApproved, setApproved } = useGameEventsStore();
  const { data: isApproveData, isSuccess: isApproveSuccess } = useIsApprovedForAll();

  React.useEffect(() => {
    if (isApproveSuccess && isApproveData !== undefined) {
      setApproved(isApproveData);
    }
  }, [isApproveData]);

  return {
    isApproved,
  };
}
