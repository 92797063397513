'use client';

import React, { createContext, useState, useContext } from 'react';

export type AnimationContextType = {
  attackAnimations: AnimationObjects;
  cardRotateAnimations: AnimationObjects;
  cardDestroyAnimations: AnimationObjects;
  shieldAnimation: AnimationObject;
  healthAnimation: AnimationObject;
  arenaAnimation: AnimationObject;
  shuffleAnimation: AnimationObject;
  attackTargetAnimation: AnimationObject;
  loadAnimations: () => Promise<void>;
};

export type AnimationObjects = {
  [key: string]: Animation;
};

export type AnimationObject = Animation | null;

const AnimationContext = createContext<AnimationContextType | undefined>(undefined);

export function AnimationProvider({ children }: { children: React.ReactNode }) {
  const [attackAnimations, setAttackAnimations] = useState<AnimationObjects>({});
  const [cardRotateAnimations, setCardRotateAnimations] = useState<AnimationObjects>({});
  const [cardDestroyAnimations, setCardDestroyAnimations] = useState<AnimationObjects>({});
  const [shieldAnimation, setShieldAnimation] = useState<AnimationObject>(null);
  const [healthAnimation, setHealthAnimation] = useState<AnimationObject>(null);
  const [arenaAnimation, setArenaAnimation] = useState<AnimationObject>(null);
  const [shuffleAnimation, setShuffleAnimation] = useState<AnimationObject>(null);
  const [attackTargetAnimation, setAttackTargetAnimation] = useState<AnimationObject>(null);

  const loadAnimation = async (path: string) => {
    const animation = await import(`../../../assets/images/lottie/${path}`);

    return animation.default;
  };

  const loadAnimations = async () => {
    await Promise.all([
      loadAttackAnimations(),
      loadCardRotateAnimations(),
      loadCardDestroyAnimations(),
      loadShieldAnimation(),
      loadHealthAnimation(),
      loadArenaAnimation(),
      loadShuffleAnimation(),
      loadAttackTargetAnimation(),
    ]);
  };

  const loadAttackAnimations = async () => {
    const animations = {
      liquidity: await loadAnimation('callouts/liquidity.json'),
      degeneracy: await loadAnimation('callouts/degeneracy.json'),
      techKnowHow: await loadAnimation('callouts/techknowhow.json'),
      influence: await loadAnimation('callouts/influence.json'),
      shitposter: await loadAnimation('callouts/shitposter.json'),
      alpha: await loadAnimation('callouts/psyops.json'),
    };

    setAttackAnimations(animations);
  };

  const loadCardRotateAnimations = async () => {
    const animations = {
      bronze: await loadAnimation('reveral-cards/Bronzerotate.json'),
      silver: await loadAnimation('reveral-cards/Silverrotate.json'),
      diamond: await loadAnimation('reveral-cards/Diamondrotate.json'),
      platinum: await loadAnimation('reveral-cards/Platinumrotate.json'),
      red: await loadAnimation('reveral-cards/Redrotate.json'),
      gold: await loadAnimation('reveral-cards/Goldrotate.json'),
    };

    setCardRotateAnimations(animations);
  };

  const loadCardDestroyAnimations = async () => {
    const animations = {
      bronze: await loadAnimation('destroy/BronzeDestroyed.json'),
      silver: await loadAnimation('destroy/SilverDestroyed.json'),
      diamond: await loadAnimation('destroy/DiamondDestroyed.json'),
      platinum: await loadAnimation('destroy/PlatinumDestroyed.json'),
      red: await loadAnimation('destroy/RedDestroyed.json'),
      gold: await loadAnimation('destroy/GoldDestroyed.json'),
    };

    setCardDestroyAnimations(animations);
  };

  const loadShieldAnimation = async () => {
    const shieldAnimation = await loadAnimation('card-booster/shield.json');

    setShieldAnimation(shieldAnimation);
  };

  const loadHealthAnimation = async () => {
    const healthAnimation = await loadAnimation('card-booster/health.json');

    setHealthAnimation(healthAnimation);
  };

  const loadArenaAnimation = async () => {
    const arenaAnimation = await loadAnimation('card-booster/arena.json');

    setArenaAnimation(arenaAnimation);
  };

  const loadShuffleAnimation = async () => {
    const shuffleAnimation = await loadAnimation('shuffle.json');

    setShuffleAnimation(shuffleAnimation);
  };
  const loadAttackTargetAnimation = async () => {
    const attackTargetAnimation = await loadAnimation('attack-target.json');

    setAttackTargetAnimation(attackTargetAnimation);
  };

  return (
    <AnimationContext.Provider
      value={{
        attackAnimations,
        cardRotateAnimations,
        cardDestroyAnimations,
        shieldAnimation,
        healthAnimation,
        arenaAnimation,
        shuffleAnimation,
        attackTargetAnimation,
        loadAnimations,
      }}
    >
      {children}
    </AnimationContext.Provider>
  );
}

export function useAnimation() {
  const context = useContext(AnimationContext);

  if (context === undefined) {
    throw new Error('useAnimationContext must be used within a AnimationProvider');
  }

  return context;
}
