'use client';

import { useContractRead } from 'wagmi';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_LENS_ABI } from '@/core/lib';
import { convertBNtoNumber } from '@/core/utils';

export type UseGetCardProps = {
  nftId: number | undefined | null;
};

export function useGetCardData(props: UseGetCardProps) {
  const { nftId } = props;
  const [isCardError, setIsCardError] = useState<boolean>();
  const [isCardLoading, setIsCardLoading] = useState<boolean>();

  const [cardData, setCardData] = useState<{
    address: `0x${string}`;
    isOnLoan: boolean;
    endurance: number;
    effect: number;
    arena: number;
    arenaEffect: number[];
    enduranceLoss: number[];
    gameId: number;
    health: number[];
    cardHealth: number[];
    tokenProperties: number[][] | number[];
  }>();

  const { data, isError, isLoading, refetch } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS_LENS,
    abi: DEGEN_WARS_LENS_ABI,
    functionName: 'getCardData',
    args: [CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS, BigInt(nftId ?? 0)],
    enabled: nftId !== undefined && nftId !== null,
  });

  useEffect(() => {
    isError && setIsCardError(isError);
    isLoading && setIsCardLoading(isLoading);
    console.log('data', data);
    if (data) {
      console.group('Card');
      console.log('timestamp', dayjs().format('HH:mm:ss'));
      console.log(data.owner);
      console.log(data.isOnLoan);
      console.log(Number(data.endurance));
      console.log(Number(data.effect));
      console.log(Number(data.arena));
      console.log(Number(data.gameId));
      console.log(data.health.map(convertBNtoNumber));
      console.log(data.cardHealth.map(convertBNtoNumber));
      console.log(data.tokenProperties.map(convertBNtoNumber));
      console.groupEnd();

      setCardData({
        address: data.owner,
        isOnLoan: data.isOnLoan,
        endurance: Number(data.endurance),
        enduranceLoss: data.enduranceLoss.map(convertBNtoNumber),
        arenaEffect: data.arenaEffect.map(convertBNtoNumber),
        effect: Number(data.effect),
        arena: Number(data.arena),
        gameId: Number(data.gameId),
        health: data.health.map(convertBNtoNumber),
        cardHealth: data.cardHealth.map(convertBNtoNumber),
        tokenProperties: data.tokenProperties.map(convertBNtoNumber),
      });
    }
  }, [data, isError, isLoading]);

  return {
    isCardError,
    isCardLoading,
    ...cardData,
    refetch,
  };
}
