import clsx from 'clsx';

import type { GameSteps } from './Play';

import { useArenaStore } from '../store';

type PlaySubmitButtonProps = {
  currentGameStep: GameSteps;
  isLoading: boolean;
  handleClick: () => void;
};

export function PlaySubmitButton(props: PlaySubmitButtonProps) {
  const { currentGameStep, isLoading, handleClick } = props;

  const { isExpired, shouldHideProperties } = useArenaStore();

  const isEnabled =
    (currentGameStep === 'attack' ||
      currentGameStep === 'shuffle' ||
      currentGameStep === 'complete turn') &&
    !shouldHideProperties;

  const buttonText = () => {
    if (isExpired) {
      return 'Expired';
    }

    if (shouldHideProperties) {
      return 'Shuffling';
    }

    return currentGameStep;
  };

  return (
    <button
      className={clsx(
        'block h-12 w-full text-xl font-extrabold text-gray-dark hover:opacity-80',
        'text-2xl 2xl:mt-6 2xl:h-16',
        'disabled:cursor-not-allowed disabled:opacity-50',
        isEnabled ? 'bg-yellow' : 'cursor-not-allowed bg-gray'
      )}
      onClick={handleClick}
      disabled={isExpired || !isEnabled || isLoading}
    >
      {buttonText()}
    </button>
  );
}
