'use client';

import * as React from 'react';
import clsx from 'clsx';

import { useAudio } from '@/core/hooks';
import { cx } from '@/core/utils';
import { useSettingsStore } from '@/play/store';

import { GameSteps } from './Play';
import { AttackValueBar } from './AttackValueBar';

import { CardParameters, CardProperty } from '../store';

type ChooseAttackProps = {
  setActiveParameter: React.Dispatch<React.SetStateAction<CardProperty | null>>;
  parameter: CardProperty;
  cardParams?: CardParameters;
  activeParameter: CardProperty | null;
  isDisabled: boolean;
  arenaEffect?: number | undefined;
  currentGameStep?: GameSteps;
  activeBooster: number | null;
  tokenProperties: number[] | number[][] | null;
};

export function AttackButton(props: ChooseAttackProps) {
  const {
    setActiveParameter,
    parameter,
    activeParameter,
    isDisabled,
    cardParams,
    arenaEffect,
    activeBooster,
    tokenProperties,
    currentGameStep,
  } = props;

  const propertyKey = Number(parameter.id - 1);
  const [healthEffectValue, setHealthEffectValue] = React.useState(0);
  const [arenaEffectValue, setArenaEffectValue] = React.useState(0);

  const healthEffectValueLive = cardParams?.[parameter.prop].enduranceLossImpact ?? 0;
  const arenaEffectValueLive = cardParams?.[parameter.prop].arenaEffectImpact ?? 0;
  const currentValueLive = cardParams?.[parameter.prop].currentValue ?? 0;

  const valueWithoutImpact =
    activeBooster === 2
      ? currentValueLive - arenaEffectValueLive
      : activeBooster === 1
      ? currentValueLive + healthEffectValueLive
      : currentValueLive;

  React.useEffect(() => {
    const healthEffectValue = cardParams?.[parameter.prop].enduranceLossImpact ?? 0;
    const arenaEffectValue = cardParams?.[parameter.prop].arenaEffectImpact ?? 0;

    if (activeBooster === 1) {
      setHealthEffectValue(0);
      setArenaEffectValue(arenaEffectValue);

      return;
    }

    if (activeBooster === 2) {
      setHealthEffectValue(healthEffectValue);

      setArenaEffectValue(0);

      return;
    }

    setHealthEffectValue(healthEffectValue);

    setArenaEffectValue(arenaEffectValue);
  }, [propertyKey, tokenProperties, activeBooster, tokenProperties, arenaEffect]);

  const audio = useAudio({ src: '/audio/choose-attack.mp3', volume: 0.5 });
  const { isSfx } = useSettingsStore();

  const handleClick = () => {
    if (isSfx) {
      audio?.play();
    }

    if (
      currentGameStep === 'enemy turn' ||
      currentGameStep === 'you win' ||
      currentGameStep === 'you lose'
    ) {
      return;
    }

    setActiveParameter(parameter);
  };

  React.useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
        audio.src = '';
      }
    };
  }, []);

  return (
    <div
      className={clsx(
        'flex flex-col border-2 border-transparent',
        activeParameter?.id === parameter.id && '!border-2 !border-yellow',
        !isDisabled && 'hover:border-2 hover:border-yellow',
        isDisabled && 'opacity-50'
      )}
    >
      <button className={cx('h-full')} disabled={isDisabled} onClick={handleClick}>
        <AttackValueBar
          attackValue={Number(valueWithoutImpact.toFixed(1))}
          arenaEffectValue={arenaEffectValue}
          healthEffectValue={healthEffectValue}
          propertyName={parameter.prop}
          activeParameter={activeParameter}
          parameter={parameter}
          isDisabled={isDisabled}
        />
      </button>
    </div>
  );
}
