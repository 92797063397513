import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type PlayEventsState = {
  handPlayed:
    | {
        timestamp: string;
        player: `0x${string}`;
        roundNumber: number;
        playerCard: number;
        order: number;
        attackerProp: number;
        boosterId: number;
        isAttacker: boolean;
      }
    | undefined;
  roundComplete:
    | {
        timestamp: string;
        playerOne: `0x${string}`;
        playerTwo: `0x${string}`;
        roundNumber: number;
        playerOneCard: number;
        playerTwoCard: number;
        attacker: number;
        attackerProp: number;
        resultId: number;
      }
    | undefined;

  setHandPlayed: (handPlayed: {
    timestamp: string;
    player: `0x${string}`;
    roundNumber: number;
    playerCard: number;
    order: number;
    attackerProp: number;
    boosterId: number;
    isAttacker: boolean;
  }) => void;
  setRoundComplete: (roundComplete: {
    timestamp: string;
    playerOne: `0x${string}`;
    playerTwo: `0x${string}`;
    roundNumber: number;
    playerOneCard: number;
    playerTwoCard: number;
    attacker: number;
    attackerProp: number;
    resultId: number;
  }) => void;

  clearAll: () => void;
};

export const usePlayEventsStore = create<PlayEventsState>()(
  devtools(
    persist(
      (set) => ({
        handPlayed: undefined,
        roundComplete: undefined,
        gameEndPending: undefined,
        setHandPlayed: (handPlayed) => set({ handPlayed }),
        setRoundComplete: (roundComplete) => set({ roundComplete }),
        clearAll: () =>
          set({
            handPlayed: undefined,
            roundComplete: undefined,
          }),
      }),
      {
        name: 'play-events-storage',
      }
    )
  )
);
