export function RoundResultCallout() {
  return (
    <svg
      width="433"
      height="369"
      viewBox="0 0 433 369"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.3844 43.154L71.6909 42.8475L32.8967 4.05103H4.26766V4.48425H32.7169L71.3844 43.154Z"
        fill="#E8BB28"
      />
      <path
        d="M72.3827 47.8435C74.7354 47.8435 76.6504 45.9286 76.6504 43.5758C76.6504 41.223 74.7354 39.3081 72.3827 39.3081C70.0299 39.3081 68.115 41.223 68.115 43.5758C68.115 45.9286 70.0299 47.8435 72.3827 47.8435ZM72.3827 39.8934C74.4128 39.8934 76.0628 41.5457 76.0628 43.5735C76.0628 45.6014 74.4128 47.2536 72.3827 47.2536C70.3525 47.2536 68.7026 45.6014 68.7026 43.5735C68.7026 41.5457 70.3548 39.8934 72.3827 39.8934Z"
        fill="#E8BB28"
      />
      <path
        d="M73.7837 43.5739C73.7837 42.7996 73.1569 42.1729 72.3826 42.1729C71.6084 42.1729 70.9816 42.7996 70.9816 43.5739C70.9816 44.3482 71.6084 44.975 72.3826 44.975C73.1569 44.975 73.7837 44.3482 73.7837 43.5739Z"
        fill="#E8BB28"
      />
      <path
        d="M4.26744 8.53542C6.62021 8.53542 8.53516 6.62048 8.53516 4.26771C8.53516 1.91494 6.62021 0 4.26744 0C1.91467 0 -0.000273842 1.91494 -0.000273842 4.26771C-0.000273842 6.62048 1.91467 8.53542 4.26744 8.53542ZM4.26744 0.585311C6.2976 0.585311 7.94753 2.23755 7.94753 4.2654C7.94753 6.29326 6.2953 7.9455 4.26744 7.9455C2.23959 7.9455 0.587354 6.29326 0.587354 4.2654C0.587354 2.23755 2.23728 0.585311 4.26744 0.585311Z"
        fill="#E8BB28"
      />
      <path
        d="M5.66846 4.26801C5.66846 3.49373 5.04168 2.86694 4.2674 2.86694C3.49313 2.86694 2.86635 3.49373 2.86635 4.26801C2.86635 5.04228 3.49313 5.66907 4.2674 5.66907C5.04168 5.66907 5.66846 5.04228 5.66846 4.26801Z"
        fill="#E8BB28"
      />
      <path
        d="M427.384 364.154L427.691 363.848L388.897 325.051L360.268 325.051L360.268 325.484L388.717 325.484L427.384 364.154Z"
        fill="#E8BB28"
      />
      <path
        d="M428.383 368.844C430.735 368.844 432.65 366.929 432.65 364.576C432.65 362.223 430.735 360.308 428.383 360.308C426.03 360.308 424.115 362.223 424.115 364.576C424.115 366.929 426.03 368.844 428.383 368.844ZM428.383 360.893C430.413 360.893 432.063 362.546 432.063 364.574C432.063 366.601 430.413 368.254 428.383 368.254C426.353 368.254 424.703 366.601 424.703 364.574C424.703 362.546 426.355 360.893 428.383 360.893Z"
        fill="#E8BB28"
      />
      <path
        d="M429.784 364.574C429.784 363.8 429.157 363.173 428.383 363.173C427.608 363.173 426.982 363.8 426.982 364.574C426.982 365.348 427.608 365.975 428.383 365.975C429.157 365.975 429.784 365.348 429.784 364.574Z"
        fill="#E8BB28"
      />
      <path
        d="M360.267 329.535C362.62 329.535 364.535 327.62 364.535 325.268C364.535 322.915 362.62 321 360.267 321C357.915 321 356 322.915 356 325.268C356 327.62 357.915 329.535 360.267 329.535ZM360.267 321.585C362.298 321.585 363.948 323.238 363.948 325.265C363.948 327.293 362.295 328.945 360.267 328.945C358.24 328.946 356.587 327.293 356.587 325.265C356.587 323.238 358.237 321.585 360.267 321.585Z"
        fill="#E8BB28"
      />
      <path
        d="M361.668 325.268C361.668 324.494 361.042 323.867 360.267 323.867C359.493 323.867 358.866 324.494 358.866 325.268C358.866 326.042 359.493 326.669 360.267 326.669C361.042 326.669 361.668 326.042 361.668 325.268Z"
        fill="#E8BB28"
      />
      <g filter="url(#filter0_b_6497_294535)">
        <circle cx="189.855" cy="195.855" r="142.855" fill="black" fillOpacity="0.1" />
      </g>
      <g filter="url(#filter1_d_6497_294535)">
        <path
          d="M293.02 301.487C235.98 358.527 142.776 358.115 85.2334 300.573C27.691 243.031 27.2968 149.827 84.3374 92.7861C100.018 77.1057 118.512 65.5112 139.317 58.2893L139.819 59.7409C119.228 66.8911 100.932 78.3781 85.4126 93.8792C28.9634 150.328 29.3756 242.565 86.3086 299.498C143.242 356.431 235.496 356.843 291.927 300.394C328.252 264.069 342.48 210.451 329.058 160.453L330.528 160.059C344.093 210.577 329.703 264.768 293.002 301.469L293.02 301.487Z"
          fill="#E8BB28"
        />
        <path
          d="M331.98 162.425C331.621 162.783 331.155 163.07 330.618 163.213C328.987 163.643 327.303 162.676 326.872 161.045C320.349 136.709 307.393 114.344 289.437 96.3881C250.585 57.5367 192.165 43.9889 140.59 61.8914C138.995 62.4469 137.257 61.6047 136.701 60.0098C136.146 58.4148 136.988 56.6766 138.583 56.121C192.344 37.4659 253.255 51.5692 293.756 92.0693C312.482 110.796 325.976 134.093 332.786 159.468C333.073 160.561 332.75 161.672 331.998 162.425H331.98Z"
          fill="#E8BB28"
        />
        <path
          d="M284.239 293.512C282.125 295.627 279.938 297.67 277.68 299.659C262.34 313.081 244.671 322.615 225.12 327.991L224.976 327.507C244.456 322.149 262.072 312.669 277.34 299.283C304.113 275.843 320.134 243.299 322.481 207.674C324.847 171.6 312.948 136.673 288.988 109.38C239.797 53.3613 154.567 47.3938 98.9603 96.0835C63.1912 127.39 46.7761 176.062 56.1485 223.049L55.6467 223.157C51.0949 200.326 52.4927 176.6 59.6967 154.576C67.1158 131.871 80.574 111.513 98.6198 95.7251C154.424 46.8383 239.994 52.8416 289.364 109.076C313.431 136.494 325.366 171.528 323.001 207.745C320.85 240.504 307.177 270.646 284.257 293.566L284.239 293.512Z"
          fill="#E8BB28"
        />
        <path
          d="M225.765 328.475C225.639 328.601 225.496 328.69 225.317 328.744C175.319 342.489 120.769 326.038 86.3622 286.828C70.4489 268.711 59.5713 246.74 54.894 223.282C54.7865 222.727 55.1449 222.189 55.7004 222.082C56.256 221.974 56.7936 222.333 56.9011 222.888C61.5067 245.988 72.223 267.635 87.9034 285.484C121.809 324.103 175.534 340.303 224.779 326.773C225.317 326.629 225.89 326.934 226.034 327.489C226.141 327.848 226.034 328.224 225.765 328.475Z"
          fill="#E8BB28"
        />
        <path
          d="M73.7643 312.042L72.6891 313.117C8.17568 248.604 7.70974 144.074 71.6676 80.1162L72.7428 81.1914C9.37635 144.558 9.84229 248.102 73.7643 312.042V312.042Z"
          fill="#E8BB28"
        />
        <path
          d="M306.855 81.8369C370.365 146.87 370.329 250.987 306.443 314.892C305.654 315.68 304.364 315.68 303.558 314.892C302.769 314.103 302.769 312.813 303.558 312.006C366.333 249.231 365.885 146.619 302.536 83.2705L301.103 81.8369H306.855V81.8369Z"
          fill="#E8BB28"
        />
        <path
          d="M58.8724 220.092C60.539 221.759 60.539 224.447 58.8903 226.096C57.2416 227.744 54.5535 227.727 52.8869 226.078C51.2203 224.411 51.2204 221.723 52.8691 220.075C54.5177 218.426 57.2058 218.444 58.8724 220.092Z"
          fill="#E8BB28"
        />
        <path
          d="M228.041 324.765C229.707 326.432 229.707 329.12 228.059 330.769C226.41 332.417 223.722 332.399 222.055 330.751C220.389 329.084 220.389 326.396 222.037 324.747C223.686 323.099 226.374 323.117 228.041 324.765Z"
          fill="#E8BB28"
        />
        <path
          d="M75.825 309.211C77.4916 310.877 77.4916 313.565 75.8429 315.214C74.1943 316.863 71.5062 316.845 69.8396 315.196C68.173 313.529 68.173 310.841 69.8217 309.193C71.4703 307.544 74.1584 307.562 75.825 309.211Z"
          fill="#E8BB28"
        />
        <path
          d="M76.1291 76.7475C77.7957 78.4141 77.7957 81.1021 76.147 82.7508C74.4984 84.3995 71.8103 84.3815 70.1437 82.7329C68.4771 81.0663 68.4592 78.3782 70.1258 76.7295C71.7745 75.0809 74.4625 75.0988 76.1291 76.7475Z"
          fill="#E8BB28"
        />
        <path
          d="M318.682 80.8333C345.778 110.044 356.029 146.494 356.136 146.87C356.208 147.139 356.136 147.425 355.939 147.623C355.849 147.712 355.742 147.784 355.598 147.82C355.186 147.927 354.774 147.694 354.667 147.282C354.559 146.924 344.273 110.366 317.07 81.353L316.568 80.8154L318.7 80.8513L318.682 80.8333Z"
          fill="#E8BB28"
        />
      </g>
      <path
        d="M181.171 199.915L183.838 195.415V191.717L184.067 191.488H186.391L186.62 191.717V196.446L186.506 196.74L182.235 202.828L181.94 202.942H180.516L180.222 202.828L175.951 196.74L175.836 196.446V191.717L176.066 191.488H178.389L178.618 191.717V195.415L181.171 199.915ZM190.447 202.942L190.218 202.713L190.496 200.717L190.742 200.488H196.583L197.14 199.948V199.015L196.583 198.459H191.854L190.218 196.822V193.124L191.854 191.488H199.693L199.922 191.717L199.643 193.713L199.398 193.942H193.54L193 194.482V195.48L193.54 196.02H198.285L199.922 197.657V201.306L198.285 202.942H190.447Z"
        fill="#E8BB28"
      />
      <line
        x1="233.59"
        y1="124.341"
        x2="203.59"
        y2="176.302"
        stroke="#E8BB28"
        strokeWidth="1.36364"
      />
      <line
        x1="173.59"
        y1="228.264"
        x2="143.59"
        y2="280.225"
        stroke="#E8BB28"
        strokeWidth="1.36364"
      />
      <defs>
        <filter
          id="filter0_b_6497_294535"
          x="-23"
          y="-17"
          width="425.71"
          height="425.71"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="35" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_6497_294535"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_6497_294535"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_6497_294535"
          x="10"
          y="38"
          width="360.163"
          height="324"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.909804 0 0 0 0 0.733333 0 0 0 0 0.156863 0 0 0 0.6 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6497_294535" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6497_294535"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
