import {ImageProps, StaticImageData} from 'next/image';

export function blurIfStatic(src: string | StaticImageData): Pick<ImageProps, 'src' | 'placeholder'> {
	if (typeof src === 'object' && src.blurDataURL) {
		return {
			src,
			placeholder: 'blur',
		};
	}

	return {src};
}
