import * as React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { GameSteps, formatTime, useArenaStore } from '@/game';
import { cx } from '@/core/utils';

import TimeSvg from './TimeSvg';
import ArrowIcon from './ArrowIcon';

type BattleProps = {
  startTime: number | undefined;
  currentGameStep?: GameSteps;
};

export function Battle(props: BattleProps) {
  const { startTime, currentGameStep } = props;
  const [time, setTime] = React.useState(0);

  const { currentUiRound } = useArenaStore((state) => ({
    activeArenaId: state.activeArenaId,
    activeBlind: state.activeBlind,
    currentUiRound: state.currentUiRound,
  }));

  const isMyTurn =
    currentGameStep === 'your turn' ||
    currentGameStep === 'choose attack' ||
    currentGameStep === 'complete turn' ||
    currentGameStep === 'attack';

  const isEnemyTurn = currentGameStep === 'enemy turn';

  let intervalId: string | number | NodeJS.Timeout | undefined;

  React.useEffect(() => {
    if (!startTime) {
      return setTime(0);
    }

    intervalId = setInterval(() => {
      const timeSince = dayjs().diff(dayjs(startTime * 1000), 'second');

      setTime(timeSince);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startTime, time]);

  return (
    <div
      className={clsx(
        'flex-shrink origin-left -translate-x-8 scale-90 gap-y-4 text-xs',
        '2xl:text-sm tall:scale-110 taller:scale-125'
      )}
    >
      <div className={cx('flex flex-col items-center gap-y-14', '2xl:gap-y-24')}>
        <div className="flex flex-col items-center space-y-1">
          <div className="flex flex-col items-center">
            <ArrowIcon
              className={cx(
                isEnemyTurn ? 'text-[#931F28] text-glow-red' : 'text-gray-600',
                'h-auto max-w-[50%]'
              )}
            />
            <ArrowIcon
              className={cx(
                isEnemyTurn ? 'text-[#5A171D] text-glow-red' : 'text-gray-600 opacity-50',
                'h-auto max-w-[50%]'
              )}
            />
          </div>
          <p
            className={cx(
              'text-[0.5rem]',
              '2xl:text-sm',
              isEnemyTurn ? 'text-red-600 text-glow-red' : 'text-gray-600'
            )}
          >
            Enemy Turn
          </p>
        </div>
        <div className="relative flex w-full items-center justify-center">
          <div className={cx('absolute w-full')}>
            <TimeSvg className={cx('absolute inset-x-7 -translate-y-1/2', '2xl:inset-x-0')} />
          </div>
          <div className="flex w-full items-center justify-center space-y-2">
            <dl className="space-y-1 font-bold">
              <div className="space-y-2 border-b-2 border-red pb-1">
                <div className="flex space-x-2">
                  <dt className="text-red-600 text-glow-red">Time:</dt>
                  <dd className="text-red-600 text-glow-red">{formatTime(time)}</dd>
                </div>
              </div>
              <div className="flex justify-center space-x-2">
                <dt className="text-red-600 text-glow-red">Round:</dt>
                <dd className="text-red-600 text-glow-red">{currentUiRound}</dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-1">
          <p
            className={cx(
              'text-[0.5rem]',
              '2xl:text-sm',
              isMyTurn ? 'text-glow text-green' : 'text-gray-600'
            )}
          >
            Your Turn
          </p>
          <div className="flex rotate-180 flex-col items-center">
            <ArrowIcon
              className={cx(
                isMyTurn ? 'text-[#1B9A1C] text-glow-green' : 'text-gray-600 opacity-50',
                'h-auto max-w-[50%]'
              )}
            />
            <ArrowIcon
              className={cx(
                isMyTurn ? 'text-[#155E15] text-glow-green' : 'text-gray-600 opacity-30',
                'h-auto max-w-[50%]'
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
