import * as React from 'react';
import type { OwnedNftsResponse } from 'alchemy-sdk';

import { BLAST_SEPOLIA_ID, CONTRACTS, alchemy } from '@/core/lib';

export type UseNftsForOwnerProps = {
  address: `0x${string}` | undefined;
};

export function useNftsForOwner(props: UseNftsForOwnerProps) {
  const { address } = props;
  const [data, setData] = React.useState<OwnedNftsResponse>();
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!address) return;

    (async () => {
      try {
        setIsLoading(true);
        //Call the method to get the nfts owned by this address
        const response = await alchemy.nft.getNftsForOwner(address, {
          contractAddresses: [CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_CARD],
        });

        setData(response);
      } catch (error) {
        setIsError(true);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [address]);

  return { data, isError, isLoading };
}
