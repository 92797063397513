import { useContractWrite, usePrepareContractWrite } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

import { GameStatuses } from '../queries/useGame';

export type UseCancelGameProps = {
  gameId: number;
  status: GameStatuses;
};

export function useCancelGame(props: UseCancelGameProps) {
  const { gameId, status } = props;

  const { config, refetch, isRefetching } = usePrepareContractWrite({
    chainId: BLAST_SEPOLIA_ID,
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'quitGame',
    enabled:
      status === GameStatuses.Ready ||
      status === GameStatuses.Started ||
      status === GameStatuses.EndPending,
    args: [BigInt(gameId)],
  });

  return {
    ...useContractWrite(config),

    refetch,
    isRefetching,
  };
}
