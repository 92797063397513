import { useContractRead } from 'wagmi';

import { useGameEventsStore } from '@/game';
import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_CARD_ABI, ZERO_ADDRESS } from '@/core/lib';

import { useAccount } from '../../useAccount';

export function useIsApprovedForAll() {
  const { isApproved } = useGameEventsStore();
  const { address } = useAccount();

  return useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_CARD as `0x${string}`,
    abi: DEGEN_CARD_ABI,
    functionName: 'isApprovedForAll',
    args: [address ?? ZERO_ADDRESS, CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS],
    enabled: !!address,
    watch: !isApproved,
  });
}
