import { cx, truncAddress } from '@/core/utils';
import { Avatar } from '@/game';

type UserAvatarProps = {
  address: `0x${string}` | undefined;
};

export function UserAvatar(props: UserAvatarProps) {
  const { address = '' } = props;

  return (
    <div className={cx('flex w-[5.375rem] flex-col items-center gap-1')}>
      <svg
        width="103"
        height="115"
        viewBox="0 0 103 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_10_2830)">
          <mask id="avatar_image_mask" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse">
            <path
              d="M9.37611 9.88123H80.4208L94.1075 23.5679V105.002H20.9405L9.37611 94.5883V9.88123Z"
              fill="#CC2733"
              stroke="#991D26"
              strokeWidth="1.26638"
            />
          </mask>
          <foreignObject width="103" height="115" mask="url(#avatar_image_mask)">
            <div
              className={cx(
                'aspect-[116/130] clip-user-avatar [&>div]:absolute [&>div]:inset-0 [&>div]:!h-full [&>div]:!w-full'
              )}
            >
              <Avatar address={address} />
            </div>
          </foreignObject>
          <path
            d="M93.6196 105.28H94.2528V104.647V23.2952V23.0329L94.0673 22.8474L80.6565 9.43652L80.471 9.25106H80.2087H9.36771H8.73452V9.88425V94.5161V94.7982L8.94415 94.9868L20.2022 105.118L20.6131 104.661V105.28H20.6257H22.2759H93.6196ZM94.3668 105.382H20.337L8.63319 94.8421V9.13709H80.5163L94.3668 22.9876V105.382Z"
            fill="#CC2733"
            stroke="#991D26"
            strokeWidth="1.26638"
          />
          <mask id="path-3-inside-1_10_2830" fill="white">
            <path d="M9.35452 36.8198L8.87329 36.3386L10.8742 34.3504V9.1875H11.558V34.629L9.35452 36.8198Z" />
          </mask>
          <path
            d="M9.35452 36.8198L8.87329 36.3386L10.8742 34.3504V9.1875H11.558V34.629L9.35452 36.8198Z"
            fill="#CC2733"
          />
          <path
            d="M9.35452 36.8198L8.45906 37.7153L9.35195 38.6082L10.2474 37.7178L9.35452 36.8198ZM8.87329 36.3386L7.98068 35.4403L7.07952 36.3357L7.97783 37.234L8.87329 36.3386ZM10.8742 34.3504L11.7668 35.2487L12.1406 34.8773V34.3504H10.8742ZM10.8742 9.1875V7.92112H9.60781V9.1875H10.8742ZM11.558 9.1875H12.8244V7.92112H11.558V9.1875ZM11.558 34.629L12.4509 35.527L12.8244 35.1557V34.629H11.558ZM10.25 35.9244L9.76875 35.4431L7.97783 37.234L8.45906 37.7153L10.25 35.9244ZM9.7659 37.2369L11.7668 35.2487L9.98157 33.4521L7.98068 35.4403L9.7659 37.2369ZM12.1406 34.3504V9.1875H9.60781V34.3504H12.1406ZM10.8742 10.4539H11.558V7.92112H10.8742V10.4539ZM10.2916 9.1875V34.629H12.8244V9.1875H10.2916ZM10.6651 33.7309L8.46164 35.9218L10.2474 37.7178L12.4509 35.527L10.6651 33.7309Z"
            fill="#991D26"
            mask="url(#path-3-inside-1_10_2830)"
          />
          <path
            d="M47.4923 104.526L49.7602 102.266H93.6697V104.696L47.4923 104.526Z"
            fill="#CC2733"
            stroke="#991D26"
            strokeWidth="1.26638"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_10_2830"
            x="0"
            y="0.503906"
            width="103"
            height="113.511"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.8 0 0 0 0 0.152941 0 0 0 0 0.2 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_2830" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_10_2830"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <div
        className={cx(
          'flex w-full items-center justify-center border border-red p-2 text-center text-[0.5rem] font-medium text-cyan'
        )}
      >
        {truncAddress({
          address: address || '',
          length: 4,
        }) || ''}
      </div>
    </div>
  );
}
