import * as React from 'react';

import { cx } from '@/core/utils';

type SnazzySecondaryButtonSvgProps = {
  children: React.ReactNode;
};

export const SnazzySecondaryButtonSvg = ({ children }: SnazzySecondaryButtonSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="178"
      height="66"
      viewBox="0 0 178 66"
      fill="none"
    >
      <path d="M1 1H17V65H1V49L5 45V21L1 17V1Z" fill="#020100" fillOpacity="0.5" />

      <path d="M17 1H161V65H17V1Z" fill="#020100" fillOpacity="0.5" />
      <path d="M161 65L177 49.2462V1H161V65Z" fill="#020100" fillOpacity="0.5" />
      <path
        d="M17 1H1V17L5 21V45L1 49V65H17M17 1V65M17 1H161M17 65H161M161 1V65M161 1H177V49.2462L161 65"
        stroke="#E8BB28"
        strokeLinejoin="round"
      />
      <foreignObject width="178" height="66">
        {children}
      </foreignObject>
    </svg>
  );
};

export const SNAZZY_BUTTON_CLASSES = ({ variant }: { variant: 'primary' | 'secondary' }) =>
  cx(
    'inline-flex items-center justify-center py-5 text-base font-extrabold leading-none tracking-wide text-neutral-900 relative',
    variant === 'primary' &&
      'bg-yellow-thirtiary px-9 clip-snazzy-button hover:saturate-150 text-neutral-900',
    variant === 'secondary' && 'text-yellow-thirtiary w-48 h-16 relative hover:opacity-80',
    'md:text-lg'
  );

export type SnazzyButtonProps = React.ComponentPropsWithoutRef<'button'> & {
  variant?: 'primary' | 'secondary';
};

export const SnazzyButton = React.forwardRef<HTMLButtonElement, SnazzyButtonProps>((props, ref) => {
  const { className, variant = 'primary', children, ...rest } = props;

  return (
    <button ref={ref} className={cx(SNAZZY_BUTTON_CLASSES({ variant }), className)} {...rest}>
      {variant === 'primary' && children}

      {variant === 'secondary' && (
        <SnazzySecondaryButtonSvg>
          <div className="flex h-full w-full items-center justify-center">{children}</div>
        </SnazzySecondaryButtonSvg>
      )}
    </button>
  );
});

SnazzyButton.displayName = 'SnazzyButton';
