export function ActiveShieldBooster(props: { className?: string }) {
  return (
    <svg
      width="389"
      height="563"
      viewBox="0 0 389 563"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_26_36130)" filter="url(#filter0_b_26_36130)">
        <rect width="389" height="563" fill="#3A3838" fillOpacity="0.18" />
        <path
          d="M389 0H0V563H389V0Z"
          stroke="#51D2E7"
          strokeWidth="0.485795"
          strokeMiterlimit="10"
        />
        <path
          d="M385.199 489.939V557.26H326.642"
          stroke="#51D2E7"
          strokeWidth="2.91477"
          strokeMiterlimit="10"
        />
        <path
          d="M2.96899 71.5857V4.29871H61.5565"
          stroke="#51D2E7"
          strokeWidth="2.91477"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_26_36130"
          x="-15.1442"
          y="-15.1442"
          width="419.288"
          height="593.288"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.57211" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_26_36130" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_26_36130"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_26_36130">
          <rect width="389" height="563" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
