import Image, { type StaticImageData } from 'next/image';

import { cx } from '@/core/utils';

export type BackgroundProps = {
  image: StaticImageData;
  alt?: string;
  hasGrid?: boolean;
  z?: string;
  isFixed?: boolean;
  className?: string;
};

export function Background(props: BackgroundProps) {
  const { image, alt, hasGrid = true, z, isFixed = false } = props;

  return (
    <div
      className={cx(
        'pointer-events-none inset-0 touch-none select-none opacity-30',
        z ?? '-z-10',
        hasGrid && 'bg-grid',
        isFixed ? 'fixed' : 'absolute',
        props.className
      )}
    >
      <Image
        fill
        src={image}
        alt={alt || ''}
        placeholder="blur"
        className="object-cover object-center"
      />
    </div>
  );
}
