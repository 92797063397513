export function truncAddress({
  address,
  length = 4,
}: {
  address: string | `0x${string}` | undefined;
  length: number;
}) {
  if (!address) return '';

  return `${address.slice(0, length)}...${address.slice(-length)}`;
}
