import * as React from 'react';

export const CardStackIcon = React.forwardRef<SVGSVGElement, React.ComponentPropsWithoutRef<'svg'>>(
  (props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={31}
        height={31}
        fill="none"
        {...props}
      >
        <g fill="#887B7C" clipPath="url(#a)">
          <path d="m18.9 16.685 2.839-10.597a2.039 2.039 0 0 0-1.44-2.495L9.059.582a2.039 2.039 0 0 0-2.495 1.44l-1.03 3.843h9.583A3.786 3.786 0 0 1 18.9 9.647v7.038ZM29.25 21.958l-1.985-5.452-8.366 9.97v1.416l9.133-3.324a2.039 2.039 0 0 0 1.217-2.61ZM18.899 23.76l8.31-9.904c.722-.86.61-2.147-.25-2.869L23.1 7.75 18.9 23.43v.33ZM7.462 18.956l1.837 2.572 1.836-2.572L9.3 16.385l-1.837 2.571Z" />
          <path d="M15.117 7.61H3.48a2.039 2.039 0 0 0-2.037 2.037v18.62c0 1.122.914 2.036 2.037 2.036h11.637a2.039 2.039 0 0 0 2.036-2.037V9.647a2.039 2.039 0 0 0-2.036-2.037Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.512.512h29.79v29.79H.513z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

CardStackIcon.displayName = 'CardStackIcon';
