import clsx from 'clsx';
import { motion } from 'framer-motion';

import { GameSteps } from './Play';

type ChooseAttackArenaEffectProps = {
  arenaEffect: number;
  currentGameStep: GameSteps;
};

function renderMotionDiv(arenaEffect: number) {
  return (
    <motion.div
      className={clsx(
        'pointer-events-none absolute left-0 top-0 inline-flex -translate-y-full touch-none select-none flex-col text-5xl font-bold uppercase',
        arenaEffect === 90 ? 'text-yellow' : 'text-green'
      )}
      animate={{
        x: ['-50%', '-75%'],
        y: ['-100%', '-100%'],
        opacity: [0.5, 1, 0.8, 0],
      }}
      transition={{
        duration: 10,
        delay: 1,
      }}
    >
      <span>ARENA</span>
      <span>EFFECT</span>
      <span>
        {arenaEffect === 90 && '-10%'}
        {arenaEffect === 110 && '+10%'}
      </span>
    </motion.div>
  );
}

export function ChooseAttackArenaEffect(props: ChooseAttackArenaEffectProps) {
  const { arenaEffect, currentGameStep } = props;

  if (arenaEffect === 100) return null;

  if (currentGameStep === 'complete turn' || currentGameStep === 'choose attack') {
    return renderMotionDiv(arenaEffect);
  }

  return null;
}
