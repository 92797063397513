import { useAccount, useContractRead } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

type UseGetActiveGamesProps = {
  shouldWatch?: boolean;
};

export function useGetActiveGames(props?: UseGetActiveGamesProps) {
  const { shouldWatch = false } = props ?? {};
  const { address } = useAccount();

  const { data, ...rest } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'getActiveGames',
    args: [address as `0x${string}`],
    watch: shouldWatch,
    enabled: !!address,
  });

  return {
    data: data?.map(Number) ?? [],
    ...rest,
  };
}
