import React from 'react';

type Props = {
  className?: string;
};

function ArrowIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="27" viewBox="0 0 37 27" {...props}>
      <path
        fill="currentColor"
        d="M36.995 18.457L18.537 0C11.323 7.21 7.284 11.248.075 18.457v7.835L18.532 7.834c7.215 7.21 11.253 11.248 18.463 18.458v-7.835z"
      ></path>
    </svg>
  );
}

export default ArrowIcon;
