'use client';

import { useCallback, useRef, type RefCallback } from 'react';

export function useMultipleRefs<T extends HTMLElement>() {
  const refs = useRef<T[]>([]);

  refs.current = [];

  const addRef: RefCallback<T> = useCallback((ref) => {
    if (ref && !refs.current.includes(ref)) {
      refs.current.push(ref);
    }
  }, []);

  return [refs, addRef] as const;
}
