'use client';

import Image from 'next/image';
import { motion } from 'framer-motion';
import { useEffect, Profiler } from 'react';

import decks from '@/assets/images/decks/decks.png';

type ShuffleDeckProps = {
  children: React.ReactNode;
  isCardsShuffle: boolean;
  isAttacker: boolean;
  isLetterShuffleAnimation: boolean;
  setIsCardsShuffle: (isStarted: boolean) => void;
  setIsLetterShuffleAnimation: (isStarted: boolean) => void;
  shufflePlay: () => void;
};

export function ShuffleDeck(props: ShuffleDeckProps) {
  const {
    children,
    isCardsShuffle,
    isAttacker,
    isLetterShuffleAnimation,
    setIsCardsShuffle,
    shufflePlay,
    setIsLetterShuffleAnimation,
  } = props;
  const title = 'Your turn, shuffle to start';

  useEffect(() => {
    if (!isAttacker) {
      setTimeout(() => {
        setIsLetterShuffleAnimation(false);
        setIsCardsShuffle(true);
        shufflePlay();
      }, 1300);
    }
  }, [isAttacker]);

  const startShuffle = () => {
    setTimeout(() => {
      setIsLetterShuffleAnimation(false);
      setIsCardsShuffle(true);
      shufflePlay();
    }, 1300);
  };
  const onRender = () => {
    console.log('render');
  };

  return (
    <Profiler id="Suffle compon" onRender={onRender}>
      <div className="absolute -left-8 top-0 z-50 flex h-screen w-screen items-center justify-center overflow-hidden bg-black/70 backdrop-blur-sm backdrop-filter">
        {isCardsShuffle ? (
          <div className="flex flex-1 items-center justify-center">{children}</div>
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center">
            <div className="title mb-16 flex text-center text-3xl font-extrabold">
              {title.split('').map((letter, index) => (
                <motion.span
                  key={index}
                  initial={isLetterShuffleAnimation ? { opacity: 0, x: -20 } : { opacity: 1, x: 0 }}
                  animate={isLetterShuffleAnimation ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }}
                  transition={{ delay: index * 0.05 }}
                  className="inline-block"
                >
                  {letter === ' ' ? <span>&nbsp;</span> : letter}
                </motion.span>
              ))}
            </div>
            <Image src={decks} alt="Decks" />
            <motion.button
              initial={
                isLetterShuffleAnimation
                  ? { y: -100, opacity: 0, scale: 0.3 }
                  : { y: 0, opacity: 1, scale: 1 }
              }
              animate={
                isLetterShuffleAnimation
                  ? { y: 0, opacity: 1, scale: 1 }
                  : { y: -100, opacity: 0, scale: 0.3 }
              }
              transition={{ duration: 0.7 }}
              className="mt-16 block h-16 w-96 bg-yellow text-2xl font-extrabold text-gray-dark hover:opacity-70"
              onClick={startShuffle}
            >
              shuffle
            </motion.button>
          </div>
        )}
      </div>
    </Profiler>
  );
}
