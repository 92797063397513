import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type SettingsState = {
  isArenaBg: boolean;
  isMusic: boolean;
  isSfx: boolean;
  isTooltip: boolean;
  setIsArenaBg: (isArenaBg: boolean) => void;
  setIsMusic: (isMusic: boolean) => void;
  setIsSfx: (isSfx: boolean) => void;
  setIsTooltip: (isTooltip: boolean) => void;
};

export const useSettingsStore = create<SettingsState>()(
  devtools(
    persist(
      (set) => ({
        isArenaBg: true,
        isMusic: true,
        isSfx: true,
        isTooltip: true,
        setIsArenaBg: (isArenaBg: boolean) => set({ isArenaBg }),
        setIsMusic: (isMusic: boolean) => set({ isMusic }),
        setIsSfx: (isSfx: boolean) => set({ isSfx }),
        setIsTooltip: (isTooltip: boolean) => set({ isTooltip }),
      }),
      {
        name: 'settings-storage',
      }
    )
  )
);
