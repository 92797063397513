'use client';

import { useEffect } from 'react';
import { useAccount, useBlockNumber } from 'wagmi';
import { createPublicClient, http } from 'viem';

import { GameCreated, GameJoined, useArenaStore, useGameEventsStore } from '@/game';
import { blastSepolia } from '@/utils/chains';

import { watchGameCreatedLogs, watchGameJoinedLogs, watchGameStartedLogs } from './helper';

export const publicClient = createPublicClient({
  chain: blastSepolia,
  transport: http(
    'https://skilled-thrumming-seed.blast-sepolia.quiknode.pro/3f08d4301519a9edabe35ecdedae1174d6ca3ca8'
  ),
});

export const useWatchGameCreationEvents = () => {
  const { address } = useAccount();
  const { data: blockNumber } = useBlockNumber();

  const { activeGameId, setActiveGameId } = useArenaStore();

  const {
    setGameStarted,
    setGameCreated: setGameCreatedStore,
    setGameJoined: setGameJoinedStore,
  } = useGameEventsStore();

  const setGameCreated = (gameCreated: GameCreated) => {
    setGameCreatedStore(gameCreated);
    setActiveGameId(gameCreated.currentGameId);
  };

  const setGameJoined = (gameJoined: GameJoined) => {
    setGameJoinedStore(gameJoined);
    setActiveGameId(gameJoined.currentGameId);
  };

  useEffect(() => {
    if (!address || !blockNumber) return;

    if (!activeGameId) {
      watchGameCreatedLogs({
        address,
        blockNumber,
        setGameCreated,
      });

      watchGameJoinedLogs({
        address,
        blockNumber,
        setGameJoined,
      });
    } else {
      watchGameStartedLogs({
        gameId: activeGameId,
        blockNumber,
        setGameStarted,
      });
    }
  }, [blockNumber, address]);
};
