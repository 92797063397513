'use client';

import { Listbox as HeadlessListBox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import { HiChevronDown, HiOutlineExclamationTriangle } from 'react-icons/hi2';

import { useAudio, useClickButtonAudio } from '@/core/hooks';
import { useSettingsStore } from '@/play/store';

export interface ResolvedListboxOptions {
  key: string;
  display: string;
  disabled?: boolean;
  isOutdated?: boolean;
}

export interface ListboxProps<T> {
  options: T[];
  selected: T;
  set: (item: T) => void;
  isOutdated?: boolean;

  /**
   * Resolves an option to a listbox item, which is used to display the option in the listbox.
   * @param option The option to resolve
   * @returns A resolved listbox item
   */
  resolve: (option: T) => ResolvedListboxOptions;
}

export function Listbox<T>(props: ListboxProps<T>) {
  const [isClick, setIsClick] = useState(false);
  const clickAudio = useClickButtonAudio();
  const { isSfx } = useSettingsStore();
  const selectAudio = useAudio({ src: '/audio/select-dropdown.mp3', volume: 0.3 });

  const handleSelect = () => {
    setIsClick(true);

    if (isSfx) {
      selectAudio?.play();
    }
  };

  React.useEffect(() => {
    return () => {
      if (selectAudio) {
        selectAudio.pause();
        selectAudio.src = '';
      }
    };
  }, []);

  return (
    <HeadlessListBox value={props.selected} onChange={props.set}>
      <div className="z-1 relative">
        <HeadlessListBox.Button
          className="relative w-full cursor-default border border-purple-light bg-purple-light/40 py-4 pl-3 pr-10 text-left font-display text-neutral-600"
          onClick={() => isSfx && clickAudio?.play()}
        >
          <div className="flex w-full items-center justify-between">
            <span className="truncate">{props.resolve(props.selected).display}</span>
            {props.isOutdated && (
              <div className="text-red-500 ml-2 flex items-center">
                <HiOutlineExclamationTriangle className="mr-1" />
                NEED TO UPDATE
              </div>
            )}
          </div>

          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <HiChevronDown className="text-gray-400 h-4 w-4 text-brokenWhite" aria-hidden="true" />
          </span>
        </HeadlessListBox.Button>

        <Transition as={Fragment}>
          <HeadlessListBox.Options className="absolute z-50 flex max-h-60 w-full flex-col overflow-x-hidden bg-[#2B252C] py-1 text-base focus:outline-none sm:text-sm">
            {props.options.map((option) => {
              const resolved = props.resolve(option);

              return (
                <Transition.Child
                  enter="transition ease-in duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-out duration-500 transform delay-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  beforeLeave={handleSelect}
                  afterLeave={() => setIsClick(false)}
                  key={resolved.key}
                >
                  <HeadlessListBox.Option
                    className={({ active, selected }) =>
                      clsx(
                        'option cursor-pointer select-none py-3 pl-10 pr-4',
                        active ? 'bg-purple-light text-yellow' : 'text-white',
                        isClick &&
                          selected &&
                          'duration-2000 scale-110 transform bg-purple-light text-yellow transition-all'
                      )
                    }
                    value={option}
                    disabled={!!resolved.disabled}
                  >
                    {({ selected }) => (
                      <div className="flex w-full items-center justify-between">
                        <span
                          className={clsx(
                            'block truncate font-display',
                            selected ? 'font-medium' : 'font-normal'
                          )}
                        >
                          {resolved.display}
                        </span>
                        {resolved.isOutdated && (
                          <div className="text-red-500 ml-2 flex items-center">
                            <HiOutlineExclamationTriangle className="mr-1" />
                            NEED TO UPDATE
                          </div>
                        )}
                      </div>
                    )}
                  </HeadlessListBox.Option>
                </Transition.Child>
              );
            })}
          </HeadlessListBox.Options>
        </Transition>
      </div>
    </HeadlessListBox>
  );
}
