import * as React from 'react';

export const ClaimDividerIcon = React.forwardRef<
  SVGSVGElement,
  React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => {
  return (
    <svg
      ref={ref}
      width="342"
      height="9"
      viewBox="0 0 342 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.05777 4.61057H5.75248V5.75252H4.61061V8.05777H3.46873V5.75252H2.30529V4.61057H0V3.4472H2.30529V2.30532H3.46873V0H4.61061V2.30532H5.75248V3.4472H8.05777V4.61057ZM2.30529 2.30532H1.16341V1.16344H2.30529V2.30532ZM2.30529 6.91589H1.16341V5.75252H2.30529V6.91589ZM6.91589 2.30532H5.77402V1.16344H6.91589V2.30532ZM6.91589 6.91589H5.77402V5.75252H6.91589V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M17.5987 4.61057H15.2934V5.75252H14.1515V8.05777H13.0097V5.75252H11.8678V4.61057H9.5625V3.4472H11.8678V2.30532H13.0097V0H14.1515V2.30532H15.2934V3.4472H17.5987V4.61057ZM11.8463 2.30532H10.7044V1.16344H11.8463V2.30532ZM11.8463 6.91589H10.7044V5.75252H11.8463V6.91589ZM16.4569 2.30532H15.315V1.16344H16.4569V2.30532ZM16.4569 6.91589H15.315V5.75252H16.4569V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M27.1417 4.61057H24.8364V5.75252H23.6945V8.05777H22.5527V5.75252H21.4108V4.61057H19.1055V3.4472H21.4108V2.30532H22.5527V0H23.6945V2.30532H24.8364V3.4472H27.1417V4.61057ZM21.3892 2.30532H20.2258V1.16344H21.3892V2.30532ZM21.3892 6.91589H20.2258V5.75252H21.3892V6.91589ZM25.9998 2.30532H24.8364V1.16344H25.9998V2.30532ZM25.9998 6.91589H24.8364V5.75252H25.9998V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M36.6925 4.61057H34.3872V5.75252H33.2453V8.05777H32.1034V5.75252H30.9616V4.61057H28.6562V3.4472H30.9616V2.30532H32.1034V0H33.2453V2.30532H34.3872V3.4472H36.6925V4.61057ZM30.94 2.30532H29.7982V1.16344H30.94V2.30532ZM30.94 6.91589H29.7982V5.75252H30.94V6.91589ZM35.5506 2.30532H34.4088V1.16344H35.5506V2.30532ZM35.5506 6.91589H34.4088V5.75252H35.5506V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M46.2355 4.61057H43.9302V5.75252H42.7883V8.05777H41.6464V5.75252H40.5045V4.61057H38.1992V3.4472H40.5045V2.30532H41.6464V0H42.7883V2.30532H43.9302V3.4472H46.2355V4.61057ZM40.483 2.30532H39.3411V1.16344H40.483V2.30532ZM40.483 6.91589H39.3411V5.75252H40.483V6.91589ZM45.0936 2.30532H43.9517V1.16344H45.0936V2.30532ZM45.0936 6.91589H43.9517V5.75252H45.0936V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M55.7766 4.61057H53.4712V5.75252H52.3294V8.05777H51.1659V5.75252H50.0241V4.61057H47.7188V3.4472H50.0241V2.30532H51.1659V0H52.3294V2.30532H53.4712V3.4472H55.7766V4.61057ZM50.0241 2.30532H48.8822V1.16344H50.0241V2.30532ZM50.0241 6.91589H48.8822V5.75252H50.0241V6.91589ZM54.6347 2.30532H53.4928V1.16344H54.6347V2.30532ZM54.6347 6.91589H53.4928V5.75252H54.6347V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M65.3253 4.61057H63.02V5.75252H61.8781V8.05777H60.7363V5.75252H59.5943V4.61057H57.2891V3.4472H59.5943V2.30532H60.7363V0H61.8781V2.30532H63.02V3.4472H65.3253V4.61057ZM59.5728 2.30532H58.4309V1.16344H59.5728V2.30532ZM59.5728 6.91589H58.4309V5.75252H59.5728V6.91589ZM64.1834 2.30532H63.0415V1.16344H64.1834V2.30532ZM64.1834 6.91589H63.0415V5.75252H64.1834V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M74.8409 4.61057H72.5356V5.75252H71.3938V8.05777H70.2519V5.75252H69.11V4.61057H66.8047V3.4472H69.11V2.30532H70.2519V0H71.3938V2.30532H72.5356V3.4472H74.8409V4.61057ZM69.0884 2.30532H67.9466V1.16344H69.0884V2.30532ZM69.0884 6.91589H67.9466V5.75252H69.0884V6.91589ZM73.699 2.30532H72.5572V1.16344H73.699V2.30532ZM73.699 6.91589H72.5572V5.75252H73.699V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M84.3878 4.61057H82.0825V5.75252H80.9406V8.05777H79.7987V5.75252H78.6568V4.61057H76.3516V3.4472H78.6568V2.30532H79.7987V0H80.9406V2.30532H82.0825V3.4472H84.3878V4.61057ZM78.6353 2.30532H77.4719V1.16344H78.6353V2.30532ZM78.6353 6.91589H77.4719V5.75252H78.6353V6.91589ZM83.2459 2.30532H82.0825V1.16344H83.2459V2.30532ZM83.2459 6.91589H82.0825V5.75252H83.2459V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M93.9308 4.61057H91.6255V5.75252H90.4836V8.05777H89.3417V5.75252H88.1999V4.61057H85.8945V3.4472H88.1999V2.30532H89.3417V0H90.4836V2.30532H91.6255V3.4472H93.9308V4.61057ZM88.1783 2.30532H87.0364V1.16344H88.1783V2.30532ZM88.1783 6.91589H87.0364V5.75252H88.1783V6.91589ZM92.7889 2.30532H91.647V1.16344H92.7889V2.30532ZM92.7889 6.91589H91.647V5.75252H92.7889V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M103.482 4.61057H101.176V5.75252H100.034V8.05777H98.8925V5.75252H97.7506V4.61057H95.4453V3.4472H97.7506V2.30532H98.8925V0H100.034V2.30532H101.176V3.4472H103.482V4.61057ZM97.7291 2.30532H96.5872V1.16344H97.7291V2.30532ZM97.7291 6.91589H96.5872V5.75252H97.7291V6.91589ZM102.34 2.30532H101.198V1.16344H102.34V2.30532ZM102.34 6.91589H101.198V5.75252H102.34V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M113.025 4.61057H110.719V5.75252H109.577V8.05777H108.435V5.75252H107.294V4.61057H104.988V3.4472H107.294V2.30532H108.435V0H109.577V2.30532H110.719V3.4472H113.025V4.61057ZM107.272 2.30532H106.13V1.16344H107.272V2.30532ZM107.272 6.91589H106.13V5.75252H107.272V6.91589ZM111.883 2.30532H110.741V1.16344H111.883V2.30532ZM111.883 6.91589H110.741V5.75252H111.883V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M115.65 3.4687H114.508V1.16344H115.65V0H120.26V1.16344H121.402V3.4687H120.26V1.16344H116.813V2.30532H117.955V3.4472H120.26V4.61057H122.566V6.91589H121.424V8.05777H115.671V6.91589H114.529V4.61057H115.671V3.4472L115.65 3.4687ZM115.65 6.93746H120.26V5.77402H117.955V4.63214H115.65V6.93746Z"
        fill="currentColor"
      />
      <path
        d="M132.114 4.61057H129.809V5.75252H128.667V8.05777H127.525V5.75252H126.383V4.61057H124.078V3.4472H126.383V2.30532H127.525V0H128.667V2.30532H129.809V3.4472H132.114V4.61057ZM126.362 2.30532H125.22V1.16344H126.362V2.30532ZM126.362 6.91589H125.22V5.75252H126.362V6.91589ZM130.972 2.30532H129.831V1.16344H130.972V2.30532ZM130.972 6.91589H129.831V5.75252H130.972V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M141.63 4.61057H139.325V5.75252H138.183V8.05777H137.041V5.75252H135.899V4.61057H133.594V3.4472H135.899V2.30532H137.041V0H138.183V2.30532H139.325V3.4472H141.63V4.61057ZM135.878 2.30532H134.736V1.16344H135.878V2.30532ZM135.878 6.91589H134.736V5.75252H135.878V6.91589ZM140.488 2.30532H139.346V1.16344H140.488V2.30532ZM140.488 6.91589H139.346V5.75252H140.488V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M151.177 4.61057H148.872V5.75252H147.73V8.05777H146.588V5.75252H145.446V4.61057H143.141V3.4472H145.446V2.30532H146.588V0H147.73V2.30532H148.872V3.4472H151.177V4.61057ZM145.424 2.30532H144.261V1.16344H145.424V2.30532ZM145.424 6.91589H144.261V5.75252H145.424V6.91589ZM150.035 2.30532H148.893V1.16344H150.035V2.30532ZM150.035 6.91589H148.893V5.75252H150.035V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M160.72 4.61057H158.415V5.75252H157.273V8.05777H156.131V5.75252H154.989V4.61057H152.684V3.4472H154.989V2.30532H156.131V0H157.273V2.30532H158.415V3.4472H160.72V4.61057ZM154.967 2.30532H153.825V1.16344H154.967V2.30532ZM154.967 6.91589H153.825V5.75252H154.967V6.91589ZM159.578 2.30532H158.436V1.16344H159.578V2.30532ZM159.578 6.91589H158.436V5.75252H159.578V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M163.353 3.4687H162.211V1.16344H163.353V0H167.963V1.16344H169.105V3.4687H167.963V1.16344H164.516V2.30532H165.658V3.4472H167.963V4.61057H170.269V6.91589H169.127V8.05777H163.374V6.91589H162.232V4.61057H163.374V3.4472L163.353 3.4687ZM163.353 6.93746H167.963V5.77402H165.658V4.63214H163.353V6.93746Z"
        fill="currentColor"
      />
      <path
        d="M172.896 3.4687H171.754V1.16344H172.896V0H177.506V1.16344H178.648V3.4687H177.506V1.16344H174.059V2.30532H175.201V3.4472H177.506V4.61057H179.812V6.91589H178.67V8.05777H172.917V6.91589H171.775V4.61057H172.917V3.4472L172.896 3.4687ZM172.896 6.93746H177.506V5.77402H175.201V4.63214H172.896V6.93746Z"
        fill="currentColor"
      />
      <path
        d="M189.357 4.61057H187.051V5.75252H185.909V8.05777H184.768V5.75252H183.626V4.61057H181.32V3.4472H183.626V2.30532H184.768V0H185.909V2.30532H187.051V3.4472H189.357V4.61057ZM183.604 2.30532H182.462V1.16344H183.604V2.30532ZM183.604 6.91589H182.462V5.75252H183.604V6.91589ZM188.215 2.30532H187.073V1.16344H188.215V2.30532ZM188.215 6.91589H187.073V5.75252H188.215V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M198.903 4.61057H196.598V5.75252H195.456V8.05777H194.314V5.75252H193.172V4.61057H190.867V3.4472H193.172V2.30532H194.314V0H195.456V2.30532H196.598V3.4472H198.903V4.61057ZM193.151 2.30532H192.009V1.16344H193.151V2.30532ZM193.151 6.91589H192.009V5.75252H193.151V6.91589ZM197.762 2.30532H196.62V1.16344H197.762V2.30532ZM197.762 6.91589H196.62V5.75252H197.762V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M208.419 4.61057H206.114V5.75252H204.972V8.05777H203.83V5.75252H202.688V4.61057H200.383V3.4472H202.688V2.30532H203.83V0H204.972V2.30532H206.114V3.4472H208.419V4.61057ZM202.667 2.30532H201.503V1.16344H202.667V2.30532ZM202.667 6.91589H201.503V5.75252H202.667V6.91589ZM207.277 2.30532H206.114V1.16344H207.277V2.30532ZM207.277 6.91589H206.114V5.75252H207.277V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M217.966 4.61057H215.661V5.75252H214.519V8.05777H213.377V5.75252H212.235V4.61057H209.93V3.4472H212.235V2.30532H213.377V0H214.519V2.30532H215.661V3.4472H217.966V4.61057ZM212.213 2.30532H211.072V1.16344H212.213V2.30532ZM212.213 6.91589H211.072V5.75252H212.213V6.91589ZM216.824 2.30532H215.682V1.16344H216.824V2.30532ZM216.824 6.91589H215.682V5.75252H216.824V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M227.509 4.61057H225.204V5.75252H224.062V8.05777H222.92V5.75252H221.778V4.61057H219.473V3.4472H221.778V2.30532H222.92V0H224.062V2.30532H225.204V3.4472H227.509V4.61057ZM221.756 2.30532H220.615V1.16344H221.756V2.30532ZM221.756 6.91589H220.615V5.75252H221.756V6.91589ZM226.367 2.30532H225.225V1.16344H226.367V2.30532ZM226.367 6.91589H225.225V5.75252H226.367V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M237.06 4.61057H234.754V5.75252H233.613V8.05777H232.471V5.75252H231.329V4.61057H229.023V3.4472H231.329V2.30532H232.471V0H233.613V2.30532H234.754V3.4472H237.06V4.61057ZM231.307 2.30532H230.165V1.16344H231.307V2.30532ZM231.307 6.91589H230.165V5.75252H231.307V6.91589ZM235.918 2.30532H234.776V1.16344H235.918V2.30532ZM235.918 6.91589H234.776V5.75252H235.918V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M246.603 4.61057H244.297V5.75252H243.155V8.05777H242.014V5.75252H240.872V4.61057H238.566V3.4472H240.872V2.30532H242.014V0H243.155V2.30532H244.297V3.4472H246.603V4.61057ZM240.85 2.30532H239.708V1.16344H240.85V2.30532ZM240.85 6.91589H239.708V5.75252H240.85V6.91589ZM245.461 2.30532H244.319V1.16344H245.461V2.30532ZM245.461 6.91589H244.319V5.75252H245.461V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M256.149 4.61057H253.844V5.75252H252.702V8.05777H251.56V5.75252H250.419V4.61057H248.113V3.4472H250.419V2.30532H251.56V0H252.702V2.30532H253.844V3.4472H256.149V4.61057ZM250.397 2.30532H249.255V1.16344H250.397V2.30532ZM250.397 6.91589H249.255V5.75252H250.397V6.91589ZM255.008 2.30532H253.866V1.16344H255.008V2.30532ZM255.008 6.91589H253.866V5.75252H255.008V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M265.693 4.61057H263.387V5.75252H262.245V8.05777H261.103V5.75252H259.962V4.61057H257.656V3.4472H259.962V2.30532H261.103V0H262.245V2.30532H263.387V3.4472H265.693V4.61057ZM259.94 2.30532H258.798V1.16344H259.94V2.30532ZM259.94 6.91589H258.798V5.75252H259.94V6.91589ZM264.551 2.30532H263.409V1.16344H264.551V2.30532ZM264.551 6.91589H263.409V5.75252H264.551V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M275.208 4.61057H272.903V5.75252H271.761V8.05777H270.619V5.75252H269.477V4.61057H267.172V3.4472H269.477V2.30532H270.619V0H271.761V2.30532H272.903V3.4472H275.208V4.61057ZM269.456 2.30532H268.292V1.16344H269.456V2.30532ZM269.456 6.91589H268.292V5.75252H269.456V6.91589ZM274.066 2.30532H272.924V1.16344H274.066V2.30532ZM274.066 6.91589H272.924V5.75252H274.066V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M284.755 4.61057H282.45V5.75252H281.308V8.05777H280.166V5.75252H279.024V4.61057H276.719V3.4472H279.024V2.30532H280.166V0H281.308V2.30532H282.45V3.4472H284.755V4.61057ZM279.003 2.30532H277.861V1.16344H279.003V2.30532ZM279.003 6.91589H277.861V5.75252H279.003V6.91589ZM283.613 2.30532H282.471V1.16344H283.613V2.30532ZM283.613 6.91589H282.471V5.75252H283.613V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M294.298 4.61057H291.993V5.75252H290.851V8.05777H289.709V5.75252H288.567V4.61057H286.262V3.4472H288.567V2.30532H289.709V0H290.851V2.30532H291.993V3.4472H294.298V4.61057ZM288.545 2.30532H287.404V1.16344H288.545V2.30532ZM288.545 6.91589H287.404V5.75252H288.545V6.91589ZM293.156 2.30532H292.014V1.16344H293.156V2.30532ZM293.156 6.91589H292.014V5.75252H293.156V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M303.849 4.61057H301.543V5.75252H300.402V8.05777H299.26V5.75252H298.118V4.61057H295.812V3.4472H298.118V2.30532H299.26V0H300.402V2.30532H301.543V3.4472H303.849V4.61057ZM298.096 2.30532H296.954V1.16344H298.096V2.30532ZM298.096 6.91589H296.954V5.75252H298.096V6.91589ZM302.707 2.30532H301.565V1.16344H302.707V2.30532ZM302.707 6.91589H301.565V5.75252H302.707V6.91589Z"
        fill="currentColor"
      />
      <path
        d="M306.474 3.4687H305.332V1.16344H306.474V0H311.084V1.16344H312.226V3.4687H311.084V1.16344H307.637V2.30532H308.779V3.4472H311.084V4.61057H313.39V6.91589H312.248V8.05777H306.495V6.91589H305.354V4.61057H306.495V3.4472L306.474 3.4687ZM306.474 6.93746H311.084V5.77402H308.779V4.63214H306.474V6.93746Z"
        fill="currentColor"
      />
      <path
        d="M316.017 3.4687H314.875V1.16344H316.017V0H320.628V1.16344H321.769V3.4687H320.628V1.16344H317.18V2.30532H318.322V3.4472H320.628V4.61057H322.933V6.91589H321.791V8.05777H316.038V6.91589H314.897V4.61057H316.038V3.4472L316.017 3.4687ZM316.017 6.93746H320.628V5.77402H318.322V4.63214H316.017V6.93746Z"
        fill="currentColor"
      />
      <path
        d="M325.56 3.4687H324.418V1.16344H325.56V0H330.17V1.16344H331.312V3.4687H330.17V1.16344H326.723V2.30532H327.865V3.4472H330.17V4.61057H332.476V6.91589H331.334V8.05777H325.581V6.91589H324.44V4.61057H325.581V3.4472L325.56 3.4687ZM325.56 6.93746H330.17V5.77402H327.865V4.63214H325.56V6.93746Z"
        fill="currentColor"
      />
      <path
        d="M341.997 4.61057H339.692V5.75252H338.55V8.05777H337.408V5.75252H336.266V4.61057H333.961V3.4472H336.266V2.30532H337.408V0H338.55V2.30532H339.692V3.4472H341.997V4.61057ZM336.245 2.30532H335.103V1.16344H336.245V2.30532ZM336.245 6.91589H335.103V5.75252H336.245V6.91589ZM340.855 2.30532H339.713V1.16344H340.855V2.30532ZM340.855 6.91589H339.713V5.75252H340.855V6.91589Z"
        fill="currentColor"
      />
    </svg>
  );
});

ClaimDividerIcon.displayName = 'ClaimDividerIcon';
