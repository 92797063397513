import { formatUnits } from 'viem';
import dayjs from 'dayjs';

import { BLAST_SEPOLIA_ID, CONTRACTS, DEGEN_WARS_ABI } from '@/core/lib';

import { publicClient } from './useWatchGameCreationEvents';

const GAME_CREATED_EVENT_ABI = DEGEN_WARS_ABI[1];
const GAME_JOINED_EVENT_ABI = DEGEN_WARS_ABI[4];
const GAME_STARTED_EVENT_ABI = DEGEN_WARS_ABI[6];
const BLOCKS_PER_SECONDS = 4;

const watchGameCreatedLogs = async ({
  address,
  blockNumber,
  setGameCreated,
}: {
  address: `0x${string}`;
  blockNumber: bigint;
  setGameCreated: (gameCreated: any) => void;
}) => {
  const logs = await publicClient.getLogs({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    event: GAME_CREATED_EVENT_ABI,
    args: {
      player: address,
    },
    fromBlock: blockNumber - BigInt(BLOCKS_PER_SECONDS * 10),
    toBlock: blockNumber,
  });

  const gameCreatedLog = logs[logs.length - 1];

  if (!gameCreatedLog) return;

  const timestamp = dayjs().format('HH:mm:ss');

  const { blind, gameId, player } = gameCreatedLog.args;

  const gameCreated = {
    timestamp,
    currentGameId: Number(gameId as bigint),
    currentAddress: player as `0x${string}`,
    currentBlind: Number(formatUnits(blind as bigint, 18)),
  };

  console.group('GameCreated');
  console.log(gameCreated);
  console.groupEnd();

  setGameCreated(gameCreated);
};

const watchGameJoinedLogs = async ({
  address,
  blockNumber,
  setGameJoined,
}: {
  address: `0x${string}`;
  blockNumber: bigint;
  setGameJoined: (gameJoined: any) => void;
}) => {
  const logs = await publicClient.getLogs({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    event: GAME_JOINED_EVENT_ABI,
    args: {
      player: address,
    },
    fromBlock: blockNumber - BigInt(BLOCKS_PER_SECONDS * 10),
    toBlock: blockNumber,
  });

  const gameJoinedLog = logs[logs.length - 1];

  if (!gameJoinedLog) return;

  const timestamp = dayjs().format('HH:mm:ss');

  const { blind, gameId, player } = gameJoinedLog.args;

  const gameJoined = {
    timestamp,
    currentGameId: Number(gameId as bigint),
    currentAddress: player as `0x${string}`,
    currentBlind: Number(formatUnits(blind as bigint, 18)),
  };

  console.group('GameJoined');
  console.log(gameJoined);
  console.groupEnd();

  setGameJoined(gameJoined);
};

const watchGameStartedLogs = async ({
  gameId,
  blockNumber,
  setGameStarted,
}: {
  gameId: number;
  blockNumber: bigint;
  setGameStarted: (gameStarted: any) => void;
}) => {
  const logs = await publicClient.getLogs({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    event: GAME_STARTED_EVENT_ABI,
    args: {
      gameId: BigInt(gameId),
    },
    fromBlock: blockNumber - BigInt(BLOCKS_PER_SECONDS * 10),
    toBlock: blockNumber,
  });

  const gameLog = logs[logs.length - 1];

  if (!gameLog) return;

  const timestamp = dayjs().format('HH:mm:ss');

  const { arenaId, attacker } = gameLog.args;

  const gameData = {
    timestamp,
    gameId: gameId,
    arenaId: Number(arenaId),
    attacker: Number(attacker),
  };

  console.group('GameStarted');
  console.log(gameData);
  console.groupEnd();

  setGameStarted(gameData);
};

export { watchGameCreatedLogs, watchGameJoinedLogs, watchGameStartedLogs };
