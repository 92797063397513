'use client';

import { useRef } from 'react';

export const uninitialized: unique symbol = Symbol('uninitialized');

export function useOnce<T>(fn: () => T) {
  const ref = useRef<T | typeof uninitialized>(uninitialized);

  if (ref.current === uninitialized) {
    const value = fn();

    ref.current = value;

    return value;
  }

  return ref.current;
}
