import * as React from 'react';

export const GameSearchFooterIcon = React.forwardRef<
  SVGSVGElement,
  React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => {
  return (
    <svg
      ref={ref}
      width="588"
      height="64"
      viewBox="0 0 588 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V29.8583L22.7311 52.5894H45.1687V49.4575H24.0035L3.10749 28.5614V0H0ZM18.4491 0V11.4336L70.836 63.796H94.0565V18.9699L75.0865 0H70.6771L90.9245 20.2667V60.6885H72.1083L21.5566 10.1368V0H18.4491ZM512.92 0L493.968 18.9699V63.796H517.188L569.551 11.4336V0H566.443V10.1368L515.892 60.6885H497.075V20.2667L517.323 0H512.92ZM584.891 0V28.5614L563.995 49.4575H542.83V52.5894H565.292L587.999 29.8583V0H584.891ZM85.884 25.3317V54.131H74.0657V11.1645L85.884 24.4019V25.3317ZM111.576 25.6987L268.786 25.5764L283.222 40.0128L273.997 49.2618H242.996L229.979 62.2545H111.576V25.6987ZM121.241 55.3055H196.017V51.4395H121.241V55.3055ZM120.629 45.0777H266.706V45.0533L273.019 38.7404L264.039 29.8094H120.629V45.0777ZM250.74 51.9324L241.536 61.137L243.733 63.3343L252.938 54.1297L250.74 51.9324ZM253.896 61.1592L263.1 51.9547L265.298 54.152L256.093 63.3566L253.896 61.1592ZM502.14 54.131V25.3317V24.4019L513.958 11.1645V54.131H502.14ZM304.802 39.9883L314.027 49.2374H345.028L358.045 62.2301H476.448V25.6743L319.239 25.5519L304.802 39.9883ZM392.008 51.4151H466.783V55.2811H392.008V51.4151ZM314.981 38.7404L323.961 29.8095H467.37V45.0777H321.318L315.006 38.7649L314.981 38.7404ZM335.086 54.1301L337.283 51.9328L346.488 61.1374L344.29 63.3347L335.086 54.1301ZM324.924 51.886L322.726 54.0833L331.931 63.2878L334.128 61.0905L324.924 51.886Z"
        fill="#CC2733"
      />
    </svg>
  );
});

GameSearchFooterIcon.displayName = 'GameSearchFooterIcon';
