import { blastSepolia } from '@/utils/chains';

export const ZERO_ADDRESS: `0x${string}` = '0x0000000000000000000000000000000000000000';

export const BLAST_SEPOLIA_ID = blastSepolia.id;
export const ARBITRUM_SEPOLIA_ID = 421_614;

export const CONTRACTS = {
  [BLAST_SEPOLIA_ID]: {
    DEGEN_CARD: '0x7aDEda3b37dE7EBE16cEf7DF92152cf7eF23e4F5' as `0x${string}`,
    DEGEN_WARS: '0x7B70f4C294A4909a9f862F3B2fC590d3102aa003' as `0x${string}`,
    DEGEN_WARS_RENTING_MARKET: '0x7250A1180EA0FBeEcd1479ec6bFE64474eEb7804' as `0x${string}`,
    WAR_TOKEN: '0xd7F6b10E6b4E8e6BE3908c42675d1Dcd16bc5ED8' as `0x${string}`,
    STAKING: '0x42f8e143908ed5A87B34b9f6EEC4b5c12651c778' as `0x${string}`,
    DEGEN_WARS_LENS: '0xE7659abacc6E40c50fEe104fB3396798C899D16A' as `0x${string}`,
    DEGEN_WARS_MARKET_LENS: '0x298f996a27D1F103EB7c2f91e64C736BB8d50CCB' as `0x${string}`,
  },

  [ARBITRUM_SEPOLIA_ID]: {
    DEGEN_CARD: '0x072A79141Cd822BbF8d7910c153d6e2de1BA6681' as `0x${string}`,
    DEGEN_WARS: '0xd43ba9a8c7EE099e3071382bED92212Fb2E613d8' as `0x${string}`,
    DEGEN_WARS_LENS: '0x93eC7E8954c2A88B139bcE872Aac69150ea0A6c6' as `0x${string}`,
    DEGEN_WARS_RENTING_MARKET: '0x3144864769E575F42268d87406bA6121476C7d44' as `0x${string}`,
    DEGEN_WARS_MARKET_LENS: '0x87CED3A6b375D533D27AAC841Acd1940979C9DF1' as `0x${string}`,
  },
};
