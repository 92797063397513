'use client';

import { motion } from 'framer-motion';

import { useAccount } from '@/core/hooks/useAccount';

import { useBalanceOf } from '../hooks';
import { useGameAnimationStore } from '../store';

type GameStatsProps = {
  isGameTitleVisible: boolean;
};

export function GameStats(props: GameStatsProps) {
  const { isGameTitleVisible } = props;
  const { address } = useAccount();
  const { data } = useBalanceOf({ address });
  const { hasSeenGameAnimation } = useGameAnimationStore();

  const cardCount = data ? Number(data) : 0;

  return (
    <motion.div
      initial={{ opacity: hasSeenGameAnimation ? 1 : 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.5 }}
      className="w-full flex-1 border border-red bg-white/5 backdrop-blur-xl"
    >
      <div className="flex h-12 w-full items-center space-x-4 border-b border-red">
        <div className="flex h-12 w-12 select-none items-center justify-center bg-red text-xl text-black">
          <p>2.</p>
        </div>

        <p className="uppercase text-lime">{isGameTitleVisible && '//:Stats'}</p>
      </div>

      <div className="space-y-6 p-8">
        <div className="space-y-3">
          <div className="border-b border-b-red pb-1 font-bold text-red text-glow-red">
            <h2 className="text-glow">Cards</h2>
          </div>

          <dl className="flex justify-between gap-4">
            <div>
              <dt className="font-display text-2xl text-white/75">{`${cardCount}/500`}</dt>
              <dd className="font-display text-cyan text-glow-cyan">Cards</dd>
            </div>
            <div>
              <dt className="font-display text-2xl text-white/75">26</dt>
              <dd className="font-display text-cyan text-glow-cyan">Tradable</dd>
            </div>
            <div>
              <dt className="font-display text-2xl text-white/75">$278,978</dt>
              <dd className="font-display text-cyan text-glow-cyan">Total Value Cards</dd>
            </div>
          </dl>
        </div>

        <div className="space-y-3">
          <div className="border-b border-b-red pb-1 font-bold text-red text-glow-red">
            <h2 className="text-glow">Ranks</h2>
          </div>

          <dl className="flex justify-between gap-4">
            <div>
              <dt className="font-display text-2xl text-white/75">2587</dt>
              <dd className="font-display text-cyan text-glow-cyan">Position</dd>
            </div>
            <div>
              <dt className="font-display text-2xl text-white/75">354</dt>
              <dd className="font-display text-cyan text-glow-cyan">XP Points</dd>
            </div>
            <div>
              <dt className="font-display text-2xl text-white/75">58%</dt>
              <dd className="font-display text-cyan text-glow-cyan">Win Rate</dd>
            </div>
          </dl>
        </div>
      </div>
    </motion.div>
  );
}
