'use client';

import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { formatTime } from '../utils';
import { useArenaStore } from '../store';

type AttackTimerScaleProps = {
  expiry: number | undefined;
  title?: string;
};

export function AttackTimerScale(props: AttackTimerScaleProps) {
  const { expiry, title = 'Attack time' } = props;
  const [timer, setTimer] = useState(300);

  const { setIsExpired } = useArenaStore();
  const motionTimer = useMotionValue(timer);
  const width = useTransform(motionTimer, [0, 300], ['0%', '100%'], {
    clamp: true,
  });

  let intervalId: string | number | NodeJS.Timeout | undefined;

  useEffect(() => {
    if (!expiry) {
      return setTimer(0);
    }

    if (!timer) {
      const timeLeft = dayjs(expiry * 1000).diff(dayjs(), 'second');

      if (timeLeft > 10) {
        setIsExpired(false);
        motionTimer.set(timeLeft);

        return setTimer(timeLeft);
      }

      if (timeLeft <= 0) {
        setTimeout(() => {
          setIsExpired(true);
        }, 2000);
      }

      return setTimer(0);
    }

    intervalId = setInterval(() => {
      const timeLeft = dayjs(expiry * 1000).diff(dayjs(), 'second');

      setIsExpired(false);
      motionTimer.set(timeLeft);
      setTimer(timeLeft);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer, expiry, status]);

  return (
    <div className={clsx('flex flex-col gap-2')}>
      <div
        className={clsx(
          'flex items-center justify-between text-xs font-bold leading-none',
          '2xl:text-sm'
        )}
      >
        <span>{title}</span>
        <span>{formatTime(timer)}</span>
      </div>
      <div className="bg-gray-200 relative h-3 w-full overflow-hidden timerShadow">
        <div className="w-ful h-3 bg-blue bg-opacity-40"></div>
        <motion.div className="absolute left-0 top-0 h-full gradient-progress" style={{ width }} />
      </div>
    </div>
  );
}
