import { cx } from '@/core/utils';

import { ActionCalloutDefendSvg } from './ActionCalloutDefendSvg';
import { ActionCalloutAttackSvg } from './ActionCalloutAttackSvg';

type DefendCalloutProps = {
  className?: string;
  type: 'DEFEND' | 'CHOOSE_ATTACK';
};

export function ActionCallout({ className, type }: DefendCalloutProps) {
  return (
    <div className={cx('absolute z-20', className)}>
      {type === 'DEFEND' ? (
        <ActionCalloutDefendSvg className="flex-1" />
      ) : (
        <ActionCalloutAttackSvg className="flex-1" />
      )}
    </div>
  );
}
