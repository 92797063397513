'use client';

import { useState } from 'react';
import { useContractEvent } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';
import { convertBNtoNumber } from '@/core/utils';

export function useVRFRequestSentEvent() {
  const [randId, setRandId] = useState<number>();
  const [currentGameId, setCurrentGameId] = useState<number>();
  const [numWords, setNumWords] = useState<number>();

  console.log('render useVRFRequestSentEvent');

  useContractEvent({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    eventName: 'VRFRequestSent',
    listener: (logs) => {
      const log = logs[0];

      if (!log) return;

      const {
        args: { gameId, numWords, randId },
      } = log;

      console.log('VRFRequestSent');
      console.log('randId', convertBNtoNumber(randId as bigint));
      console.log('gameId', Number(gameId as bigint));
      console.log('numWords', numWords as number);

      randId && setRandId(convertBNtoNumber(randId as bigint));
      gameId && setCurrentGameId(Number(gameId as bigint));
      numWords && setNumWords(numWords as number);
    },
  });

  return { randId, currentGameId, numWords };
}
