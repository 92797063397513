import { Alchemy, Network } from 'alchemy-sdk';

export const ALCHEMY_API_KEY = '7ceKpQto1Cq2D9KfykQS5iK4mhOMMXlf';
export const ALCHEMY_API_URL = `https://arb-sepolia.g.alchemy.com/v2/${ALCHEMY_API_KEY}`;

// Configures the Alchemy SDK
export const config = {
  apiKey: ALCHEMY_API_KEY,
  network: Network.ARB_SEPOLIA,
};

// Creates an Alchemy object instance with the config to use for making requests
export const alchemy = new Alchemy(config);
