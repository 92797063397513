'use client';

import React, { useState, useEffect, useCallback } from 'react';
import { useAccount } from 'wagmi';
import { useIsClient } from 'usehooks-ts';

import cyberspace from '@/assets/images/backgrounds/cyberspace.png';
import ethBg from '@/assets/images/arenas/backgrounds/eth-bg.png';
import gameBackground from '@/assets/images/arenas/backgrounds/game-bg.png';
import { Background, Main } from '@/ui';
import {
  ArenaEffect,
  ArenaFrame,
  ArenaShuffle,
  AttackTimer,
  BattleResults,
  Boosters,
  PreloadedGameCards,
  UserAvatar,
} from '@/play';
import { useAttackValueStore, usePlayEventsStore, useSettingsStore } from '@/play/store';
import { cx } from '@/core/utils';
import {
  useAttack,
  useGame,
  useDefend,
  useUnusedBoosters,
  useCancelGame,
  useIdentifyPlayer,
  GameStatuses,
  useAudio,
  useClickButtonAudio,
  useExpireGame,
  usePlayer,
  useAnimation,
  GameMode,
} from '@/core/hooks';
import { AttackValueCallout } from '@/play/components/CalloutActionHelper/AttackValueCallout';
import { CardsAmount } from '@/play/components/ArenaFrame/CardsAmount';

import { CARD_PROPERTIES, ChooseAttack } from './ChooseAttack';
import { Battle } from './Battle';
import { PlaySettings } from './PlaySettings';
import { ActiveCallout } from './ActiveCallout';
import { ActiveCards } from './ActiveCards';
import { ARENAS } from './Search';
// import { ShuffleDeck } from './ShuffleDeck';
import { PlaySubmitButton } from './PlaySubmitButton';
import { PlayDisplayNote } from './PlayDisplayNote';

import { CardProperty, useArenaStore, useGameEventsStore, useGameSecretsStore } from '../store';

export type CARD_TYPES = 'bronze' | 'silver' | 'diamond' | 'platinum' | 'red' | 'gold';

export type GameSteps =
  | 'attack'
  | 'attacking'
  | 'enemy turn'
  | 'your turn'
  | 'complete turn'
  | 'defence'
  | 'choose attack'
  | 'shuffle'
  | 'you win'
  | 'you lose'
  | 'draw';

export default function Play() {
  const isClient = useIsClient();
  // const { activeGameId } = props;
  const {
    activeGameId,
    activeArenaId,
    activeBlind,
    activeBooster,
    playerId,
    playerTopCard,
    setPlayerTopCard,
    isExpired,
    playerOneHand: playerOneHandStore,
    playerTwoHand: playerTwoHandStore,
    setIsSearching,
    setPlayerOneHand,
    setPlayerTwoHand,
    setActiveBooster,
    shouldHideProperties,
  } = useArenaStore();
  const { gameEndPending, gameReanimated, setGameReanimated, gameStarted } = useGameEventsStore();
  const gameSecrets = useGameSecretsStore();
  const { address } = useAccount();
  const [activeParameter, setActiveParameter] = useState<CardProperty | null>(null);
  /** Booster (shield) animation. */
  const [isShieldAnimated, setIsShieldAnimated] = useState(false);

  const [isDeckShuffleAnimation] = useState(false);
  const { loadAnimations } = useAnimation();

  const [isCurrentAttacker, setIsCurrentAttacker] = useState(true);
  const [activeCardType, setActiveCardType] = useState<CARD_TYPES>('bronze');
  const [currentGameStep, setCurrentGameStep] = useState<GameSteps>('enemy turn');
  const [opponentCardId, setOpponentCardId] = useState<number>(0);
  //TODO: Will be added later
  // const [isLetterShuffleAnimation, setIsLetterShuffleAnimation] = useState(false);
  // const [isWinGameAnimated, setIsWinGameAnimated] = useState(false);
  const { handPlayed, roundComplete } = usePlayEventsStore();

  const choosenArena = ARENAS.find((arena) => arena.id === activeArenaId);
  const arenaAudio = useAudio({
    src: choosenArena?.audio,
    volume: 0.1,
    loop: true,
    preload: 'none',
  });
  const clickButtonAudio = useClickButtonAudio();
  const cardFlipAudio = useAudio({ src: '/audio/card-flip.mp3', volume: 0.5 });

  const {
    startTime,
    gameMode,
    expiry,
    status,
    winner,
    attacker,
    round,
    refetch: refetchGame,
  } = useGame({
    gameId: activeGameId || 0,
  });
  const [isArenaSpinOpen, setIsArenaSpinOpen] = useState(!roundComplete);

  const {
    setOpponentValues,
    setActiveParameterAttackValues,
    deleteAttackValues,
    setPlayerCardValue,
    setPlayerValues,
  } = useAttackValueStore();

  const {
    playerOne,
    playerOneHand,
    refetchPlayer,
    playerTwoHand,
    playerTwo,
    refetchPlayerOneTopCard,
    playerOneTopCard,
    refetchPlayerTwoTopCard,
    playerTwoTopCard,
  } = usePlayer({
    gameId: activeGameId || 0,
  });

  const { identifyPlayer, refetch: refetchIdentifyPlayer } = useIdentifyPlayer();

  const { unusedBoosters, refetch: refetchUnusedBoosters } = useUnusedBoosters({
    gameId: activeGameId || 0,
    playerId: playerId || 0,
    isEnabled:
      currentGameStep === 'complete turn' ||
      currentGameStep === 'choose attack' ||
      currentGameStep === 'attack',
  });
  const { writeAsync: cancelGame, isLoading: isCancelGameLoading } = useCancelGame({
    gameId: activeGameId || 0,
    status,
  });
  const { writeAsync: expireGame } = useExpireGame({
    gameId: activeGameId || 0,
    status,
  });

  /** The price of each booster becomes 1/10 of the current blind (The maximum number of boosters is 4 per game). */
  const boostersPrice = activeBooster && activeBlind ? (Number(activeBlind.prize) / 10) * 1e18 : 0;

  const {
    writeAsync: attack,
    isLoading: isLoadingAttack,
    isSuccess: isAttackSuccess,
    refetch: refetchAttack,
  } = useAttack({
    gameId: activeGameId || 0,
    boosterId: activeBooster,
    propId: activeParameter?.id || 1,
    value: boostersPrice,
    currentGameStep,
  });
  const {
    writeAsync: defend,
    isLoading: isLoadingDefend,
    refetch: refetchDefend,
  } = useDefend({
    gameId: activeGameId || 0,
    boosterId: activeBooster,
    value: boostersPrice,
    currentGameStep,
  });

  const { isArenaBg, isSfx, isMusic } = useSettingsStore();

  const isAttacker =
    (roundComplete?.resultId === playerId &&
      gameReanimated?.attacker === playerId &&
      gameReanimated?.round === round) ||
    (gameStarted?.attacker === playerId && (!round || round === 1)) ||
    (gameReanimated?.attacker === playerId && gameReanimated?.round === round) ||
    attacker === playerId;

  const isTopCards = !!playerOneTopCard && !!playerTwoTopCard;
  let identifyPlayerInterval: string | number | NodeJS.Timer | undefined = undefined;

  useEffect(() => {
    if (!isArenaSpinOpen) {
      if (isMusic) {
        arenaAudio?.play();

        return;
      }

      arenaAudio?.pause();
    }

    loadAnimations();
  }, [isArenaSpinOpen, isMusic]);

  //setPlayerCardValue sets the value of the card to the AttackValueStore before the first round, after that it is done in the shuffle onComplete
  React.useEffect(() => {
    if (!isArenaSpinOpen) {
      if (playerId && playerOneTopCard && playerTwoTopCard) {
        setPlayerCardValue(playerId === 1 ? playerOneTopCard : playerTwoTopCard);
      }
    }
  }, [isArenaSpinOpen]);

  useEffect(() => {
    if (
      !playerOneHandStore.length &&
      !playerTwoHandStore.length &&
      playerOneHand &&
      playerTwoHand
    ) {
      setPlayerOneHand(playerOneHand);
      setPlayerTwoHand(playerTwoHand);
    }
  }, [playerOneHandStore, playerTwoHandStore, playerOneHand, playerTwoHand]);

  useEffect(() => {
    setIsSearching(false);

    return () => {
      if (arenaAudio) {
        arenaAudio.pause();
        arenaAudio.src = '';
      }

      if (cardFlipAudio) {
        cardFlipAudio.pause();
        cardFlipAudio.src = '';
      }

      if (clickButtonAudio) {
        clickButtonAudio.pause();
        clickButtonAudio.src = '';
      }
    };
  }, []);

  React.useEffect(() => {
    const playerTopCardId = playerId === 1 ? playerOneTopCard : playerTwoTopCard;

    console.log(currentGameStep);
    if (
      playerTopCardId !== null &&
      currentGameStep !== 'choose attack' &&
      currentGameStep !== 'shuffle' &&
      currentGameStep !== 'defence' &&
      currentGameStep !== 'you win' &&
      currentGameStep !== 'you lose' &&
      currentGameStep !== 'draw' &&
      (currentGameStep === 'attack' || currentGameStep === 'complete turn')
    ) {
      setPlayerValues(playerTopCardId, activeBooster);
    }
  }, [activeBooster, currentGameStep]);

  useEffect(() => {
    // first round
    if (!roundComplete) {
      if (gameStarted?.attacker === playerId || attacker === playerId) {
        setCurrentGameStep('choose attack');
      } else {
        setCurrentGameStep('enemy turn');
      }

      return;
    }

    // all following rounds
    const isWinner = roundComplete.resultId === playerId;

    if (roundComplete.resultId === 0 || roundComplete.resultId === 3) {
      setCurrentGameStep('draw');
    } else if (isWinner) {
      setCurrentGameStep('you win');
    } else {
      setCurrentGameStep('you lose');
    }

    refetchPlayer();
  }, [roundComplete, gameStarted, status]);

  useEffect(() => {
    if (!isTopCards) {
      refetchPlayerOneTopCard();

      refetchPlayerTwoTopCard();

      return;
    }

    setPlayerTopCard(playerId === 1 ? playerOneTopCard : playerTwoTopCard);
  }, [playerId, playerOneTopCard, playerTwoTopCard, isTopCards]);

  /**
   * reset to new round after win/loss of previous round
   */
  useEffect(() => {
    refetchGame();

    const reset = () => {
      setActiveParameter(null);
      setActiveBooster(0);
      setOpponentCardId(0);
      deleteAttackValues();
    };

    if (currentGameStep === 'choose attack') {
      refetchAttack();
      setIsCurrentAttacker(true);
      if (identifyPlayer?.isAttacker) {
        if (playerId === 1) {
          refetchPlayerOneTopCard();
        }

        if (playerId === 2) {
          refetchPlayerTwoTopCard();
        }
      }
    }

    if (currentGameStep === 'complete turn') {
      refetchDefend();
      refetchUnusedBoosters();
      setIsCurrentAttacker(false);
    }

    if (currentGameStep === 'you win') {
      setTimeout(() => {
        setCurrentGameStep('choose attack');
        reset();
      }, 7000);
    }

    if (currentGameStep === 'draw') {
      setTimeout(() => {
        if (isCurrentAttacker) {
          setCurrentGameStep('enemy turn');
        } else {
          setCurrentGameStep('choose attack');
        }

        reset();
      }, 2000);
    }

    if (currentGameStep === 'you lose') {
      setTimeout(() => {
        setCurrentGameStep('enemy turn');
        reset();
      }, 7000);
    }

    if (currentGameStep === 'your turn') {
      setTimeout(() => {
        setCurrentGameStep('complete turn');
      }, 2000);
    }

    if (currentGameStep === 'enemy turn') {
      if (!identifyPlayer?.isAttacker) {
        if (playerId === 1) {
          refetchPlayerOneTopCard();
        }

        if (playerId === 2) {
          refetchPlayerTwoTopCard();
        }
      }

      refetchGame();
      // foo
    }
  }, [currentGameStep, identifyPlayer, playerId]);

  const identifyPlayerAsync = async () => {
    if (!identifyPlayer) return;

    if (identifyPlayer.isAttacker && !identifyPlayer.needsAttack) {
      const activeCardProp = CARD_PROPERTIES.find(
        (prop) => prop.id === identifyPlayer.attackerPropId
      );

      setActiveParameter(
        activeCardProp?.prop
          ? {
              id: activeCardProp.id,
              prop: activeCardProp.prop,
            }
          : null
      );
    }

    if (!identifyPlayer.isAttacker && !identifyPlayer.needsAttack) {
      if (currentGameStep === 'enemy turn') {
        const activeCardProp = CARD_PROPERTIES.find(
          (prop) => prop.id === identifyPlayer.attackerPropId
        );

        setCurrentGameStep('complete turn');
        setActiveParameter(
          activeCardProp?.prop
            ? {
                id: activeCardProp.id,
                prop: activeCardProp.prop,
              }
            : null
        );
      }
    }

    if (identifyPlayer.isAttacker && identifyPlayer.needsAttack) {
      if (currentGameStep === 'enemy turn') {
        setCurrentGameStep('choose attack');
      }
    }
  };

  useEffect(() => {
    if (gameEndPending) {
      identifyPlayerInterval = setInterval(async () => {
        await refetchGame();
      }, 5000);

      return;
    }

    identifyPlayerInterval = setInterval(async () => {
      await refetchIdentifyPlayer();
      await refetchGame();
    }, 5000);

    const identify = setTimeout(() => {
      identifyPlayerAsync();
    }, 10_000);

    return () => {
      clearInterval(identifyPlayerInterval as number | undefined);
      clearTimeout(identify);
    };
  }, [identifyPlayer, playerId, currentGameStep, gameEndPending]);

  useEffect(() => {
    if (!handPlayed) {
      return;
    }

    if (handPlayed.isAttacker) {
      const activeCardProp = CARD_PROPERTIES.find((prop) => prop.id === handPlayed.attackerProp);

      setActiveParameter(
        activeCardProp?.prop ? { id: handPlayed.attackerProp, prop: activeCardProp.prop } : null
      );

      if (handPlayed.player !== address) {
        setActiveCardType('gold');
        setCurrentGameStep('your turn');

        if (isSfx) {
          cardFlipAudio?.play();
        }
      } else {
        setCurrentGameStep('enemy turn');
      }
    }
  }, [handPlayed]);

  useEffect(() => {
    if (handPlayed && currentGameStep === 'complete turn') {
      setOpponentCardId(handPlayed.playerCard);
      setOpponentValues(handPlayed.playerCard, handPlayed.boosterId);
      setActiveParameterAttackValues(activeParameter);
    }

    const hasRoundEnded =
      currentGameStep === 'you win' || currentGameStep === 'you lose' || currentGameStep === 'draw';

    if (
      handPlayed?.playerCard &&
      !handPlayed.isAttacker &&
      handPlayed.player !== address &&
      hasRoundEnded
    ) {
      setOpponentCardId(handPlayed.playerCard);
      setOpponentValues(handPlayed.playerCard, handPlayed.boosterId);
      setActiveParameterAttackValues(activeParameter);
    }
  }, [handPlayed, currentGameStep, address]);

  useEffect(() => {
    /** After a successful attack, the card is turned over and the turn passes to another player. */
    if (currentGameStep === 'attack' && isAttackSuccess && !isAttacker) {
      setActiveCardType('gold');
      setCurrentGameStep('enemy turn');

      if (isSfx) {
        cardFlipAudio?.play();
      }
    } else if (currentGameStep === 'choose attack' && activeParameter) {
      setCurrentGameStep('attack');
    }
  }, [activeParameter, currentGameStep, isAttackSuccess, isAttacker]);

  // after using reanimate booster, set booster user to attacker, and opponent to defender
  useEffect(() => {
    if (!gameReanimated || status !== GameStatuses.Started) {
      return;
    }

    if (gameReanimated?.attacker === playerId) {
      setCurrentGameStep('choose attack');

      return;
    }

    setCurrentGameStep('enemy turn');
  }, [gameReanimated, status]);

  const openOpponentCard = useCallback(async () => {
    if (isSfx) {
      clickButtonAudio?.play();
    }

    if (currentGameStep === 'attack') {
      try {
        await attack?.();
        setCurrentGameStep('attacking');
        await refetchIdentifyPlayer();
        gameSecrets.next();
        setCurrentGameStep('enemy turn');

        if (gameReanimated) {
          setGameReanimated(undefined);
        }
      } catch (error) {
        console.error(error);
      }

      return;
    }

    try {
      await defend?.();
      setCurrentGameStep('defence');
      await refetchIdentifyPlayer();
      gameSecrets.next();

      if (gameReanimated) {
        setGameReanimated(undefined);
      }
    } catch (error) {
      console.error(error);
    }
  }, [attack, defend, isAttacker, gameSecrets, gameReanimated]);

  const handleCancelGame = async () => {
    if (isExpired) {
      try {
        await expireGame?.();
      } catch (error) {
        console.error(error);
      }

      return;
    }

    try {
      await cancelGame?.();
    } catch (error) {
      console.error(error);
    }
  };

  if (!isClient) {
    return null;
  }

  if ((gameEndPending && status === GameStatuses.EndPending) || status === GameStatuses.Ended) {
    return (
      <Main className={cx('flex grow flex-col items-center justify-center')}>
        <BattleResults
          isWinner={gameEndPending?.potentialWinner === playerId || winner === playerId}
          playerAddress={address === playerOne ? playerOne : playerTwo}
          opponentAddress={address === playerOne ? playerTwo : playerOne}
          hasLoserAccepted={status === GameStatuses.Ended}
          handleCancelGame={handleCancelGame}
          isCancelGameLoading={isCancelGameLoading}
          expiry={expiry}
          status={status}
          isHardcoreMode={gameMode === 2}
        />
        {isArenaBg && <Background hasGrid={false} image={choosenArena?.background || ethBg} />}
      </Main>
    );
  }

  return (
    <>
      {/* preload cards */}
      <PreloadedGameCards />

      {/* arena spin */}
      {isArenaSpinOpen && gameMode === GameMode.Default ? (
        <ArenaShuffle setIsArenaSpinOpen={setIsArenaSpinOpen} />
      ) : (
        <>
          {/* note for small screens */}
          <PlayDisplayNote />

          {/* callouts */}
          <ActiveCallout currentGameStep={currentGameStep} />

          <Main className="play-display flex-col">
            {/* bg */}
            {isArenaBg ? (
              <Background
                className="bg-[#0E0E0E] !opacity-80"
                hasGrid={false}
                image={gameBackground}
              />
            ) : (
              <Background image={cyberspace} />
            )}

            {/* game ui */}
            <div className={cx('relative flex grow flex-col px-8')}>
              <PlaySettings />

              <div
                className={cx(
                  // "relative z-10 flex grow",
                  'relative z-10 grid grow grid-cols-12 gap-8',
                  '2xl:flex'
                )}
              >
                <div
                  className={cx(
                    'col-span-2 flex w-auto min-w-[12rem] shrink-0 flex-col justify-between',
                    '2xl:py-10'
                  )}
                >
                  {address && playerOne && playerTwo && (
                    <div className="flex flex-row gap-6">
                      <UserAvatar address={address !== playerOne ? playerOne : playerTwo} />
                      <CardsAmount
                        cardsAmount={
                          address !== playerOne
                            ? playerOneHand?.length ?? 0
                            : playerTwoHand?.length ?? 0
                        }
                      />
                    </div>
                  )}

                  {currentGameStep === 'complete turn' && !shouldHideProperties && (
                    <AttackValueCallout
                      className={cx('absolute left-4 top-40 z-10', '2xl:top-56')}
                    />
                  )}

                  <Battle startTime={startTime} currentGameStep={currentGameStep} />

                  {address && playerOne && playerTwo && (
                    <div className="flex flex-row items-end gap-6">
                      <UserAvatar address={address === playerOne ? playerOne : playerTwo} />
                      <CardsAmount
                        cardsAmount={
                          address === playerOne
                            ? playerOneHand?.length ?? 0
                            : playerTwoHand?.length ?? 0
                        }
                      />
                    </div>
                  )}
                </div>

                <div className={cx('col-span-1', '2xl:hidden')}>{/* spacer */}</div>

                <div className="relative z-0 col-span-6 flex flex-1 items-center justify-center">
                  <ActiveCards
                    activeParameter={activeParameter}
                    currentGameStep={currentGameStep}
                    isAttacker={isAttacker}
                    activeCardType={activeCardType}
                    playerTopCardId={playerTopCard || playerOneTopCard}
                    opponentTopCardId={opponentCardId}
                    // isWinGameAnimated={isWinGameAnimated}
                    setActiveCardType={setActiveCardType}
                  />

                  <div className={cx('absolute inset-0')}>
                    {!isDeckShuffleAnimation && gameMode !== 2 && (
                      <ArenaFrame
                        gameMode={gameMode}
                        playerOneAddress={playerOne}
                        isAttacker={isAttacker}
                        playerOneHand={playerOneHand}
                        playerTwoHand={playerTwoHand}
                        roundWinner={roundComplete?.resultId}
                        currentGameStep={currentGameStep}
                        playerOneTopCard={playerOneTopCard}
                        playerTwoTopCard={playerTwoTopCard}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={cx(
                    'relative z-10 col-span-3 flex w-full origin-right scale-90 items-center justify-center',
                    'tall:scale-95',
                    '2xl:w-auto 2xl:taller:scale-100'
                  )}
                >
                  <div
                    className={cx(
                      'relative flex flex-col',
                      '2xl:tall:translate-y-10',
                      '2xl:taller:mt-20'
                    )}
                  >
                    <Boosters
                      currentGameStep={currentGameStep}
                      gameMode={gameMode}
                      isAnimated={isShieldAnimated}
                      isAttacker={isAttacker}
                      setIsAnimated={setIsShieldAnimated}
                      unusedBoosters={unusedBoosters}
                    />

                    <ArenaEffect
                      gameMode={gameMode}
                      arenaId={activeArenaId}
                      cardId={playerTopCard}
                      currentGameStep={currentGameStep}
                    />

                    <div className="relative">
                      <ChooseAttack
                        cardId={playerTopCard}
                        activeParameter={activeParameter}
                        setActiveParameter={setActiveParameter}
                        currentGameStep={currentGameStep}
                        isAttacker={isAttacker}
                      />

                      <div className="mt-1.5 h-full space-y-1">
                        <AttackTimer
                          expiry={expiry}
                          title={isAttacker ? 'Attack Time' : 'Defence Time'}
                        />

                        <div className={cx('flex flex-col')}>
                          <PlaySubmitButton
                            isLoading={
                              (currentGameStep === 'attack' && !attack) ||
                              isLoadingAttack ||
                              isLoadingDefend ||
                              (currentGameStep === 'complete turn' && !defend)
                            }
                            currentGameStep={currentGameStep}
                            handleClick={openOpponentCard}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Main>
        </>
      )}
    </>
  );
}
