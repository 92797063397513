'use client';

import { ConnectButton as ConnectButtonPrimitive } from '@rainbow-me/rainbowkit';
import clsx from 'clsx';
// import Image from 'next/image';
import * as React from 'react';

import { WalletIcon } from '@/ui';
import { truncAddress } from '@/core/utils';

type ConnectButtonProps = Omit<React.ComponentPropsWithRef<'button'>, 'children' | 'onClick'> & {
  size?: 'default' | 'game';
};

const t = {
  connectWallet: 'Connect Wallet',
  wrongNetwork: 'Wrong network',
};

const rootProps: ConnectButtonProps = {
  type: 'button',
  className: clsx(
    'block border-2 border-red px-3 py-2.5 text-sm font-bold text-cyan text-glow-cyan',
    'sm:px-4',
    'hover:opacity-80'
  ),
  size: 'default',
};

export function ConnectButton(props: ConnectButtonProps) {
  const { size = 'default' } = props;

  return (
    <ConnectButtonPrimitive.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
        const connected = account && chain;

        return (
          <div
            aria-hidden={!mounted}
            className={clsx(!mounted && 'pointer-events-none touch-none select-none opacity-0')}
          >
            {(() => {
              if (!connected) {
                return (
                  <button onClick={openConnectModal} {...rootProps} {...props}>
                    {t.connectWallet}
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} {...rootProps} {...props}>
                    {t.wrongNetwork}
                  </button>
                );
              }

              return (
                <div className="flex flex-col gap-2 md:flex-row md:gap-4">
                  {/* wallet balance */}
                  {account.displayBalance && (
                    <div
                      className={clsx(
                        'hidden items-stretch gap-2.5 border-[1.5px] border-red px-4 text-red',
                        'sm:flex',
                        size === 'game' && '!h-9 !items-center'
                      )}
                    >
                      <div className="flex items-center pl-2">
                        <WalletIcon />
                      </div>
                      <span className="py-2.5 text-sm font-bold text-glow-red">
                        {account.displayBalance}
                      </span>
                    </div>
                  )}

                  {/* <button
                    onClick={openChainModal}
                    className="flex items-center gap-1"
                    {...rootProps} {...props}

                  >
                    {chain.hasIcon && (
                      <div
                        className="h-3 w-3 overflow-hidden rounded-full"
                        style={{
                          background: chain.iconBackground,
                        }}
                      >
                        {chain.iconUrl && (
                          <Image
                            alt={chain.name ?? 'Chain icon'}
                            src={chain.iconUrl}
                            width={16}
                            height={16}
                          />
                        )}
                      </div>
                    )}
                    {chain.name}
                  </button> */}

                  {size === 'default' && (
                    <button onClick={openAccountModal} {...rootProps} {...props}>
                      {truncAddress({ address: account.address, length: 3 })}
                    </button>
                  )}
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButtonPrimitive.Custom>
  );
}
