import * as React from 'react';
import { useContractEvent } from 'wagmi';
import dayjs from 'dayjs';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';
import { PlayEventsState } from '@/play/store';

export type UseRoundCompleteEventProps = {
  currentGameId: number;
};

export function useRoundCompleteEvent(props: UseRoundCompleteEventProps) {
  const { currentGameId } = props;
  const [roundComplete, setRoundComplete] = React.useState<PlayEventsState['roundComplete']>();

  useContractEvent({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    eventName: 'RoundCompleted',
    listener: (logs) => {
      const log = logs[0];

      if (!log) return;

      const {
        args: {
          gameId,
          attacker,
          attackerProp,
          playerOne,
          playerOneCard,
          playerTwo,
          playerTwoCard,
          resultId,
          roundNumber,
        },
      } = log;

      const id = Number(gameId as bigint);
      const timestamp = dayjs().format('HH:mm:ss');

      console.group('RoundComplete');
      console.log('timestamp', timestamp);
      console.log('playerOne', playerOne as `0x${string}`);
      console.log('playerTwo', playerTwo as `0x${string}`);
      console.log('id', id);
      console.log('currentGameId', currentGameId);
      console.log('roundNumber', Number(roundNumber as bigint));
      console.log('playerOneCard', Number(playerOneCard as bigint));
      console.log('playerTwoCard', Number(playerTwoCard as bigint));
      console.log('attacker', Number(attacker as bigint));
      console.log('attackerProp', Number(attackerProp as bigint));
      console.log('resultId', Number(resultId as bigint));
      console.groupEnd();

      if (id !== currentGameId) {
        return;
      }

      console.log('setRoundComplete');

      setRoundComplete({
        timestamp,
        playerOne: playerOne as `0x${string}`,
        playerTwo: playerTwo as `0x${string}`,
        roundNumber: Number(roundNumber as bigint),
        playerOneCard: Number(playerOneCard as bigint),
        playerTwoCard: Number(playerTwoCard as bigint),
        attacker: Number(attacker as bigint),
        attackerProp: Number(attackerProp as bigint),
        resultId: Number(resultId as bigint),
      });
    },
  });

  return roundComplete;
}
