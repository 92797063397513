import { useAccount as useAccountWagmi } from 'wagmi';

export const useAccount = () => {
  const { address, ...rest } = useAccountWagmi();

  // Edward
  // const manualAddress = '0xdb13075381b21F82e9EF02C745dfa4b8e74dB307';
  // Tyler = ""
  // const manualAddress = '0x71A3EbF40CFa29A4C3d85bd03F7613F01519F332';

  const manualAddress = null;

  return {
    address: (manualAddress ?? address) as `0x${string}`,
    ...rest,
  };
};
