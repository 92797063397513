import * as React from 'react';

export const GameSearchHeaderIcon = React.forwardRef<
  SVGSVGElement,
  React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => {
  return (
    <svg
      ref={ref}
      width="588"
      height="62"
      viewBox="0 0 588 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.8907 62L94.0565 44.8506V0H70.836L18.4491 52.3868V62H21.5566V53.6592L72.1083 3.10749H90.9245V43.5537L72.4782 62H76.8907ZM515.522 62H511.117L493.968 44.8506V0H517.188L569.551 52.3868V62H566.443V53.6592L515.892 3.10749H497.075V43.5537L515.522 62ZM587.999 62H584.891V35.2343L563.995 14.3383H542.83V11.2308H565.292L587.999 33.9375V62ZM3.10749 62H0V33.9375L22.7311 11.2308H45.1687V14.3383H24.0035L3.10749 35.2343V62ZM85.884 38.4887V9.66489H74.0657V52.6314L85.884 39.4185V38.4887ZM283.185 23.7954L283.197 23.8077V23.7832L283.185 23.7954ZM273.972 14.5586L283.185 23.7954L268.76 38.2196L111.551 38.0972V1.56592H229.953L242.971 14.5586H273.972ZM121.216 12.3809H195.991V8.51494H121.216V12.3809ZM264.038 33.9865L273.018 25.0556L272.993 25.0311L266.681 18.7183H120.629V33.9865H264.038ZM243.711 0.463677L241.514 2.661L250.719 11.8655L252.916 9.66822L243.711 0.463677ZM253.89 2.66584L256.088 0.468507L265.292 9.67305L263.095 11.8704L253.89 2.66584ZM502.14 9.66489V38.4887V39.4185L513.958 52.6314V9.66489H502.14ZM319.239 38.244L476.448 38.1217V1.56592H358.045L345.028 14.5586H314.027L304.802 23.8077L319.239 38.244ZM392.008 8.51494H466.783V12.3809H392.008V8.51494ZM321.318 18.7427H467.37V34.011H323.961L314.981 25.08L321.294 18.7672L321.318 18.7427ZM335.063 9.66876L344.267 0.464237L346.465 2.66156L337.26 11.8661L335.063 9.66876ZM331.909 0.466391L322.705 9.67094L324.902 11.8683L334.106 2.66372L331.909 0.466391Z"
        fill="currentColor"
      />
    </svg>
  );
});

GameSearchHeaderIcon.displayName = 'GameSearchHeaderIcon';
