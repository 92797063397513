import { useContractWrite, usePrepareContractWrite } from 'wagmi';

import { useArenaStore } from '@/game';
import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

export function useGameReanimate() {
  const { activeGameId, activeBlind } = useArenaStore();

  const { config } = usePrepareContractWrite({
    chainId: BLAST_SEPOLIA_ID,
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'reanimate',
    args: [BigInt(activeGameId ?? 0)],
    enabled: !!activeGameId,
    value: BigInt((Number(activeBlind.prize) / 10) * 1e18),
  });

  return {
    ...useContractWrite(config),
  };
}
