'use client';

import { Menu } from '@headlessui/react';
import clsx from 'clsx';
import Link from 'next/link';
import { HiOutlineEllipsisHorizontal } from 'react-icons/hi2';
import { usePathname } from 'next/navigation';

import type { HeaderProps } from '../Header';

export function DropDownMenu(props: HeaderProps) {
  const { navigation } = props;

  const pathname = usePathname();

  return (
    <Menu as="div" className={clsx('relative block text-left', 'xl:hidden')}>
      <div>
        <Menu.Button className={clsx('-mr-2 px-1 py-1.5', 'sm:-mr-3')}>
          <HiOutlineEllipsisHorizontal className="h-8 w-8" />
        </Menu.Button>
      </div>

      <Menu.Items
        className={clsx(
          'absolute -right-2 top-16 flex flex-col gap-1 rounded border border-gray-dark bg-black px-1 py-1 shadow-md'
        )}
      >
        {navigation?.map(({ href, label }) => (
          <Menu.Item key={href} as="div">
            {({ active }) => (
              <Link
                href={href}
                className={clsx(
                  active || pathname === href ? 'text-red' : 'text-white',
                  'block px-4 py-2 text-sm font-bold leading-snug -tracking-[0.04em]'
                )}
              >
                {label}
              </Link>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}
