import * as React from 'react';

export const PlusIcon = React.forwardRef<SVGSVGElement, React.ComponentPropsWithoutRef<'svg'>>(
  (props, ref) => {
    return (
      <svg
        ref={ref}
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.03587 0.63501C8.41908 0.63501 7.91908 1.13501 7.91908 1.7518V8.45253H1.21835C0.60156 8.45253 0.101562 8.95254 0.101562 9.56931C0.101562 10.1861 0.60156 10.6861 1.21835 10.6861H7.91908V17.3868C7.91908 18.0036 8.41908 18.5036 9.03587 18.5036C9.65267 18.5036 10.1527 18.0036 10.1527 17.3868V10.6861H16.8534C17.4702 10.6861 17.9702 10.1861 17.9702 9.56931C17.9702 8.95254 17.4702 8.45253 16.8534 8.45253H10.1527V1.7518C10.1527 1.13501 9.65267 0.63501 9.03587 0.63501Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);

PlusIcon.displayName = 'PlusIcon';
