import { useContractWrite, usePrepareContractWrite } from 'wagmi';
import { parseEther } from 'viem';

import { useArenaStore } from '@/game';
import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';
import { useReferralStore } from '@/core/store/useReferralStore';

import { useIsApproved } from '../../useIsApproved';

export type UseGameCreateProps = {
  hash2_: string | null;
  hash3_: string | null;
  activeDeck: number[];
  blind: string;
  opponentAddress: `0x${string}` | null;
  isEnabled?: boolean;
  isHardcoreMode?: boolean;
};

export function useGameCreate(props: UseGameCreateProps) {
  const {
    hash2_,
    hash3_,
    activeDeck,
    blind,
    isEnabled = false,
    isHardcoreMode = false,
    opponentAddress,
  } = props;

  const { referralCode } = useReferralStore();
  const { isSearching } = useArenaStore();
  const { isApproved } = useIsApproved();

  const { config, error } = usePrepareContractWrite({
    chainId: BLAST_SEPOLIA_ID,
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'createGame',
    enabled: !isSearching && isEnabled && !!opponentAddress && isApproved && !!hash2_ && !!hash3_,
    args: [
      activeDeck?.map(BigInt) ?? [],
      BigInt(hash2_ as string),
      BigInt(hash3_ as string),
      opponentAddress as `0x${string}`,
      isHardcoreMode ? 2 : 1,
      referralCode ? referralCode : '0x000000000000000',
    ],

    value: parseEther(blind),
  });

  return {
    ...useContractWrite(config),
    prepareError: error,
  };
}
