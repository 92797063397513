import * as React from 'react';

export const BackIcon = React.forwardRef<SVGSVGElement, React.ComponentPropsWithoutRef<'svg'>>(
  (props, ref) => {
    return (
      <svg
        ref={ref}
        width="53"
        height="54"
        viewBox="0 0 53 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1276_16806)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.1892 36.171C30.0156 36.9974 31.3555 36.9974 32.1819 36.171C33.0084 35.3445 33.0084 34.0046 32.1819 33.1782L25.2135 26.2097L32.1819 19.2413C33.0084 18.4148 33.0084 17.0749 32.1819 16.2485C31.3555 15.4221 30.0156 15.4221 29.1892 16.2485L20.7243 24.7133C19.8979 25.5398 19.8979 26.8797 20.7243 27.7061L29.1892 36.171Z"
            fill="currentColor"
          />
          <path
            d="M0.661315 0.418639H52.2439V26.2099V39.1623L39.405 52.0012H26.4526H0.661315V0.418639Z"
            stroke="currentColor"
            strokeWidth="1.32263"
          />
        </g>
        <defs>
          <clipPath id="clip0_1276_16806">
            <rect width="52.9052" height="52.9056" fill="white" transform="translate(0 0.418701)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

BackIcon.displayName = 'BackIcon';
