export const DEGEN_WARS_LENS_ABI = [
  {
    inputs: [
      {
        internalType: 'contract DegenWars',
        name: 'degenWars',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'nftId_',
        type: 'uint256',
      },
    ],
    name: 'getCardData',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            internalType: 'bool',
            name: 'isOnLoan',
            type: 'bool',
          },
          {
            internalType: 'uint256',
            name: 'endurance',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'effect',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'arena',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'gameId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'round',
            type: 'uint256',
          },
          {
            internalType: 'uint256[]',
            name: 'health',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'cardHealth',
            type: 'uint256[]',
          },
          {
            internalType: 'int256[]',
            name: 'arenaEffect',
            type: 'int256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'enduranceLoss',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'tokenProperties',
            type: 'uint256[]',
          },
        ],
        internalType: 'struct DegenWarsLensInterface.CardData',
        name: 'cardData',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract DegenWars',
        name: 'degenWars',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'gameId_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'secret_',
        type: 'uint256',
      },
    ],
    name: 'getGameData',
    outputs: [
      {
        components: [
          {
            internalType: 'enum DegenWarsObjects.GameStatus',
            name: 'status',
            type: 'uint8',
          },
          {
            internalType: 'enum DegenWarsObjects.GameMode',
            name: 'mode',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'arena',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'blind',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startTime',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'round',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'attacker',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'pendingRand',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'pendingBooster',
            type: 'uint256',
          },
          {
            internalType: 'uint256[]',
            name: 'unusedPlayerOneBoosters',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'unusedPlayerTwoBoosters',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256',
            name: 'property',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expiry',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'winner',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'playerOne',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'playerOneTopCard',
            type: 'uint256',
          },
          {
            internalType: 'uint256[]',
            name: 'playerOneHand',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'playerTwo',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'playerTwoTopCard',
            type: 'uint256',
          },
          {
            internalType: 'uint256[]',
            name: 'playerTwoHand',
            type: 'uint256[]',
          },
        ],
        internalType: 'struct DegenWarsLensInterface.GameData',
        name: 'gameData',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const;
