import * as React from 'react';
import clsx from 'clsx';
import Image, { type StaticImageData } from 'next/image';
import Link from 'next/link';

type CardProps = React.PropsWithChildren<{
  image?: StaticImageData | undefined;
  href?: string;
  className?: string;
}>;

export function Card(props: CardProps) {
  const { image, href, className, children } = props;

  const rootClasses = clsx(
    'w-full border border-white/25 relative min-h-[300px]',
    'md:min-h-[100px]',
    className
  );

  const content = (
    <>
      <div className="absolute inset-0 bg-black">
        {image && (
          <Image
            alt=""
            priority
            src={image}
            placeholder="blur"
            fill
            className="object-cover object-center"
          />
        )}
      </div>

      <div
        className={clsx(
          'absolute inset-0 flex h-full items-end p-6',
          'bg-gradient-to-b from-transparent via-transparent to-black'
        )}
      >
        <div>{children}</div>
      </div>
    </>
  );

  if (href) {
    if (href.startsWith('http')) {
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={clsx(rootClasses, 'hover:border-white/50')}
        >
          {content}
        </a>
      );
    }

    return (
      <Link href={href} className={clsx(rootClasses, 'hover:border-white/50')}>
        {content}
      </Link>
    );
  }

  return <div className={rootClasses}>{content}</div>;
}
