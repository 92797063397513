'use client';

import * as React from 'react';
import Image from 'next/image';
import { Dialog } from '@headlessui/react';

import { cx } from '@/core/utils';
import { Background, Callout, Main } from '@/ui';
import { useArenaStore } from '@/game';
import { ARENAS } from '@/game/components/Search';
import { ArenaShuffleEffect, ArenaShuffleItem } from '@/play';
import binanceArena from '@/assets/images/arenas/shuffle/binance-arena.png';
import ethArena from '@/assets/images/arenas/shuffle/eth-arena.png';
import mcdArena from '@/assets/images/arenas/shuffle/mcd-arena.png';
import secArena from '@/assets/images/arenas/shuffle/sec-arena.png';
import twitterArena from '@/assets/images/arenas/shuffle/twitter-arena.png';
import arenaSelectBg from '@/assets/images/arena-select-bg-2x.png';

type ArenaShuffleProps = {
  setIsArenaSpinOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ArenaShuffle(props: ArenaShuffleProps) {
  const { setIsArenaSpinOpen } = props;

  const [isClient, setIsClient] = React.useState(false);
  const [isShuffleComplete, setIsShuffleComplete] = React.useState(false);

  const { activeArenaId } = useArenaStore();
  const selectedArena = ARENAS.find((arena) => arena.id === activeArenaId);

  const shuffleArenas = [...ARENAS].map((arena) => {
    switch (arena.id) {
      case 1:
        return { ...arena, image: twitterArena };
      case 2:
        return { ...arena, image: mcdArena };
      case 3:
        return { ...arena, image: binanceArena };
      case 4:
        return { ...arena, image: secArena };
      case 5:
      default:
        return { ...arena, image: ethArena };
    }
  });

  React.useEffect(() => {
    setIsClient(true);
    setTimeout(() => {
      setIsShuffleComplete(true);
    }, 6_000);
  }, []);

  React.useEffect(() => {
    if (isShuffleComplete) {
      setTimeout(() => {
        setIsArenaSpinOpen(false);
      }, 2_500);
    }
  }, [isShuffleComplete]);

  if (!isClient || !activeArenaId) {
    return null;
  }

  return (
    <>
      {/* show arena background after shuffle */}
      {isShuffleComplete && selectedArena && (
        <Background hasGrid={false} className={cx('opacity-70')} image={selectedArena.background} />
      )}

      <Main className="flex w-screen flex-1 items-center">
        <div className={cx('relative mx-auto w-full max-w-7xl')}>
          {/* bg */}
          <Image
            src={arenaSelectBg}
            placeholder="blur"
            unoptimized
            alt=""
            width={2718}
            height={1173}
            className={cx(
              'pointer-events-none relative -z-10 h-auto w-full object-contain object-center'
            )}
          />

          <div className={cx('absolute inset-0 px-[5%] pt-[12.5%]')}>
            <div className="mx-4 grid grid-cols-3 gap-4">
              <ArenaShuffleItem
                className={cx('animate-scrollUp')}
                arenas={shuffleArenas}
                activeArenaId={activeArenaId}
              />
              <ArenaShuffleItem
                className={cx('animate-scrollUp1')}
                arenas={shuffleArenas}
                activeArenaId={activeArenaId}
              />
              <ArenaShuffleItem
                className={cx('animate-scrollUp2')}
                arenas={shuffleArenas}
                activeArenaId={activeArenaId}
              />
            </div>

            {isShuffleComplete && <ArenaShuffleEffect />}
          </div>
        </div>
      </Main>

      <Dialog
        open={isShuffleComplete}
        onClose={() => null}
        className="fixed left-0 top-0 z-50 flex h-full w-screen items-center justify-center"
      >
        <Dialog.Panel className="relative flex items-center justify-center">
          <Callout />
          <span className="absolute text-3xl font-extrabold text-cyan">{selectedArena?.name}</span>
        </Dialog.Panel>
      </Dialog>
    </>
  );
}
