import * as React from 'react';

export const PreferencesIcon = React.forwardRef<
  SVGSVGElement,
  React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => {
  return (
    <svg
      ref={ref}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1C7.30622 1 8.41746 1.83481 8.82929 3H18C18.5523 3 19 3.44772 19 4C19 4.55228 18.5523 5 18 5H8.82929C8.41746 6.16519 7.30622 7 6 7C4.69378 7 3.58254 6.16519 3.17071 5H2C1.44772 5 1 4.55228 1 4C1 3.44772 1.44772 3 2 3H3.17071C3.58254 1.83481 4.69378 1 6 1ZM6 5C6.55228 5 7 4.55228 7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5ZM1 10C1 9.44771 1.44772 9 2 9H11.1707C11.5825 7.83481 12.6938 7 14 7C15.3062 7 16.4175 7.83481 16.8293 9H18C18.5523 9 19 9.44771 19 10C19 10.5523 18.5523 11 18 11H16.8293C16.4175 12.1652 15.3062 13 14 13C12.6938 13 11.5825 12.1652 11.1707 11H2C1.44772 11 1 10.5523 1 10ZM15 10C15 10.5523 14.5523 11 14 11C13.4477 11 13 10.5523 13 10C13 9.44771 13.4477 9 14 9C14.5523 9 15 9.44771 15 10ZM1 16C1 15.4477 1.44772 15 2 15H4.17071C4.58254 13.8348 5.69378 13 7 13C8.30622 13 9.41746 13.8348 9.82929 15H18C18.5523 15 19 15.4477 19 16C19 16.5523 18.5523 17 18 17H9.82929C9.41746 18.1652 8.30622 19 7 19C5.69378 19 4.58254 18.1652 4.17071 17H2C1.44772 17 1 16.5523 1 16ZM8 16C8 16.5523 7.55228 17 7 17C6.44772 17 6 16.5523 6 16C6 15.4477 6.44772 15 7 15C7.55228 15 8 15.4477 8 16Z"
        fill="currentColor"
      />
    </svg>
  );
});

PreferencesIcon.displayName = 'PreferencesIcon';
