'use client';

import * as React from 'react';
import { motion } from 'framer-motion';

import { useGameAnimationStore } from '@/game';

export type StatProps = React.PropsWithChildren<{
  title: string;
  animationdDelay: number;
}>;

export function Stat(props: StatProps) {
  const { title, children, animationdDelay } = props;

  const { hasSeenGameAnimation } = useGameAnimationStore();

  return (
    <motion.div
      initial={{ opacity: hasSeenGameAnimation ? 1 : 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: animationdDelay }}
      className="w-full space-y-2 border border-white/25 bg-black/80 p-3 backdrop-blur-xl"
    >
      <p className="text-sm font-semibold uppercase text-red">{title}</p>
      <p className="font-bold text-white lg:text-2xl">{children}</p>
    </motion.div>
  );
}
