import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { HiXMark } from 'react-icons/hi2';
import { useIsClient } from 'usehooks-ts';

import { cx } from '@/core/utils';

const message = `Current network issues may affect gameplay.`;

export function NetworkWarningBanner() {
  const { shouldShowWarning, setShouldShowWarning } = useNetworkWarning();
  const isClient = useIsClient();

  if (!isClient || !shouldShowWarning) {
    return null;
  }

  return (
    <div
      className={cx('absolute bottom-0 left-0 w-full translate-y-full bg-yellow-thirtiary/30 px-4')}
    >
      <div
        className={cx(
          'container relative mx-auto max-w-screen-2xl px-8 py-2 text-center text-yellow-thirtiary'
        )}
      >
        <strong className={cx('font-display text-lg font-normal')}>{message}</strong>

        <button className={cx('absolute -right-2 top-1/2 -translate-y-1/2')}>
          <HiXMark className={cx('h-6 w-6')} onClick={() => setShouldShowWarning(false)} />
        </button>
      </div>
    </div>
  );
}

type NetworkWarningState = {
  shouldShowWarning: boolean;
  setShouldShowWarning: (shouldShowWarning: boolean) => void;
};

export const useNetworkWarning = create<NetworkWarningState>()(
  devtools(
    persist(
      (set) => ({
        shouldShowWarning: true,
        setShouldShowWarning: (shouldShowWarning: boolean) => {
          set({ shouldShowWarning });
        },
      }),
      {
        name: 'network-warning-storage',
        version: 3,
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      name: 'network-warning',
    }
  )
);
