'use client';

import { useEffect, type DependencyList } from 'react';

export function useTimeout(fn: () => void | (() => void), ms: number, deps: DependencyList) {
  useEffect(() => {
    let cleanup: (() => void) | void | undefined = undefined;

    const timeout = setTimeout(() => {
      cleanup = fn();
    }, ms);

    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }

      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useInterval(fn: () => void | (() => void), ms: number, deps: DependencyList) {
  useEffect(() => {
    let cleanup: (() => void) | void | undefined = undefined;

    const interval = setInterval(() => {
      cleanup = fn();
    }, ms);

    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }

      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
