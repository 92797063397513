import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type Parameters =
  | 'liquidity'
  | 'degeneracy'
  | 'techKnowHow'
  | 'influence'
  | 'shitposter'
  | 'alpha';

export type CardProperty = {
  id: number;
  prop: Parameters;
};

export type CardParameters = {
  [key in Parameters]: {
    currentValue: number;
    enduranceLossImpact: number;
    arenaEffectImpact: number;
  };
};

export type Currency = 'ETH';

export type Blind = {
  id: string;
  name: string;
  prize: string;
  currency: Currency;
};

export const BLINDS: Blind[] = [
  { id: '1', name: 'Blind tier 1', prize: '0.01', currency: 'ETH' },
  { id: '2', name: 'Blind tier 2', prize: '0.05', currency: 'ETH' },
  { id: '3', name: 'Blind tier 3', prize: '0.1', currency: 'ETH' },
  { id: '4', name: 'Blind tier 4', prize: '1', currency: 'ETH' },
];

type ArenaState = {
  isSearching: boolean;
  isExpired: boolean;
  activeArenaId: number | undefined;
  activeGameId: number | undefined;
  activeBlind: Blind;
  activeBooster: number;
  currentUiRound: number;
  playerId: 1 | 2 | undefined; // determines if player is player 1 or player 2 in the game
  playerTopCard: number | undefined;
  playerOneHand: number[];
  playerTwoHand: number[];
  cardParams: CardParameters | undefined;
  shouldHideProperties: boolean;
  setIsSearching: (isSearching: boolean) => void;
  setIsExpired: (isExpired: boolean) => void;
  setActiveArenaId: (arenaId: number | undefined) => void;
  setActiveGameId: (gameId: number | undefined) => void;
  setActiveBlind: (blind: Blind) => void;
  setPlayerId: (playerId: 1 | 2 | undefined) => void;
  setPlayerTopCard: (playerTopCard: number | undefined) => void;
  setPlayerOneHand: (playerOneHand: number[]) => void;
  setPlayerTwoHand: (playerTwoHand: number[]) => void;
  setCardParams: (cardParams: CardParameters | undefined) => void;
  setActiveBooster: (booster: number) => void;
  incrementCurrentUiRound: () => void;
  getCardIds: () => number[];
  setShouldHideProperties: (shouldHideProperties: boolean) => void;
  clearAll: () => void;
};

export const useArenaStore = create<ArenaState>()(
  devtools(
    persist(
      (set, get) => ({
        isSearching: false,
        isExpired: false,
        activeArenaId: undefined,
        activeBlind: BLINDS[0] as Blind,
        activeGameId: undefined,
        playerId: undefined,
        playerTopCard: undefined,
        playerOneHand: [],
        playerTwoHand: [],
        cardParams: undefined,
        activeBooster: 0,
        currentUiRound: 1,
        shouldHideProperties: false,
        setIsSearching: (isSearching: boolean) => {
          set({
            isSearching,
          });
        },
        setIsExpired: (isExpired: boolean) => {
          set({
            isExpired,
          });
        },
        setActiveArenaId: (arenaId: number | undefined) => {
          set({
            activeArenaId: arenaId,
          });
        },
        setActiveGameId: (gameId: number | undefined) => {
          set({
            activeGameId: gameId,
          });
        },
        setActiveBlind: (blind: Blind) => {
          set({
            activeBlind: blind,
          });
        },
        setPlayerId: (playerId: 1 | 2 | undefined) => {
          set({
            playerId,
          });
        },
        setPlayerTopCard: (playerTopCard: number | undefined) => {
          set({
            playerTopCard,
          });
        },
        setPlayerOneHand: (playerOneHand: number[]) => {
          set({
            playerOneHand,
          });
        },
        setPlayerTwoHand: (playerTwoHand: number[]) => {
          set({
            playerTwoHand,
          });
        },
        setCardParams: (cardParams: CardParameters | undefined) => {
          set({
            cardParams,
          });
        },
        setActiveBooster: (booster: number) => {
          set({
            activeBooster: booster,
          });
        },
        incrementCurrentUiRound: () => {
          set({
            currentUiRound: get().currentUiRound + 1,
          });
        },
        getCardIds: () => [...get().playerOneHand, ...get().playerTwoHand],
        setShouldHideProperties: (shouldHideProperties: boolean) => {
          set({
            shouldHideProperties,
          });
        },
        clearAll: () => {
          set({
            isSearching: false,
            isExpired: false,
            activeArenaId: undefined,
            activeGameId: undefined,
            playerId: undefined,
            playerTopCard: undefined,
            cardParams: undefined,
            playerOneHand: [],
            playerTwoHand: [],
            activeBooster: 0,
            currentUiRound: 1,
          });
        },
      }),
      {
        name: 'game-arena-storage',
        version: 1,
      }
    ),
    {
      name: 'game-arena-storage',
    }
  )
);
