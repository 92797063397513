'use client';

import { useEffect, useState } from 'react';
import { useAccount, useContractRead } from 'wagmi';
import dayjs from 'dayjs';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_LENS_ABI, ZERO_ADDRESS } from '@/core/lib';

export type UsePlayerDataProps = {
  gameId: number;
  secret: string;
  shouldWatch?: boolean;
};

export type PlayerData = {
  address: `0x${string}` | null;
  topCard: number | null;
  hand: number[] | null;
  unusedBoosters: number[] | null;
  isCurrentPlayer?: boolean;
};

export function usePlayerData(props: UsePlayerDataProps) {
  const { gameId, secret, shouldWatch = false } = props;
  const { address } = useAccount();

  const [playerOneData, setPlayerOneData] = useState<PlayerData>({
    address: null,
    topCard: null,
    hand: null,
    unusedBoosters: null,
  });

  const [playerTwoData, setPlayerTwoData] = useState<PlayerData>({
    address: null,
    topCard: null,
    hand: null,
    unusedBoosters: null,
  });

  const { data, isError, isLoading, refetch } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS_LENS,
    abi: DEGEN_WARS_LENS_ABI,
    functionName: 'getGameData',
    args: [
      CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
      BigInt(gameId ?? 0),
      BigInt(secret || ZERO_ADDRESS),
    ],
    watch: shouldWatch,
    enabled: !!gameId && !!secret,
  });

  useEffect(() => {
    if (data) {
      const playerOneChainData: PlayerData = {
        address: data.playerOne,
        topCard: Number(data.playerOneTopCard),
        hand: data.playerOneHand.map(Number),
        unusedBoosters: data.unusedPlayerOneBoosters.map(Number),
        isCurrentPlayer: data.playerOne === address,
      };

      const playerTwoChainData: PlayerData = {
        address: data.playerTwo,
        topCard: Number(data.playerTwoTopCard),
        hand: data.playerTwoHand.map(Number),
        unusedBoosters: data.unusedPlayerTwoBoosters.map(Number),
        isCurrentPlayer: data.playerTwo === address,
      };

      console.group('Player Data');
      console.log('timestamp', dayjs().format('HH:mm:ss'));

      console.log('Player One Data', playerOneChainData);
      console.log('Player Two Data', playerTwoChainData);

      console.groupEnd();

      setPlayerOneData(playerOneChainData);
      setPlayerTwoData(playerTwoChainData);
    }
  }, [data, isError, isLoading]);

  return {
    playerOneData,
    playerTwoData,
    refetch,
  };
}
