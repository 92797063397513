'use client';

import React from 'react';
import clsx from 'clsx';

import { Modal } from '@/ui';

import { useArenaStore } from '../store';

export type GameCancelModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  isExpire: boolean;
  handleClose: () => void;
  cancelGame: () => void;
  continueGame: () => void;
};

export const GameCancelModal = React.memo(
  (props: GameCancelModalProps) => {
    const { cancelGame, continueGame, handleClose, isOpen, isLoading, isExpire } = props;
    const { activeBlind } = useArenaStore();

    const warning = isExpire ? (
      'Are you sure you want to cancel?'
    ) : (
      <>
        {`If you leave the game then there is a penalty fee of`}
        <span className="text-cyan">
          {Number(activeBlind.prize)} {`ETH`}
        </span>
      </>
    );

    return (
      <Modal onClose={handleClose} isShow={isOpen}>
        <div className="max-w-[340px] flex-col items-center justify-center">
          <h2 className="pb-2 text-center text-2xl font-bold text-white">ARE YOU SURE?</h2>
          <div className="mx-auto mb-7 flex w-4/5">
            <span className="text-center font-display text-base font-normal text-gray-600">
              {warning}
            </span>
          </div>
          <button
            className={clsx(
              'mb-3 h-10 w-full border border-primary-500 font-display text-base font-normal text-primary-500 hover:opacity-80',
              isLoading && 'cursor-wait opacity-50'
            )}
            disabled={isLoading}
            onClick={() => cancelGame()}
          >
            Yes,{!isExpire ? ' Pay Penalty and' : ''} leave game
          </button>
          <button
            className={clsx(
              'h-10 w-full border border-purple-light font-display text-base font-normal text-white hover:opacity-80',
              isLoading && 'cursor-wait opacity-50'
            )}
            disabled={isLoading}
            onClick={() => continueGame()}
          >
            Continue Game
          </button>
        </div>
      </Modal>
    );
  },
  (newProps, oldProps) => {
    return newProps.isOpen === oldProps.isOpen;
  }
);

GameCancelModal.displayName = 'GameCancelModal';
