import * as React from 'react';
import Image from 'next/image';
import { useLottie } from 'lottie-react';
import { motion } from 'framer-motion';

import playerCardBg from '@/assets/images/player-cards/player-card-bg.png';
import addToDeckAnimation from '@/assets/images/lottie/add-to-deck.json';
import outFromDeckAnimation from '@/assets/images/lottie/out-of-deck.json';
import { imageUrl } from '@/core/lib';
import { useAudio } from '@/core/hooks';
import { cx } from '@/core/utils';

import { GameSteps } from './ArenaFrame';

import { useSettingsStore } from '../store';

type DeckStackProps = {
  cardsAmount: number;
  currentGameStep: GameSteps;
  variant?: 'top' | 'bottom';
  isPlayerOne: boolean;
  playerOneHand: number[] | null;
  playerTwoHand: number[] | null;
  playerOneTopCard: number | null;
  playerTwoTopCard: number | null;
};

type PlayerDeckItem = {
  src: string;
  height: number;
  width: number;
  blurHeight?: number;
  blurWidth?: number;
  blurSrc?: string;
};

export function DeckStack(props: DeckStackProps) {
  const {
    cardsAmount,
    currentGameStep,
    variant,
    isPlayerOne,
    playerOneHand,
    playerOneTopCard,
    playerTwoHand,
    playerTwoTopCard,
  } = props;

  const [isAddCardAnimated, setIsAddCardAnimated] = React.useState(false);
  const [isOutCardAnimated, setIsOutCardAnimated] = React.useState(false);
  const cardAudio = useAudio({ src: '/audio/cards-slide.mp3', volume: 0.5 });
  const { isSfx } = useSettingsStore();

  const playerDeck = React.useMemo(() => {
    let playerDeck2: PlayerDeckItem[] = [];

    if (!isPlayerOne && variant === 'bottom') {
      const cardsOnHandToShow = playerTwoHand?.filter((item) => item !== playerTwoTopCard);

      playerDeck2 = cardsAmount
        ? cardsOnHandToShow?.map((item) => ({
            src: imageUrl(item),
            height: 500,
            width: 350,
          })) || []
        : [];
    }

    if (isPlayerOne && variant === 'bottom') {
      const cardsOnHandToShow = playerOneHand?.filter((item) => item !== playerOneTopCard);

      playerDeck2 = cardsAmount
        ? cardsOnHandToShow?.map((item) => ({
            src: imageUrl(item),
            height: 500,
            width: 350,
          })) || []
        : [];
    }

    if (!isPlayerOne && variant === 'top') {
      playerDeck2 = cardsAmount ? Array(cardsAmount).fill(playerCardBg) : [];
    }

    if (isPlayerOne && variant === 'top') {
      playerDeck2 = cardsAmount ? Array(cardsAmount).fill(playerCardBg) : [];
    }

    return playerDeck2;
  }, [isPlayerOne, variant, playerOneHand, playerTwoHand, playerOneTopCard, playerTwoTopCard]);

  const playerDeckWithoutOneCard =
    playerDeck.length && playerDeck.length > 1 && playerDeck.slice(1, playerDeck.length);

  React.useEffect(() => {
    if (currentGameStep === 'you win') {
      if (variant === 'bottom') {
        return setIsAddCardAnimated(true);
      }

      if (isSfx) {
        cardAudio?.play();
      }

      return setIsOutCardAnimated(true);
    }

    if (currentGameStep === 'you lose') {
      if (variant === 'bottom') {
        return setIsOutCardAnimated(true);
      }

      if (isSfx) {
        cardAudio?.play();
      }

      return setIsAddCardAnimated(true);
    }
  }, [currentGameStep]);

  React.useEffect(() => {
    return () => {
      if (cardAudio) {
        cardAudio.pause();
        cardAudio.src = '';
      }
    };
  }, []);

  const addToDeckOptions = {
    animationData: isAddCardAnimated && addToDeckAnimation,
    loop: false,
    onComplete: () => {
      setIsAddCardAnimated(false);
    },
  };

  const outFromDeckOptions = {
    animationData: isOutCardAnimated && outFromDeckAnimation,
    loop: false,
    onComplete: () => {
      setIsOutCardAnimated(false);
    },
  };

  const { View: AddToDeckView } = useLottie(addToDeckOptions);
  const { View: OutFromDeckView } = useLottie(outFromDeckOptions);

  return (
    <motion.div
      initial={{ x: 0 }}
      animate={{ x: isAddCardAnimated ? 50 : isOutCardAnimated ? -50 : 50 }}
      transition={{ duration: 1 }}
      className="relative mr-28 flex"
    >
      {playerDeckWithoutOneCard ? (
        <div className={cx('flex')}>
          <div
            className={cx(
              'absolute bottom-0 z-20 w-36 2xl:w-52',
              playerDeck.length === 1 && '-left-32',
              playerDeck.length > 1 && playerDeck.length <= 4 && ['-left-20', '2xl:-left-32'],
              playerDeck.length === 5 && ['-left-20', '2xl:-left-28'],
              playerDeck.length > 5 && playerDeck.length < 7 && '-left-14',
              playerDeck.length >= 7 && '-left-12'
            )}
          >
            {isAddCardAnimated && <div className=" w-36 2xl:w-52">{AddToDeckView}</div>}
            {isOutCardAnimated && <div className=" w-36 2xl:w-52">{OutFromDeckView}</div>}
            <>
              {!!playerDeck.length && playerDeck[0] && (
                <Image
                  src={playerDeck[0]}
                  width={350}
                  height={500}
                  alt="player card"
                  className={cx(
                    variant === 'bottom' && 'hover:!z-30 hover:-translate-y-20 hover:rotate-1',
                    isAddCardAnimated ? 'opacity-0' : 'opacity-1',
                    isOutCardAnimated && 'opacity-0',
                    'absolute bottom-0  !h-auto !w-36 2xl:!w-52'
                  )}
                />
              )}
            </>
          </div>
          {playerDeckWithoutOneCard.map((img, index: number) => (
            <Image
              key={index}
              src={img}
              width={350}
              height={500}
              alt="player card"
              style={{
                zIndex: playerDeckWithoutOneCard.length - index,
              }}
              className={cx(
                variant === 'bottom' && 'hover:!z-30 hover:-translate-y-20 hover:rotate-1',
                playerDeckWithoutOneCard.length === 1 && '-mr-0',
                playerDeckWithoutOneCard.length > 1 &&
                  playerDeckWithoutOneCard.length <= 4 &&
                  '-mr-16',
                playerDeckWithoutOneCard.length === 5 && '-mr-24 2xl:-mr-36',
                playerDeckWithoutOneCard.length > 5 &&
                  playerDeckWithoutOneCard.length < 7 &&
                  '-mr-24 2xl:-mr-36',
                playerDeckWithoutOneCard.length >= 7 && '-mr-24 2xl:-mr-36',
                '!h-auto !w-36 2xl:!w-52'
              )}
            />
          ))}
        </div>
      ) : (
        <>
          <div className="-mr-36 flex aspect-[2/3] w-36 2xl:w-52"></div>

          {playerDeck.length === 1 && (
            <div className={cx('flex')}>
              <div className={cx('absolute bottom-0 z-20 w-36 2xl:w-52', '-left-32')}>
                {isAddCardAnimated && <div className=" w-36 2xl:w-52">{AddToDeckView}</div>}
                {isOutCardAnimated && <div className=" w-36 2xl:w-52">{OutFromDeckView}</div>}
                <>
                  {!!playerDeck.length && playerDeck[0] && (
                    <Image
                      src={playerDeck[0]}
                      width={350}
                      height={500}
                      alt="player card"
                      className={cx(
                        variant === 'bottom' && 'hover:!z-30 hover:-translate-y-20 hover:rotate-1',
                        isAddCardAnimated ? 'opacity-0' : 'opacity-1',
                        isOutCardAnimated && 'opacity-0',
                        'absolute bottom-0 !h-auto !w-36 2xl:!w-52'
                      )}
                    />
                  )}
                </>
              </div>
            </div>
          )}
        </>
      )}
    </motion.div>
  );
}
