export function Callout() {
  return (
    <svg
      width="595"
      height="182"
      viewBox="0 0 595 182"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3081_35663)">
        <rect width="594.396" height="181.939" fill="black" fillOpacity="0.7" />
        <g filter="url(#filter0_d_3081_35663)">
          <path
            d="M585.306 107.233H535.426C532.05 107.233 529.078 105.475 527.461 102.521C525.843 99.5672 525.966 96.1035 527.777 93.2728L552.726 54.3813C554.397 51.7616 557.262 50.2144 560.357 50.2144C563.451 50.2144 566.317 51.7792 567.987 54.3813L592.919 93.2728C594.73 96.1035 594.853 99.5672 593.253 102.521C591.635 105.475 588.664 107.233 585.288 107.233H585.306ZM560.357 53.4846C558.37 53.4846 556.559 54.4868 555.487 56.1395L530.538 95.031C529.377 96.842 529.307 99.0397 530.327 100.939C531.364 102.82 533.263 103.945 535.408 103.945H585.288C587.433 103.945 589.332 102.82 590.369 100.939C591.407 99.0573 591.319 96.842 590.158 95.0486L565.227 56.1571C564.155 54.4868 562.344 53.5022 560.357 53.5022V53.4846Z"
            fill="#51D2E7"
          />
          <path
            d="M568.778 81.8796H562.22L565.789 63.2778L549.736 81.8796H556.523L552.954 100.499L568.778 81.8796Z"
            fill="#51D2E7"
          />
          <path
            d="M58.9699 107.233H9.08959C5.71383 107.233 2.74247 105.475 1.12492 102.521C-0.492631 99.5672 -0.369557 96.1035 1.4414 93.2728L26.3903 54.3637C28.0606 51.744 30.9265 50.1968 34.0209 50.1968C37.1154 50.1968 39.9813 51.7616 41.6516 54.3637L66.6005 93.2552C68.4114 96.0859 68.5345 99.5496 66.917 102.503C65.2994 105.457 62.328 107.215 58.9523 107.215L58.9699 107.233ZM34.0209 53.4846C32.0342 53.4846 30.2232 54.4868 29.1507 56.1395L4.21936 95.031C3.05894 96.842 2.98862 99.0397 4.00838 100.939C5.04572 102.82 6.927 103.945 9.08959 103.945H58.9699C61.1149 103.945 63.0138 102.82 64.0511 100.939C65.0884 99.0573 65.0005 96.842 63.8401 95.031L38.8912 56.1395C37.8187 54.4692 36.0077 53.4846 34.0209 53.4846Z"
            fill="#51D2E7"
          />
          <path
            d="M42.4418 81.8796H35.8836L39.4528 63.2778L23.4004 81.8796H30.1871L26.6179 100.499L42.4418 81.8796Z"
            fill="#51D2E7"
          />
          <path
            d="M32.9301 38.364C32.0158 38.364 31.2949 37.6256 31.2949 36.7289V19.8325C31.2949 18.9183 32.0334 18.1974 32.9301 18.1974H221.374C222.289 18.1974 223.01 18.9358 223.01 19.8325C223.01 20.7292 222.271 21.4676 221.374 21.4676H34.5652V36.7113C34.5652 37.6256 33.8267 38.3464 32.9301 38.3464V38.364Z"
            fill="#51D2E7"
          />
          <path
            d="M221.374 13.0459H32.9299C32.4727 13.0459 32.1035 12.6767 32.1035 12.2195C32.1035 11.7624 32.4727 11.3932 32.9299 11.3932H221.374C221.831 11.3932 222.201 11.7624 222.201 12.2195C222.201 12.6767 221.831 13.0459 221.374 13.0459Z"
            fill="#51D2E7"
          />
          <path
            d="M28.9925 11.147H19.4982C19.0411 11.147 18.6719 10.7778 18.6719 10.3207V0.826357C18.6719 0.369223 19.0411 0 19.4982 0H28.9925C29.4497 0 29.8189 0.369223 29.8189 0.826357V10.3207C29.8189 10.7778 29.4497 11.147 28.9925 11.147ZM20.307 9.51189H28.1662V1.63513H20.307V9.49431V9.51189Z"
            fill="#51D2E7"
          />
          <path
            d="M28.9916 11.147C28.7806 11.147 28.5696 11.0591 28.4114 10.9009L18.917 1.40656C18.6006 1.09009 18.6006 0.562624 18.917 0.246147C19.2335 -0.0703302 19.761 -0.0703302 20.0775 0.246147L29.5718 9.74045C29.8882 10.0569 29.8882 10.5844 29.5718 10.9009C29.4135 11.0591 29.2025 11.147 28.9916 11.147Z"
            fill="#51D2E7"
          />
          <path
            d="M19.4973 11.147C19.2863 11.147 19.0753 11.0591 18.917 10.9009C18.6006 10.5844 18.6006 10.0569 18.917 9.74045L28.4114 0.246147C28.7278 -0.0703302 29.2553 -0.0703302 29.5718 0.246147C29.8882 0.562624 29.8882 1.09009 29.5718 1.40656L20.0775 10.9009C19.9192 11.0591 19.7082 11.147 19.4973 11.147Z"
            fill="#51D2E7"
          />
          <path
            d="M32.9316 24.615H110.134L114.442 20.3074L32.9316 19.8326V24.615Z"
            fill="#51D2E7"
          />
          <path
            d="M32.9299 45.7835C32.4727 45.7835 32.1035 45.4143 32.1035 44.9572V41.8627C32.1035 41.4056 32.4727 41.0364 32.9299 41.0364C33.387 41.0364 33.7562 41.4056 33.7562 41.8627V44.9572C33.7562 45.4143 33.387 45.7835 32.9299 45.7835Z"
            fill="#51D2E7"
          />
          <path
            d="M560.322 38.364C559.408 38.364 558.687 37.6256 558.687 36.7289V21.4676H371.895C370.981 21.4676 370.26 20.7292 370.26 19.8325C370.26 18.9358 370.998 18.1974 371.895 18.1974H560.339C561.254 18.1974 561.974 18.9358 561.974 19.8325V36.7289C561.974 37.6431 561.236 38.364 560.339 38.364H560.322Z"
            fill="#51D2E7"
          />
          <path
            d="M560.322 13.0459H371.877C371.42 13.0459 371.051 12.6767 371.051 12.2195C371.051 11.7624 371.42 11.3932 371.877 11.3932H560.322C560.779 11.3932 561.148 11.7624 561.148 12.2195C561.148 12.6767 560.779 13.0459 560.322 13.0459Z"
            fill="#51D2E7"
          />
          <path
            d="M573.754 11.147H564.26C563.803 11.147 563.434 10.7778 563.434 10.3207V0.826357C563.434 0.369223 563.803 0 564.26 0H573.754C574.211 0 574.581 0.369223 574.581 0.826357V10.3207C574.581 10.7778 574.211 11.147 573.754 11.147ZM565.069 9.51189H572.928V1.63513H565.069V9.49431V9.51189Z"
            fill="#51D2E7"
          />
          <path
            d="M564.243 11.147C564.032 11.147 563.821 11.0591 563.663 10.9009C563.347 10.5844 563.347 10.0569 563.663 9.74045L573.157 0.246147C573.474 -0.0703302 574.001 -0.0703302 574.318 0.246147C574.634 0.562624 574.634 1.09009 574.318 1.40656L564.824 10.9009C564.665 11.0591 564.454 11.147 564.243 11.147Z"
            fill="#51D2E7"
          />
          <path
            d="M573.753 11.147C573.542 11.147 573.331 11.0591 573.173 10.9009L563.679 1.40656C563.362 1.09009 563.362 0.562624 563.679 0.246147C563.995 -0.0703302 564.523 -0.0703302 564.839 0.246147L574.333 9.74045C574.65 10.0569 574.65 10.5844 574.333 10.9009C574.175 11.0591 573.964 11.147 573.753 11.147Z"
            fill="#51D2E7"
          />
          <path
            d="M560.321 24.615H483.101L478.811 20.3074L560.321 19.8326V24.615Z"
            fill="#51D2E7"
          />
          <path
            d="M560.32 45.7835C559.863 45.7835 559.494 45.4143 559.494 44.9572V41.8627C559.494 41.4056 559.863 41.0364 560.32 41.0364C560.778 41.0364 561.147 41.4056 561.147 41.8627V44.9572C561.147 45.4143 560.778 45.7835 560.32 45.7835Z"
            fill="#51D2E7"
          />
          <path d="M82.8457 133.5V136.982L85.4478 133.5H82.8457Z" fill="#51D2E7" />
          <path
            d="M93.8697 133.5H88.9818L82.8457 141.676V148.058H82.9336L93.8697 133.5Z"
            fill="#51D2E7"
          />
          <path
            d="M91.3546 148.058H86.4492L97.4028 133.5H102.291L91.3546 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M99.7765 148.058H94.8711L105.825 133.5H110.713L99.7765 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M108.198 148.058H103.293L114.247 133.5H119.134L108.198 148.058Z"
            fill="#51D2E7"
          />
          <path d="M116.62 148.058H111.715L122.668 133.5H127.556L116.62 148.058Z" fill="#51D2E7" />
          <path d="M125.025 148.058H120.137L131.09 133.5H135.978L125.025 148.058Z" fill="#51D2E7" />
          <path d="M133.446 148.058H128.559L139.512 133.5H144.4L133.446 148.058Z" fill="#51D2E7" />
          <path d="M141.868 148.058H136.98L147.934 133.5H152.822L141.868 148.058Z" fill="#51D2E7" />
          <path d="M150.29 148.058H145.402L156.356 133.5H161.244L150.29 148.058Z" fill="#51D2E7" />
          <path d="M158.712 148.058H153.824L164.76 133.5H169.666L158.712 148.058Z" fill="#51D2E7" />
          <path
            d="M167.134 148.058H162.246L173.182 133.5H178.088L167.134 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M175.556 148.058H170.668L181.604 133.5H186.509L175.556 148.058Z"
            fill="#51D2E7"
          />
          <path d="M183.978 148.058H179.09L190.026 133.5H194.931L183.978 148.058Z" fill="#51D2E7" />
          <path d="M192.4 148.058H187.512L198.448 133.5H203.336L192.4 148.058Z" fill="#51D2E7" />
          <path d="M200.821 148.058H195.934L206.87 133.5H211.757L200.821 148.058Z" fill="#51D2E7" />
          <path d="M351.394 148.058H346.506L357.442 133.5H362.33L351.394 148.058Z" fill="#51D2E7" />
          <path d="M359.905 148.558H355L365.954 134H370.841L359.905 148.558Z" fill="#51D2E7" />
          <path
            d="M368.237 148.058H363.332L374.286 133.5H379.173L368.237 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M376.659 148.058H371.754L382.708 133.5H387.595L376.659 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M385.081 148.058H380.176L391.129 133.5H396.017L385.081 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M393.485 148.058H388.598L399.551 133.5H404.439L393.485 148.058Z"
            fill="#51D2E7"
          />
          <path d="M401.907 148.058H397.02L407.973 133.5H412.861L401.907 148.058Z" fill="#51D2E7" />
          <path
            d="M410.329 148.058H405.441L416.395 133.5H421.283L410.329 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M418.749 148.058H413.861L424.815 133.5H429.703L418.749 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M427.173 148.058H422.285L433.239 133.5H438.127L427.173 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M435.595 148.058H430.707L441.643 133.5H446.548L435.595 148.058Z"
            fill="#51D2E7"
          />
          <path d="M444.017 148.058H439.129L450.065 133.5H454.97L444.017 148.058Z" fill="#51D2E7" />
          <path
            d="M452.439 148.058H447.551L458.487 133.5H463.392L452.439 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M460.859 148.058H455.971L466.907 133.5H471.812L460.859 148.058Z"
            fill="#51D2E7"
          />
          <path d="M469.28 148.058H464.393L475.329 133.5H480.216L469.28 148.058Z" fill="#51D2E7" />
          <path d="M477.702 148.058H472.814L483.75 133.5H488.638L477.702 148.058Z" fill="#51D2E7" />
          <path d="M486.124 148.058H481.236L492.172 133.5H497.06L486.124 148.058Z" fill="#51D2E7" />
          <path
            d="M208.734 148.058H203.846L214.799 133.5H219.687L208.734 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M217.155 148.058H212.268L223.204 133.5H228.109L217.155 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M225.577 148.058H220.689L231.626 133.5H236.531L225.577 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M233.999 148.058H229.111L240.047 133.5H244.953L233.999 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M242.421 148.058H237.533L248.469 133.5H253.375L242.421 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M250.841 148.058H245.953L256.889 133.5H261.795L250.841 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M259.263 148.058H254.375L265.311 133.5H270.199L259.263 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M267.685 148.058H262.797L273.733 133.5H278.621L267.685 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M276.107 148.058H271.219L282.155 133.5H287.043L276.107 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M284.528 148.058H279.641L290.577 133.5H295.464L284.528 148.058Z"
            fill="#51D2E7"
          />
          <path d="M292.95 148.058H288.062L298.999 133.5H303.886L292.95 148.058Z" fill="#51D2E7" />
          <path d="M301.372 148.058H296.467L307.42 133.5H312.308L301.372 148.058Z" fill="#51D2E7" />
          <path d="M309.794 148.058H304.889L315.842 133.5H320.73L309.794 148.058Z" fill="#51D2E7" />
          <path
            d="M318.216 148.058H313.311L324.264 133.5H329.152L318.216 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M326.638 148.058H321.732L332.686 133.5H337.574L326.638 148.058Z"
            fill="#51D2E7"
          />
          <path
            d="M335.042 148.058H330.154L341.108 133.5H345.996L335.042 148.058Z"
            fill="#51D2E7"
          />
          <path d="M343.466 148.058H338.578L349.532 133.5H354.42L343.466 148.058Z" fill="#51D2E7" />
          <path
            d="M500.594 133.5L489.658 148.058H494.546L502.81 137.07V133.5H500.594Z"
            fill="#51D2E7"
          />
          <path d="M498.08 148.058H502.81V141.764L498.08 148.058Z" fill="#51D2E7" />
          <path d="M341.706 12.2192H251.668V19.8147H341.706V12.2192Z" fill="#51D2E7" />
          <path
            d="M560.322 163.742H371.877C370.963 163.742 370.242 163.003 370.242 162.107C370.242 161.21 370.981 160.471 371.877 160.471H558.669V145.228C558.669 144.314 559.407 143.593 560.304 143.593C561.201 143.593 561.939 144.331 561.939 145.228V162.124C561.939 163.038 561.201 163.759 560.304 163.759L560.322 163.742Z"
            fill="#51D2E7"
          />
          <path
            d="M560.322 170.528H371.877C371.42 170.528 371.051 170.159 371.051 169.702C371.051 169.245 371.42 168.876 371.877 168.876H560.322C560.779 168.876 561.148 169.245 561.148 169.702C561.148 170.159 560.779 170.528 560.322 170.528Z"
            fill="#51D2E7"
          />
          <path
            d="M573.754 181.939H564.26C563.803 181.939 563.434 181.57 563.434 181.113V171.618C563.434 171.161 563.803 170.792 564.26 170.792H573.754C574.211 170.792 574.581 171.161 574.581 171.618V181.113C574.581 181.57 574.211 181.939 573.754 181.939ZM565.069 180.304H572.928V172.445H565.069V180.304Z"
            fill="#51D2E7"
          />
          <path
            d="M573.753 181.939C573.542 181.939 573.331 181.851 573.173 181.693L563.679 172.199C563.362 171.882 563.362 171.355 563.679 171.038C563.995 170.722 564.523 170.722 564.839 171.038L574.333 180.533C574.65 180.849 574.65 181.377 574.333 181.693C574.175 181.851 573.964 181.939 573.753 181.939Z"
            fill="#51D2E7"
          />
          <path
            d="M564.243 181.939C564.032 181.939 563.821 181.851 563.663 181.693C563.347 181.377 563.347 180.849 563.663 180.533L573.157 171.038C573.474 170.722 574.001 170.722 574.318 171.038C574.634 171.355 574.634 171.882 574.318 172.199L564.824 181.693C564.665 181.851 564.454 181.939 564.243 181.939Z"
            fill="#51D2E7"
          />
          <path
            d="M560.321 157.324H483.101L478.811 161.632L560.321 162.107V157.324Z"
            fill="#51D2E7"
          />
          <path
            d="M560.32 140.903C559.863 140.903 559.494 140.533 559.494 140.076V116.253C559.494 115.795 559.863 115.426 560.32 115.426C560.778 115.426 561.147 115.795 561.147 116.253V140.076C561.147 140.533 560.778 140.903 560.32 140.903Z"
            fill="#51D2E7"
          />
          <path
            d="M221.374 163.742H32.9301C32.0158 163.742 31.2949 163.003 31.2949 162.106V145.21C31.2949 144.296 32.0334 143.575 32.9301 143.575C33.8267 143.575 34.5652 144.313 34.5652 145.21V160.454H221.374C222.289 160.454 223.01 161.192 223.01 162.089C223.01 162.986 222.271 163.724 221.374 163.724V163.742Z"
            fill="#51D2E7"
          />
          <path
            d="M221.374 170.528H32.9299C32.4727 170.528 32.1035 170.159 32.1035 169.702C32.1035 169.245 32.4727 168.875 32.9299 168.875H221.374C221.831 168.875 222.201 169.245 222.201 169.702C222.201 170.159 221.831 170.528 221.374 170.528Z"
            fill="#51D2E7"
          />
          <path
            d="M28.9925 181.939H19.4982C19.0411 181.939 18.6719 181.57 18.6719 181.112V171.618C18.6719 171.161 19.0411 170.792 19.4982 170.792H28.9925C29.4497 170.792 29.8189 171.161 29.8189 171.618V181.112C29.8189 181.57 29.4497 181.939 28.9925 181.939ZM20.307 180.304H28.1662V172.444H20.307V180.304Z"
            fill="#51D2E7"
          />
          <path
            d="M19.4973 181.939C19.2863 181.939 19.0753 181.851 18.917 181.693C18.6006 181.376 18.6006 180.849 18.917 180.532L28.4114 171.038C28.7278 170.722 29.2553 170.722 29.5718 171.038C29.8882 171.354 29.8882 171.882 29.5718 172.198L20.0775 181.693C19.9192 181.851 19.7082 181.939 19.4973 181.939Z"
            fill="#51D2E7"
          />
          <path
            d="M28.9916 181.939C28.7806 181.939 28.5696 181.851 28.4114 181.693L18.917 172.198C18.6006 171.882 18.6006 171.354 18.917 171.038C19.2335 170.722 19.761 170.722 20.0775 171.038L29.5718 180.532C29.8882 180.849 29.8882 181.376 29.5718 181.693C29.4135 181.851 29.2025 181.939 28.9916 181.939Z"
            fill="#51D2E7"
          />
          <path
            d="M32.9316 157.324H110.134L114.442 161.632L32.9316 162.107V157.324Z"
            fill="#51D2E7"
          />
          <path
            d="M32.9299 140.902C32.4727 140.902 32.1035 140.533 32.1035 140.076V117.167C32.1035 116.71 32.4727 116.34 32.9299 116.34C33.387 116.34 33.7562 116.71 33.7562 117.167V140.076C33.7562 140.533 33.387 140.902 32.9299 140.902Z"
            fill="#51D2E7"
          />
          <path d="M251.529 169.702H341.566V162.107H251.529V169.702Z" fill="#51D2E7" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3081_35663"
          x="-14.0078"
          y="-14"
          width="622.379"
          height="209.939"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.317647 0 0 0 0 0.823529 0 0 0 0 0.905882 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3081_35663" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3081_35663"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3081_35663">
          <rect width="594.396" height="181.939" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
