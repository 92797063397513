export function AttackCalloutSvg() {
  return (
    <svg
      width="250"
      height="57"
      viewBox="0 0 250 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M90.0492 13.8732C89.1879 12.3283 87.0347 12.3283 86.1734 13.8732L76.8562 30.5868L67.539 47.3004C66.6777 48.8453 67.7577 50.7798 69.4803 50.7798H106.749C108.472 50.7798 109.552 48.8453 108.691 47.3004L99.3733 30.5868L90.0561 13.8732H90.0492Z"
        fill="#E8BB28"
      />
      <path
        d="M86.2073 39.5964H90.0149L90.384 33.4237V21.5294H85.8382V33.492L86.2073 39.5964Z"
        fill="#E8BB28"
      />
      <path d="M85.8858 40.6763H90.3359V45.2905H85.8858V40.6763Z" fill="#E8BB28" />
      <path
        d="M69.4799 51.1351H106.749C107.678 51.1351 108.519 50.6361 108.984 49.8021C109.449 48.9681 109.449 47.9633 108.984 47.1293L90.3497 13.7022C89.8849 12.8682 89.0509 12.3692 88.1144 12.3692C87.1847 12.3692 86.3439 12.8682 85.8791 13.7022L67.2446 47.1293C66.7798 47.9633 66.7798 48.9681 67.2446 49.8021C67.7095 50.6361 68.5434 51.1351 69.4799 51.1351ZM90.0557 13.8731L89.7618 14.0508L108.396 47.4779C108.738 48.0932 108.738 48.8314 108.396 49.4466C108.054 50.0619 107.439 50.431 106.749 50.431H69.4799C68.7964 50.431 68.1811 50.0619 67.8325 49.4466C67.4907 48.8314 67.4907 48.0932 67.8325 47.4779L86.4669 14.0508C86.8087 13.4356 87.424 13.0664 88.1075 13.0664C88.7911 13.0664 89.4064 13.4356 89.755 14.0508L90.0489 13.8731H90.0557Z"
        fill="#E8BB28"
      />
      <path d="M70.0604 55.2027H250V57.0005H70.0604V55.2027Z" fill="#E8BB28" />
      <path
        d="M5.26571 4.6896L4.95923 4.99608L43.7535 43.7926H72.3825V43.3594H43.9332L5.26571 4.6896Z"
        fill="#E8BB28"
      />
      <path
        d="M4.26771 0C1.91494 0 0 1.91494 0 4.26772C0 6.62049 1.91494 8.53542 4.26771 8.53542C6.62049 8.53542 8.53543 6.62049 8.53543 4.26772C8.53543 1.91494 6.62049 0 4.26771 0ZM4.26771 7.95011C2.23756 7.95011 0.587614 6.29787 0.587614 4.27001C0.587614 2.24216 2.23756 0.589921 4.26771 0.589921C6.29787 0.589921 7.9478 2.24216 7.9478 4.27001C7.9478 6.29787 6.29557 7.95011 4.26771 7.95011Z"
        fill="#E8BB28"
      />
      <path
        d="M2.86646 4.26963C2.86646 5.0439 3.49325 5.6707 4.26752 5.6707C5.04179 5.6707 5.66859 5.0439 5.66859 4.26963C5.66859 3.49536 5.04179 2.86857 4.26752 2.86857C3.49325 2.86857 2.86646 3.49536 2.86646 4.26963Z"
        fill="#E8BB28"
      />
      <path
        d="M72.3827 39.3082C70.0299 39.3082 68.115 41.2232 68.115 43.5759C68.115 45.9287 70.0299 47.8436 72.3827 47.8436C74.7355 47.8436 76.6504 45.9287 76.6504 43.5759C76.6504 41.2232 74.7355 39.3082 72.3827 39.3082ZM72.3827 47.2583C70.3525 47.2583 68.7026 45.6061 68.7026 43.5782C68.7026 41.5504 70.3549 39.8981 72.3827 39.8981C74.4106 39.8981 76.0628 41.5504 76.0628 43.5782C76.0628 45.6061 74.4129 47.2583 72.3827 47.2583Z"
        fill="#E8BB28"
      />
      <path
        d="M70.9814 43.5757C70.9814 44.35 71.6082 44.9768 72.3825 44.9768C73.1568 44.9768 73.7836 44.35 73.7836 43.5757C73.7836 42.8015 73.1568 42.1747 72.3825 42.1747C71.6082 42.1747 70.9814 42.8015 70.9814 43.5757Z"
        fill="#E8BB28"
      />
    </svg>
  );
}
