import { defineChain } from 'viem';

const sourceId = 11_155_111;

export const blastSepolia = defineChain({
  id: 168_587_773,
  name: 'Blast Sepolia',
  network: 'blast-sepolia',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    // alchemy: {
    //   http: ['https://blast-sepolia.g.alchemy.com/v2'],
    //   webSocket: ['wss://blast-sepolia.g.alchemy.com/v2'],
    // },
    default: {
      http: ['https://sepolia.blast.io'],
    },
    public: {
      http: ['https://sepolia.blast.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blastscan',
      url: 'https://sepolia.blastscan.io',
      // apiUrl: 'https://api-sepolia.blastscan.io/api',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 756690,
    },
  },
  testnet: true,
  sourceId,
});
