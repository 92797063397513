import { useContractEvent } from 'wagmi';
import dayjs from 'dayjs';

import { useArenaStore, useGameEventsStore } from '@/game';
import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

export function useGameReanimatedEvent() {
  const { activeGameId } = useArenaStore();
  const { gameReanimated, setGameReanimated } = useGameEventsStore();
  const { setGameEndPending } = useGameEventsStore();
  const { setHasBeenReanimated } = useGameEventsStore();

  useContractEvent({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    eventName: 'GameReanimated',
    listener: (logs) => {
      const log = logs[0];

      if (!log) return;

      const {
        args: { attacker, gameId, roundNumber },
      } = log;

      const timestamp = dayjs().format('HH:mm:ss');
      const id = Number(gameId as bigint);

      if (id !== activeGameId) {
        return;
      }

      setGameReanimated({
        timestamp,
        gameId: id,
        round: Number(roundNumber),
        attacker: Number(attacker as bigint),
      });

      setGameEndPending(undefined);
      setHasBeenReanimated(true);
    },
  });

  return gameReanimated;
}
