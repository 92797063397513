import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type ReferralState = {
  referralCode: `0x${string}`;
  setReferralCode: (referralId: ReferralState['referralCode']) => void;
};

export const useReferralStore = create<ReferralState>()(
  devtools(
    persist(
      (set) => ({
        referralCode: '0x000000000000000',
        setReferralCode: (referralCode) => set({ referralCode }),
      }),
      {
        name: 'referral-storage',
        version: 1,
      }
    ),
    {
      name: 'referral-store',
    }
  )
);
