'use client';

import * as React from 'react';
import { useAnimate, stagger, AnimatePresence } from 'framer-motion';
import { chain, round } from 'mathjs';
import { LuHelpCircle } from 'react-icons/lu';

import { Tooltip } from '@/ui';
import { cx } from '@/core/utils';
import { useGetCardData } from '@/core/hooks';
import { ActionCallout } from '@/play/components/CalloutActionHelper/ActionCallout';

import { AttackButton } from './AttackButton';
import { ChooseAttackArenaEffect } from './ChooseAttackArenaEffect';
import { GameSteps } from './Play';
import { PlaceholderAttackButton } from './PlaceholderAttackButton';

import { CardParameters, CardProperty, useArenaStore } from '../store';

const staggerMenuItems = stagger(0.1, { startDelay: 0.2, from: 'first', ease: 'easeOut' });

export function calcProp({ num, effect }: { num: number; effect?: number }) {
  if (!effect) return round(num, 2);

  return round(chain(num).multiply(effect).divide(100).done(), 2);
}

export function calcPropWithOutArenaEffect({ num, effect }: { num: number; effect: number }) {
  return round(chain(num).divide(effect).multiply(100).done(), 2);
}

function useMenuAnimation(isAnimate: boolean, cardParams: CardParameters | undefined) {
  const [scope, animate] = useAnimate();

  React.useEffect(() => {
    cardParams &&
      animate('button', isAnimate ? { x: [450, 0] } : { x: [-450, 0] }, {
        duration: 0.2,
        delay: isAnimate ? staggerMenuItems : 0,
      });
  }, [isAnimate]);

  return scope;
}

type ChooseAttackProps = {
  cardId: number | undefined;
  activeParameter: CardProperty | null;
  currentGameStep: GameSteps;
  setActiveParameter: React.Dispatch<React.SetStateAction<CardProperty | null>>;
  isAttacker: boolean;
};

export const CARD_PROPERTIES: CardProperty[] = [
  { id: 1, prop: 'liquidity' },
  { id: 2, prop: 'degeneracy' },
  { id: 3, prop: 'techKnowHow' },
  { id: 4, prop: 'influence' },
  { id: 5, prop: 'shitposter' },
  { id: 6, prop: 'alpha' },
];

const t = {
  title: '//: Attack Properties',
  tooltip:
    "Select a property to attack your opponent. The card with the higher strength wins, and the winner adds the opponent's card to their deck.",
};

export function ChooseAttack(props: ChooseAttackProps) {
  const { activeParameter, setActiveParameter, currentGameStep, cardId, isAttacker } = props;
  const [isAnimate] = React.useState(true);
  const [activeCard, setActiveCard] = React.useState<number>();

  const {
    effect,
    cardHealth,
    arenaEffect,
    enduranceLoss,
    tokenProperties,
    refetch: refetchCard,
  } = useGetCardData({
    nftId: activeCard,
  });

  const { activeBooster, shouldHideProperties } = useArenaStore();
  const [safeEffect, setSafeEffect] = React.useState<number>(100);

  React.useEffect(() => {
    if (effect) {
      setSafeEffect(effect);
    }
  }, [effect]);

  React.useEffect(() => {
    if (
      currentGameStep === 'choose attack' ||
      currentGameStep === 'complete turn' ||
      currentGameStep === 'you lose' ||
      currentGameStep === 'enemy turn'
    ) {
      setActiveCard(cardId);
      refetchCard();
    }
  }, [cardId, currentGameStep]);

  const liveCardParams: CardParameters | null = React.useMemo(() => {
    if (!activeCard) return null;

    if (!cardHealth) return null;

    if (!tokenProperties) return null;

    if (!arenaEffect) return null;

    if (!enduranceLoss) return null;

    const parameters: CardParameters = {
      liquidity: {
        currentValue: cardHealth[0] as number,
        enduranceLossImpact: enduranceLoss[0] as number,
        arenaEffectImpact: arenaEffect[0] as number,
      },
      degeneracy: {
        currentValue: cardHealth[1] as number,
        enduranceLossImpact: enduranceLoss[1] as number,
        arenaEffectImpact: arenaEffect[1] as number,
      },
      techKnowHow: {
        currentValue: cardHealth[2] as number,
        enduranceLossImpact: enduranceLoss[2] as number,
        arenaEffectImpact: arenaEffect[2] as number,
      },
      influence: {
        currentValue: cardHealth[3] as number,
        enduranceLossImpact: enduranceLoss[3] as number,
        arenaEffectImpact: arenaEffect[3] as number,
      },
      shitposter: {
        currentValue: cardHealth[4] as number,
        enduranceLossImpact: enduranceLoss[4] as number,
        arenaEffectImpact: arenaEffect[4] as number,
      },
      alpha: {
        currentValue: cardHealth[5] as number,
        enduranceLossImpact: enduranceLoss[5] as number,
        arenaEffectImpact: arenaEffect[5] as number,
      },
    };

    return parameters;
  }, [
    safeEffect,
    activeCard,
    cardHealth,
    arenaEffect,
    enduranceLoss,
    tokenProperties,
    activeBooster,
  ]);

  const scope = useMenuAnimation(isAnimate, liveCardParams ?? undefined);

  return (
    <div>
      {/* title */}
      <div className={cx('relative')}>
        <TitleBg currentGameStep={currentGameStep} />
        <div
          className={cx('absolute bottom-0 z-10 flex w-full items-baseline gap-5 pb-[3.5%] pl-4')}
        >
          <AttackPropertiesIcon />
          <span
            className={cx(
              'inline-block translate-y-0.5 text-[0.625rem] text-green text-glow-green',
              'xl:tall:text-xs',
              '2xl:translate-y-0'
            )}
          >
            {t.title}
          </span>
          <Tooltip content={t.tooltip} delayDuration={300}>
            <div className={cx('absolute bottom-0 right-4 pb-[3.5%]')}>
              <LuHelpCircle className={cx('h-4 w-4 text-neutral-500', 'hover:text-white')} />
            </div>
          </Tooltip>
        </div>
      </div>
      {/* body */}
      <div className={cx('relative z-50 -mt-0.5 mb-2.5 h-full', '2xl:mb-5')} ref={scope}>
        <div className={cx('absolute grid h-full w-full grid-rows-6 p-0.5', 'lg:p-1', '2xl:p-3')}>
          <AnimatePresence>
            {liveCardParams &&
              CARD_PROPERTIES.map((value: CardProperty, index: number) => (
                <>
                  {shouldHideProperties ? (
                    <PlaceholderAttackButton key={index} parameter={value} />
                  ) : (
                    <AttackButton
                      setActiveParameter={setActiveParameter}
                      parameter={value}
                      cardParams={liveCardParams}
                      activeParameter={activeParameter}
                      arenaEffect={activeBooster === 2 ? 100 : safeEffect}
                      currentGameStep={currentGameStep}
                      tokenProperties={tokenProperties || null}
                      key={index}
                      activeBooster={activeBooster}
                      isDisabled={!isAttacker && activeParameter?.id !== value.id}
                    />
                  )}
                </>
              ))}
          </AnimatePresence>
          <ChooseAttackArenaEffect arenaEffect={safeEffect} currentGameStep={currentGameStep} />
        </div>
        <ContentBg currentGameStep={currentGameStep} />
        <ContentBgTall currentGameStep={currentGameStep} />

        {currentGameStep === 'choose attack' && (
          <ActionCallout
            type="CHOOSE_ATTACK"
            className={cx(
              'absolute bottom-0 left-0 -translate-x-full translate-y-[20%]',
              '2xl:translate-y-0'
            )}
          />
        )}
      </div>
    </div>
  );
}

//check border values
function TitleBg(props: { currentGameStep: GameSteps }) {
  const { currentGameStep } = props;

  return (
    <div className="-mt-1">
      <svg
        width="319"
        height="46"
        viewBox="0 0 319 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cx('h-auto w-full')}
      >
        <path
          d="M0.75 44.5455V44.7955H1H318H318.25V44.5455V11.6049V11.3549H318H57.5973L46.814 0.821166L46.7411 0.75H46.6393H1H0.75V1V44.5455Z"
          fill="#3A3838"
          fillOpacity="0.18"
          className={cx(
            currentGameStep === 'choose attack' ||
              currentGameStep === 'attack' ||
              currentGameStep === 'attacking'
              ? 'stroke-yellow'
              : 'stroke-red'
          )}
          strokeWidth="0.5"
        />
        <path
          opacity="0.44"
          d="M51.7385 1H94.095L101.694 9.20385H59.3378L51.7385 1Z"
          className={cx(
            currentGameStep === 'choose attack' ||
              currentGameStep === 'attack' ||
              currentGameStep === 'attacking'
              ? 'fill-yellow'
              : 'fill-red-900'
          )}
        />
        <path
          opacity="0.19"
          d="M98.0844 1.00006H140.45L148.04 9.20391H105.684L98.0844 1.00006Z"
          className={cx(
            currentGameStep === 'choose attack' ||
              currentGameStep === 'attack' ||
              currentGameStep === 'attacking'
              ? 'fill-yellow'
              : 'fill-red-900'
          )}
        />
      </svg>
    </div>
  );
}

//change to Attack Properties Icon
function AttackPropertiesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 26 26"
      className={cx('h-4 w-4', 'xl:h-5 xl:w-5', '2xl:h-6 2xl:w-6')}
    >
      <path
        fill="#FBFBFB"
        d="M22.743 21.031l.08-3.711L26 15.397l-1.792-3.253L26 8.891l-3.177-1.923-.08-3.711-3.711-.08L17.109 0l-3.253 1.792L10.603 0 8.68 3.178l-3.711.079-.129 6.01-.944.944a4.91 4.91 0 00-1.429 3.746 3.367 3.367 0 01-1.1 2.692L0 18.016 7.986 26l1.342-1.342.025-.026a3.368 3.368 0 012.69-1.099h.006a4.91 4.91 0 003.737-1.427l.948-.946 6.01-.129zm-1.492-1.491l-2.959.063.689-.688-.002-.002 2.336-2.336-.064 2.962zM7.09 7.017l-.693.692.064-2.96 3.089-.066 1.599-2.643 2.707 1.491 2.707-1.49 1.6 2.642 3.088.066.066 3.089 2.643 1.6-1.491 2.706 1.49 2.707-2.349 1.422a2.293 2.293 0 00-.89-3.64 2.295 2.295 0 00-1.263-3.065 2.295 2.295 0 00-.475-2.551 2.293 2.293 0 00-2.55-.473 2.293 2.293 0 00-3.754-.79l-1.091 1.093a3.314 3.314 0 00-4.497.17zm10.993 4.142a.768.768 0 011.086 1.086l-3.427 3.427a.769.769 0 01-1.086-1.087l3.427-3.426zm-4.325-2.156l-1.086-1.087 1.084-1.084a.768.768 0 011.086 1.086l-1.084 1.085zm3.063-.909a.768.768 0 011.086 1.086l-1.986 1.986-1.086-1.086 1.986-1.986zm2.524 6.13a.767.767 0 011.084 1.083l-2.527 2.528a.766.766 0 11-1.082-1.086l2.525-2.526zm-7.215 7.788h-.007a4.884 4.884 0 00-3.884 1.581l-.253.253-5.831-5.83.252-.253a4.884 4.884 0 001.58-3.892 3.384 3.384 0 01.986-2.583l3.194-3.194a1.79 1.79 0 012.528 0l4.088 4.088a3.327 3.327 0 01-4.094-.477L9.475 10.49l-.976.977c1.07 1.965.858 4.074-.623 5.58l-.859.858 1.077 1.078.857-.857a5.946 5.946 0 001.733-4.535 4.85 4.85 0 002.422.607 2.295 2.295 0 00.473 2.55c.415.416.952.638 1.497.667a2.282 2.282 0 001.303 1.946l-1.67 1.668a3.385 3.385 0 01-2.58.983z"
      ></path>
    </svg>
  );
}

function ContentBg(props: { currentGameStep: GameSteps }) {
  const { currentGameStep } = props;

  return (
    <svg
      width="278"
      height="157"
      viewBox="0 0 278 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('h-auto w-full', 'tall:hidden')}
    >
      <g filter="url(#filter0_i_27_33637)">
        <rect
          x="1"
          y="0.768066"
          width="276.278"
          height="155.502"
          fill="#3A3838"
          fillOpacity="0.18"
        />
        <rect
          x="0.755969"
          y="0.524036"
          width="276.766"
          height="155.99"
          strokeWidth="0.488061"
          className={cx(
            currentGameStep === 'choose attack' ||
              currentGameStep === 'attack' ||
              currentGameStep === 'attacking'
              ? 'stroke-yellow'
              : 'stroke-red'
          )}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_27_33637"
          x="0.511719"
          y="0.280029"
          width="277.254"
          height="159.964"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.48615" />
          <feGaussianBlur stdDeviation="1.74308" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_27_33637" />
        </filter>
      </defs>
    </svg>
  );
}

function ContentBgTall(props: { currentGameStep: GameSteps }) {
  const { currentGameStep } = props;

  return (
    <svg
      width="278"
      height="220"
      viewBox="0 0 278 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('hidden h-auto w-full', 'tall:block')}
    >
      <g filter="url(#filter0_i_29_53445)">
        <rect
          x="1"
          y="1.05469"
          width="276.278"
          height="218.252"
          fill="#3A3838"
          fillOpacity="0.18"
        />
        <rect
          x="0.755969"
          y="0.810657"
          width="276.766"
          height="218.74"
          stroke="#E8BB28"
          strokeWidth="0.488061"
          className={cx(
            currentGameStep === 'choose attack' ||
              currentGameStep === 'attack' ||
              currentGameStep === 'attacking'
              ? 'stroke-yellow'
              : 'stroke-red'
          )}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_29_53445"
          x="0.511963"
          y="0.56665"
          width="277.254"
          height="222.715"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.48615" />
          <feGaussianBlur stdDeviation="1.74308" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_29_53445" />
        </filter>
      </defs>
    </svg>
  );
}
