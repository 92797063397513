'use client';

import { useAccount } from 'wagmi';
import { useEffect } from 'react';

import { useArenaStore, useGameSecretsStore } from '@/game';

import { usePlayerOneTopCard } from './web3/queries/usePlayerOneTopCard';
import { usePlayerData } from './web3/queries/usePlayerData';
import { usePlayerTwoTopCard } from './web3/queries/usePlayerTwoTopCard';

type UsePlayerProps = {
  gameId: number;
  shouldWatch?: boolean;
};

export function usePlayer(props: UsePlayerProps) {
  const { gameId, shouldWatch = false } = props;
  const { address } = useAccount();
  const secret = useGameSecretsStore((state) => state.secret2);
  const { setPlayerId } = useArenaStore();

  const {
    playerOneData: { hand: playerOneHand, address: playerOne },
    playerTwoData: { hand: playerTwoHand, address: playerTwo },

    refetch: refetchPlayer,
  } = usePlayerData({
    gameId,
    secret,
    shouldWatch,
  });

  useEffect(() => {
    if (playerOne === address) {
      setPlayerId(1);
    } else if (playerTwo === address) {
      setPlayerId(2);
    }
  }, [playerOne, playerTwo, address]);

  const { refetch: refetchPlayerOneTopCard, playerOneTopCard } = usePlayerOneTopCard({
    gameId,
    secret,
  });

  const { refetch: refetchPlayerTwoTopCard, playerTwoTopCard } = usePlayerTwoTopCard({
    gameId,
    secret,
  });

  return {
    playerOne,
    playerOneTopCard,
    playerOneHand,
    playerTwo,
    playerTwoTopCard,
    playerTwoHand,
    refetchPlayer,
    refetchPlayerOneTopCard,
    refetchPlayerTwoTopCard,
  };
}
