type ArenaShuffleFrameProps = {
  children: React.ReactNode;
  className?: string;
};

export function ArenaShuffleFrame(props: ArenaShuffleFrameProps) {
  const { children, className } = props;

  return (
    <svg
      width="445"
      height="305"
      viewBox="0 0 445 305"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <mask id="image_mask" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse">
        <path
          d="M387.872 300H5.12158V57.1021C25.3785 36.8451 36.7333 25.4903 56.9902 5.2334H439.741V248.132C419.484 268.388 408.129 279.743 387.872 300Z"
          fill="white"
          shapeRendering="crispEdges"
        />
      </mask>
      <foreignObject x="0" y="0" width="445" height="305" mask="url(#image_mask)">
        {children}
      </foreignObject>
      <g filter="url(#filter0_b_74_18329)">
        <rect
          width="434.74"
          height="295"
          transform="translate(5 5)"
          fill="url(#paint0_linear_74_18329)"
          fillOpacity="0.18"
        />
        <g filter="url(#filter1_d_74_18329)">
          <path
            d="M387.872 300H5.12158V57.1021C25.3785 36.8451 36.7333 25.4903 56.9902 5.2334H439.741V248.132C419.484 268.388 408.129 279.743 387.872 300Z"
            stroke="#CC2733"
            strokeWidth="0.804165"
            strokeMiterlimit="10"
            shapeRendering="crispEdges"
          />
        </g>
        <rect x="85" y="21" width="273.582" height="264.851" fill="url(#pattern0)" />
      </g>
      <defs>
        <filter
          id="filter0_b_74_18329"
          x="-41.1854"
          y="-41.0738"
          width="527.233"
          height="387.381"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="22.9524" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_74_18329" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_74_18329"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_74_18329"
          x="0.719482"
          y="0.831055"
          width="443.423"
          height="303.571"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0.55 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_74_18329" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_74_18329"
            result="shape"
          />
        </filter>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image0_74_18329"
            transform="matrix(0.00124696 0 0 0.00128535 -0.285616 0)"
          />
        </pattern>
      </defs>
    </svg>
  );
}
