'use client';

import * as RadixTooltip from '@radix-ui/react-tooltip';
import { PropsWithChildren, ReactNode } from 'react';

import { useSettingsStore } from '@/play/store';
import { cx } from '@/core/utils';

export type TooltipProps = PropsWithChildren<{
  /** The content of the tooltip. */
  content: ReactNode;
  delayDuration?: number;
}>;

export function Tooltip(props: TooltipProps) {
  const { delayDuration = 700 } = props;
  const { isTooltip } = useSettingsStore();

  if (!isTooltip) return <>{props.children}</>;

  return (
    <RadixTooltip.Provider delayDuration={delayDuration}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{props.children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={cx(
              'z-50 max-w-[220px] select-none whitespace-pre-line rounded-lg bg-gray-950 p-3 font-display text-xs text-white',
              'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]',
              'data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade'
            )}
            sideOffset={5}
          >
            {props.content}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
