'use client';

import { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

export type UseUnusedBoostersProps = {
  gameId: number;
  playerId: number;
  isEnabled?: boolean;
};

export function useUnusedBoosters(props: UseUnusedBoostersProps) {
  const { gameId, playerId, isEnabled = false } = props;
  const [unusedBoosters, setUnusedBoosters] = useState<number[]>();

  const { data, isError, isLoading, refetch } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'getUnusedBoosters',
    args: [BigInt(playerId), BigInt(gameId)],
    enabled: isEnabled,
  });

  useEffect(() => {
    if (data) {
      setUnusedBoosters(data.map(Number));
    }
  }, [data]);

  return {
    isError,
    isLoading,
    unusedBoosters,
    refetch,
  };
}
