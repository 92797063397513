export function PlayDisplayNote() {
  return (
    <div className="play-display-note absolute inset-0">
      <div className="flex h-full flex-col items-center justify-center">
        <div className="text-4xl font-bold text-white">{`Sorry!`}</div>
        <div className="text-2xl font-bold text-white">
          {`The game only supports screen resolutions of 1024x600px and above. Smaller screen sizes are currently not supported, but will be in the future.`}
        </div>
      </div>
    </div>
  );
}
