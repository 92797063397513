'use client';

import * as React from 'react';
import Image from 'next/image';
import { useLottie, type LottieOptions } from 'lottie-react';
import { motion, useMotionValue, useTransform, animate } from 'framer-motion';
import { useElementSize } from 'usehooks-ts';

import { useAccount } from '@/core/hooks/useAccount';
import playerCardBg from '@/assets/images/player-cards/player-card-bg.png';
import { usePlayEventsStore } from '@/play/store';
import { imageUrl } from '@/core/lib';
import { cx } from '@/core/utils';
import { useAnimation } from '@/systems/core/hooks/useAnimation';
import { useAttackValueStore } from '@/systems/play/store';

import { CARD_TYPES, GameSteps } from './Play';
import { ActiveHealthBooster } from './ActiveHealthBooster';
import { ActiveArenaBooster } from './ActiveArenaBooster';
import { ActiveShieldBooster } from './ActiveShieldBooster';

import { CardProperty, useArenaStore } from '../store';

type ActiveCardsProps = {
  activeParameter: CardProperty | null;
  currentGameStep: GameSteps;
  activeCardType: CARD_TYPES;
  playerTopCardId: number | null;
  opponentTopCardId: number | null;
  isAttacker: boolean;
  setActiveCardType: (cardType: CARD_TYPES) => void;
};

export function ActiveCards(props: ActiveCardsProps) {
  const { activeParameter, activeCardType, currentGameStep, playerTopCardId, opponentTopCardId } =
    props;

  const {
    arenaAnimation,
    attackAnimations,
    attackTargetAnimation,
    cardDestroyAnimations,
    cardRotateAnimations,
    healthAnimation,
    shieldAnimation,
    shuffleAnimation,
  } = useAnimation();

  const [isCardDestroyAnimated, setIsCardDestroyAnimated] = React.useState(false);
  const [isTargetAttackAnimated, setIsTargetAttackAnimated] = React.useState(false);
  const [isCardVisible, setIsCardVisible] = React.useState(false);
  const [isShuffle, setIsShuffle] = React.useState(false);
  const [isHealthAnimated, setIsHealthAnimated] = React.useState(false);
  const [isShieldAnimated, setIsShieldAnimated] = React.useState(false);
  const [isArenaAnimated, setIsArenaAnimated] = React.useState(false);
  const [isHealthBooster, setIsHealthBooster] = React.useState(false);
  const [isShieldBooster, setIsShieldBooster] = React.useState(false);
  const [isArenaBooster, setIsArenaBooster] = React.useState(false);
  const [isOpponentShieldAnimation, setIsOpponentShieldAnimation] = React.useState(false);
  const [isOpponentHealthAnimation, setIsOpponentHealthAnimation] = React.useState(false);
  const [isOpponentArenaAnimation, setIsOpponentArenaAnimation] = React.useState(false);
  const [isOpponentHealthBooster, setIsOpponentHealthBooster] = React.useState(false);
  const [isOpponentShieldBooster, setIsOpponentShieldBooster] = React.useState(false);
  const [isOpponentArenaBooster, setIsOpponentArenaBooster] = React.useState(false);

  const [cardContainerRef, { width, height }] = useElementSize();

  const x = useMotionValue(200);
  const y = useMotionValue(200);

  const rotateX = useTransform(y, [0, width], [20, -20]);
  const rotateY = useTransform(x, [0, height], [-20, 20]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleMouse(event: any) {
    const rect = event.currentTarget.getBoundingClientRect();

    x.set(event.clientX - rect.left);
    y.set(event.clientY - rect.top);
  }

  const { activeBooster, incrementCurrentUiRound, setShouldHideProperties } = useArenaStore(
    (state) => ({
      playerId: state.playerId,
      activeBooster: state.activeBooster,
      incrementCurrentUiRound: state.incrementCurrentUiRound,
      setShouldHideProperties: state.setShouldHideProperties,
    })
  );
  const { handPlayed } = usePlayEventsStore((state) => ({
    handPlayed: state.handPlayed,
  }));
  const { address } = useAccount();

  React.useEffect(() => {
    if (isShuffle) {
      setShouldHideProperties(true);

      return;
    }

    setShouldHideProperties(false);
  }, [isShuffle]);

  const calloutsOptions = {
    animationData: activeParameter && attackAnimations[activeParameter.prop],
    loop: false,
  };

  const { setPlayerCardValue, getOpponentValues, getPlayerValues, deleteAttackValues } =
    useAttackValueStore();

  const attackTargetOptions: LottieOptions = {
    animationData: isTargetAttackAnimated && attackTargetAnimation,
    loop: false,
    onComplete: () => {
      setIsTargetAttackAnimated(false);
      setIsCardVisible(false);
      setIsCardDestroyAnimated(true);
      destroyAttackAnimation();
    },
  };

  const cardRotateOptions = {
    animationData:
      (currentGameStep === 'complete turn' || currentGameStep === 'you win') &&
      cardRotateAnimations[activeCardType],
    loop: false,
    onComplete: () => {
      setIsCardVisible(true);
    },
  };

  const cardDestroyOptions: LottieOptions = {
    animationData: isCardDestroyAnimated && cardDestroyAnimations[activeCardType],
    loop: false,
    onComplete: () => {
      setIsCardDestroyAnimated(false);
      setIsShuffle(true);
      setIsCardVisible(false);

      destroyCardDestroyAnimation();
    },
  };

  const shieldOptions: LottieOptions = {
    animationData: (isOpponentShieldAnimation || isShieldAnimated) && shieldAnimation,
    loop: false,
    onComplete: () => {
      if (isShieldAnimated) {
        setIsShieldBooster(true);
        setIsHealthBooster(false);
        setIsArenaBooster(false);
        setIsShieldAnimated(false);
      }

      setIsOpponentShieldAnimation(false);
    },
  };

  const healthOptions: LottieOptions = {
    animationData: (isOpponentHealthAnimation || isHealthAnimated) && healthAnimation,
    loop: false,
    onComplete: () => {
      if (isHealthAnimated) {
        setIsHealthBooster(true);
        setIsShieldBooster(false);
        setIsArenaBooster(false);
        setIsHealthAnimated(false);
      }

      setIsOpponentHealthAnimation(false);
    },
  };

  const arenaOptions: LottieOptions = {
    animationData: (isOpponentArenaAnimation || isArenaAnimated) && arenaAnimation,
    loop: false,
    onComplete: () => {
      if (isArenaAnimated) {
        setIsArenaBooster(true);
        setIsShieldBooster(false);
        setIsHealthBooster(false);
        setIsArenaAnimated(false);
      }

      setIsOpponentArenaAnimation(false);
    },
  };

  const shuffleOptions: LottieOptions = {
    animationData: isShuffle && shuffleAnimation,
    loop: false,

    onComplete: () => {
      setIsShuffle(false);
      incrementCurrentUiRound();
      deleteAttackValues();
      if (playerTopCardId !== null) {
        setPlayerCardValue(playerTopCardId);
      }
    },
  };

  const { View: ShieldView } = useLottie(shieldOptions);
  const { View: HealthView } = useLottie(healthOptions);
  const { View: ArenaView } = useLottie(arenaOptions);
  const { View: CardRotateView } = useLottie(cardRotateOptions);
  const { View: CalloutsView } = useLottie(calloutsOptions);
  const { View: ShuffleView } = useLottie(shuffleOptions);
  const { View: AttackTargetView, destroy: destroyAttackAnimation } =
    useLottie(attackTargetOptions);
  const { View: CardDestroyView, destroy: destroyCardDestroyAnimation } =
    useLottie(cardDestroyOptions);

  React.useEffect(() => {
    if (currentGameStep === 'you win' || currentGameStep === 'you lose') {
      setIsTargetAttackAnimated(true);
      setIsCardVisible(true);
    }
  }, [currentGameStep]);

  React.useEffect(() => {
    if (!activeBooster) {
      setIsHealthBooster(false);
      setIsArenaBooster(false);
      setIsShieldBooster(false);
    }

    let timeout: NodeJS.Timeout | undefined;

    if (activeBooster === 1) {
      setIsArenaAnimated(false);
      setIsShieldAnimated(false);
      setIsArenaBooster(false);
      setIsShieldBooster(false);

      setTimeout(() => {
        setIsHealthAnimated(true);
      }, 1_300);

      return;
    }

    if (activeBooster === 2) {
      setIsHealthAnimated(false);
      setIsShieldAnimated(false);
      setIsHealthBooster(false);
      setIsShieldBooster(false);

      setTimeout(() => {
        setIsArenaAnimated(true);
      }, 1_300);

      return;
    }

    if (activeBooster === 3) {
      setIsHealthAnimated(false);
      setIsArenaAnimated(false);
      setIsHealthBooster(false);
      setIsArenaBooster(false);

      setTimeout(() => {
        setIsShieldAnimated(true);
      }, 1_300);

      return;
    }

    clearTimeout(timeout);
  }, [activeBooster]);

  React.useEffect(() => {
    if (!handPlayed) {
      return;
    }

    // shield animation
    if (
      handPlayed.boosterId === 3 &&
      handPlayed.player !== address &&
      currentGameStep === 'complete turn'
    ) {
      setIsCardVisible(true);
      setIsOpponentShieldAnimation(true);
      setIsOpponentShieldBooster(true);
    }

    // arena effect animation
    if (
      handPlayed.boosterId === 2 &&
      handPlayed.player !== address &&
      (currentGameStep === 'draw' ||
        currentGameStep === 'you win' ||
        currentGameStep === 'you lose' ||
        currentGameStep === 'complete turn')
    ) {
      setIsCardVisible(true);
      setIsOpponentArenaAnimation(true);
      setIsOpponentArenaBooster(true);
    }

    if (currentGameStep === 'draw') {
      setIsCardVisible(false);
      setIsOpponentShieldAnimation(false);
      setIsShuffle(true);
    }

    // health animation
    if (
      handPlayed.boosterId === 1 &&
      handPlayed.player !== address &&
      (currentGameStep === 'draw' ||
        currentGameStep === 'you win' ||
        currentGameStep === 'you lose' ||
        currentGameStep === 'complete turn')
    ) {
      setIsCardVisible(true);
      setIsOpponentHealthAnimation(true);
      setIsOpponentHealthBooster(true);
    }
  }, [handPlayed, currentGameStep]);

  React.useEffect(() => {
    if (isShuffle) {
      setIsOpponentHealthBooster(false);
      setIsOpponentShieldBooster(false);
      setIsOpponentArenaBooster(false);
      setIsHealthBooster(false);
      setIsShieldBooster(false);
      setIsArenaBooster(false);
    }
  }, [isShuffle]);

  // if (currentGameStep === 'shuffle') {
  //   return (
  //     <div className={cx('relative flex flex-1 items-center justify-center')}>{ShuffleView}</div>
  //   );
  // }

  return (
    <div className="relative z-10 flex h-full items-center justify-center">
      {isShuffle ? (
        <div className={cx('w-full text-center', 'max-2xl:scale-150')}>{ShuffleView}</div>
      ) : (
        <div
          className={cx(
            'relative isolate mt-16 grid grid-cols-5 gap-8 px-[10%]',
            '2xl:max-w-lg tall:px-0'
          )}
        >
          <div
            className={cx(
              'target pointer-events-none absolute inset-x-0 top-1/2 isolate z-50 h-full -translate-y-1/2 touch-none select-none'
            )}
          >
            {/* attack target animation */}
            {isTargetAttackAnimated && (
              <div
                className={cx(
                  'absolute inset-x-0',
                  currentGameStep === 'you win'
                    ? 'origin-center -translate-x-1/3 -translate-y-1/3 scale-75'
                    : 'translate-x-[18%] translate-y-[5%]'
                )}
              >
                {AttackTargetView}
              </div>
            )}

            {/* card destroy animation */}
            {isCardDestroyAnimated && (
              <div
                className={cx(
                  'absolute',
                  currentGameStep === 'you win'
                    ? 'left-0 w-[calc(40%_-_1rem)] -translate-y-[25%] scale-[1.45]'
                    : 'right-0 w-[calc(60%_-_1rem)] -translate-y-[9.25%] scale-[1.1875]'
                )}
              >
                {CardDestroyView}
              </div>
            )}
          </div>

          {/* shield animation */}
          {(isOpponentShieldAnimation || isShieldAnimated) && (
            <div
              className={cx(
                'absolute z-10 aspect-[350/500] [&_svg]:aspect-[350/500]',
                isOpponentShieldAnimation
                  ? 'left-0 w-[calc(40%_-_1rem)] -translate-y-1/4'
                  : 'right-0 top-[2%] w-[calc(60%_-_1rem)] px-[6.666667%]'
              )}
            >
              {ShieldView}
            </div>
          )}

          {/* health animation */}
          {(isOpponentHealthAnimation || isHealthAnimated) && (
            <div
              className={cx(
                'absolute z-10 aspect-[350/500] [&_svg]:aspect-[350/500]',
                isOpponentHealthAnimation
                  ? 'left-0 w-[calc(40%_-_1rem)] -translate-y-1/4'
                  : 'right-0 top-[2%] w-[calc(60%_-_1rem)] px-[6.666667%]'
              )}
            >
              {HealthView}
            </div>
          )}

          {/* arena animation */}
          {(isOpponentArenaAnimation || isArenaAnimated) && (
            <div
              className={cx(
                'absolute z-10 aspect-[350/500] [&_svg]:aspect-[350/500]',
                isOpponentArenaAnimation
                  ? 'left-0 w-[calc(40%_-_1rem)] -translate-y-1/4'
                  : 'right-0 top-[2%] w-[calc(60%_-_1rem)] px-[6.666667%]'
              )}
            >
              {ArenaView}
            </div>
          )}

          {/* opponent card */}
          <div className={cx('relative isolate col-span-2 aspect-[350/500] -translate-y-1/4')}>
            {/* card bg */}
            <Image
              alt="Degentraland"
              src={playerCardBg}
              fill
              className={cx(
                isCardVisible || currentGameStep === 'you win'
                  ? 'z-0 opacity-0'
                  : 'z-10 opacity-100'
              )}
            />

            {/* card */}
            {opponentTopCardId !== null && (
              <Image
                alt="Degentraland"
                src={imageUrl(
                  getOpponentValues().cardID !== 0 ? getOpponentValues().cardID : opponentTopCardId
                )}
                fill
                className={cx(isCardVisible ? 'z-10 opacity-100' : 'z-0 opacity-0')}
              />
            )}

            {/* flip card animation */}
            {(currentGameStep === 'complete turn' || currentGameStep === 'you win') && (
              <div className={cx('absolute inset-0 z-20')}>{CardRotateView}</div>
            )}

            {/* active booster indicator */}
            {(isOpponentHealthBooster || isOpponentArenaBooster || isOpponentShieldBooster) && (
              <div className={cx('pointer-events-none absolute inset-0 -z-10 scale-110')}>
                {isOpponentHealthBooster && <ActiveHealthBooster className={cx('h-auto w-full')} />}
                {isOpponentArenaBooster && <ActiveArenaBooster className={cx('h-auto w-full')} />}
                {isOpponentShieldBooster && <ActiveShieldBooster className={cx('h-auto w-full')} />}
              </div>
            )}
          </div>

          {/* player card */}
          <div className={cx('relative col-span-3')}>
            {playerTopCardId !== null && (
              <motion.div
                ref={cardContainerRef}
                className={cx('aspect-[350/500] w-full px-[10%]')}
                onMouseMove={handleMouse}
                onMouseLeave={() => {
                  animate(x, 200);
                  animate(y, 200);
                }}
              >
                <motion.div style={{ rotateX: rotateX, rotateY: rotateY }}>
                  <Image
                    alt="Degentraland"
                    width={350}
                    height={500}
                    src={imageUrl(
                      getPlayerValues().cardID !== 0 ? getPlayerValues().cardID : playerTopCardId
                    )}
                    className={cx(
                      currentGameStep === 'you lose' && !isTargetAttackAnimated
                        ? 'z-0 opacity-0'
                        : 'z-10 opacity-100'
                    )}
                  />
                </motion.div>
              </motion.div>
            )}

            {/* active booster indicator */}
            {(isHealthBooster || isArenaBooster || isShieldBooster) && (
              <div className={cx('pointer-events-none absolute inset-0 -z-10 scale-105 px-[10%]')}>
                {isHealthBooster && <ActiveHealthBooster className={cx('h-auto w-full')} />}
                {isArenaBooster && <ActiveArenaBooster className={cx('h-auto w-full')} />}
                {isShieldBooster && <ActiveShieldBooster className={cx('h-auto w-full')} />}
              </div>
            )}

            {activeParameter && currentGameStep === 'complete turn' && (
              <div className="absolute -bottom-1/4 left-0 z-50 w-1/3 animate-pulse">
                {CalloutsView}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
