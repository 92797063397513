import { useGetCardData } from '@/systems/core/hooks/web3/listener/useGetCardData';
import {
  CARD_PROPERTIES,
  calcProp,
  calcPropWithOutArenaEffect,
} from '@/systems/game/components/ChooseAttack';
import { CardProperty } from '@/systems/game/store/arena';

type AttackValueProps = {
  cardId: number;
  activeParameter: CardProperty | null;
  booster: number;
};

const HEALTH_BOOSTER = 1;
const ARENA_BOOSTER = 2;

function getBoosterValue(
  booster: number,
  attackValueWithoutArenaEffect: number,
  tokenProperties: number[],
  effect: number | null,
  indexOfProp: number
) {
  switch (booster) {
    case HEALTH_BOOSTER:
      return calcProp({
        num: tokenProperties ? Number(tokenProperties[indexOfProp]) : 0,
        effect: effect || 0,
      });
    case ARENA_BOOSTER:
      return calcProp({
        num: attackValueWithoutArenaEffect,
      });
    default:
      return calcProp({
        num: attackValueWithoutArenaEffect,
        effect: effect || 0,
      });
  }
}

export function useAttackValues(props: AttackValueProps) {
  const { cardId, activeParameter, booster } = props;

  const {
    effect: effect,
    cardHealth: cardHealth,
    tokenProperties: tokenProperties,
  } = useGetCardData({ nftId: cardId });

  if (!activeParameter || !cardHealth || !tokenProperties) {
    return null;
  }

  const indexOfProp =
    (activeParameter
      ? CARD_PROPERTIES.find((prop) => prop.prop === activeParameter.prop)?.id ?? 0
      : 0) - 1;

  const attackValueWithoutArenaEffect = calcPropWithOutArenaEffect({
    num: cardHealth[indexOfProp] || 0,
    effect: effect || 100,
  });

  const attackValue = getBoosterValue(
    booster,
    attackValueWithoutArenaEffect,
    tokenProperties as number[],
    effect as number,
    indexOfProp
  );

  return {
    attackValue: attackValue,
  };
}
