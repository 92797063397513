import { useContractEvent } from 'wagmi';
import dayjs from 'dayjs';

import { useArenaStore, useGameEventsStore } from '@/game';
import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

export function useGameEndPendingEvent() {
  const { activeGameId } = useArenaStore();
  const { gameEndPending, setGameEndPending } = useGameEventsStore();

  useContractEvent({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    eventName: 'GameEndPending',
    listener: (logs) => {
      const log = logs[0];

      if (!log) return;

      const {
        args: { gameId, player1, player2, potentialWinner },
      } = log;

      const timestamp = dayjs().format('HH:mm:ss');

      const id = Number(gameId as bigint);

      console.group('GameEndPending');
      console.log('timestamp', timestamp);
      console.log('player1', player1 as `0x${string}`);
      console.log('player2', player2 as `0x${string}`);
      console.log('gameId', id);
      console.log('potentialWinner', Number(potentialWinner as bigint));
      console.groupEnd();

      if (id !== activeGameId) {
        return;
      }

      console.log('setGameEndPending');

      setGameEndPending({
        timestamp,
        playerOne: player1 as `0x${string}`,
        playerTwo: player2 as `0x${string}`,
        gameId: id,
        potentialWinner: Number(potentialWinner as bigint),
      });
    },
  });

  return gameEndPending;
}
