import * as React from 'react';
import Image from 'next/image';

import { imageUrl } from '@/core/lib';
import { cx } from '@/core/utils';
import { useArenaStore } from '@/game';

export function PreloadedGameCards() {
  const { playerOneHand, playerTwoHand } = useArenaStore((state) => ({
    playerOneHand: state.playerOneHand,
    playerTwoHand: state.playerTwoHand,
  }));

  return (
    <div className={cx('pointer-events-none fixed -z-50 scale-0 touch-none select-none')}>
      {[...playerOneHand, ...playerTwoHand].map((card, i) => (
        <React.Fragment key={i}>
          <Image alt="" src={imageUrl(card)} priority width={350} height={500} />
          <Image alt="" src={imageUrl(card)} priority fill />
        </React.Fragment>
      ))}
    </div>
  );
}
