import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type GameAnimationState = {
  hasSeenGameAnimation: boolean;
  setHasSeenGameAnimation: (hasSeenGameAnimation: boolean) => void;
};

export const useGameAnimationStore = create<GameAnimationState>()(
  devtools(
    persist(
      (set) => ({
        hasSeenGameAnimation: false,
        setHasSeenGameAnimation: (hasSeenGameAnimation: boolean) => {
          set({
            hasSeenGameAnimation,
          });
        },
      }),
      {
        name: 'game-animation-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
