import clsx from 'clsx';

import { useArenaStore } from '@/game';
import { GameMode } from '@/core/hooks';
import { cx } from '@/core/utils';

import { DeckStack } from '../DeckStack';

export type GameSteps =
  | 'attack'
  | 'attacking'
  | 'enemy turn'
  | 'your turn'
  | 'complete turn'
  | 'defence'
  | 'choose attack'
  | 'shuffle'
  | 'you win'
  | 'you lose'
  | 'draw';

type ArenaFrameProps = {
  playerOneAddress: string | null;
  playerOneHand: number[] | null;
  playerTwoHand: number[] | null;
  isAttacker: boolean;
  roundWinner: number | undefined;
  currentGameStep: GameSteps;
  playerOneTopCard: number | null;
  playerTwoTopCard: number | null;
  gameMode: GameMode | undefined;
};

export function ArenaFrame(props: ArenaFrameProps) {
  const {
    playerOneHand,
    playerTwoHand,
    currentGameStep,
    playerOneAddress,
    playerOneTopCard,
    playerTwoTopCard,
    gameMode,
  } = props;

  const { playerId } = useArenaStore();

  const playerOneHandCount = playerOneHand?.length || 0;
  const playerTwoHandCount = playerTwoHand?.length || 0;
  const playerOneDeckStackCount = playerOneHandCount ? playerOneHandCount - 1 : 0;
  const playerTwoDeckStackCount = playerTwoHandCount ? playerTwoHandCount - 1 : 0;
  const isPlayerOne = playerId === 1;

  if (gameMode === GameMode.Hardcore) {
    return null;
  }

  return (
    <div className="" aria-hidden>
      {playerOneAddress && playerOneHand && playerTwoHand && (
        <>
          <div className="w-full">
            <div
              className={clsx(
                'absolute -top-32 left-0 right-28 flex items-center justify-center',
                '2xl:-top-40'
              )}
            >
              <DeckStack
                cardsAmount={!isPlayerOne ? playerOneDeckStackCount : playerTwoDeckStackCount}
                currentGameStep={currentGameStep}
                isPlayerOne={!isPlayerOne}
                playerOneHand={playerOneHand}
                playerTwoHand={playerTwoHand}
                playerOneTopCard={playerOneTopCard}
                playerTwoTopCard={playerTwoTopCard}
                variant="top"
              />
            </div>
          </div>

          <div className="w-full">
            <div className="pointer-events-none absolute bottom-0 flex w-full touch-none select-none justify-center overflow-hidden blur-3xl">
              <div
                className={cx(
                  'h-48 w-[50vw] max-w-3xl translate-y-1/3 rounded-full bg-gradient-to-t from-red-600 to-transparent'
                )}
              ></div>
            </div>
            <div className="absolute bottom-0 left-0 right-28 z-50 flex items-center justify-center overflow-y-clip pt-32">
              <div className="relative -mb-24">
                <DeckStack
                  cardsAmount={isPlayerOne ? playerOneDeckStackCount : playerTwoDeckStackCount}
                  currentGameStep={currentGameStep}
                  isPlayerOne={isPlayerOne}
                  playerOneHand={playerOneHand}
                  playerTwoHand={playerTwoHand}
                  playerOneTopCard={playerOneTopCard}
                  playerTwoTopCard={playerTwoTopCard}
                  variant="bottom"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
