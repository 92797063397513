'use client';

import { Dialog } from '@headlessui/react';

type ModalProps = React.PropsWithChildren<{
  isShow: boolean;
  onClose: () => void;
}>;

export function Modal(props: ModalProps) {
  const { isShow, onClose, children } = props;

  return (
    <Dialog
      className="fixed left-0 top-0 z-20 flex h-full w-screen items-center justify-center bg-black bg-opacity-70"
      open={isShow}
      onClose={onClose}
    >
      <Dialog.Panel className="border border-neutral-800 bg-black p-7">{children}</Dialog.Panel>
    </Dialog>
  );
}
