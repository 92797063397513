'use client';

import { AiOutlineSound } from 'react-icons/ai';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';

import { useSettingsStore } from '@/play/store';
import { ConnectButton } from '@/core/components/Header/includes';
import { cx } from '@/core/utils';

import { PlaySettingsPopover } from './PlaySettingsPopover';
import { SurrenderButton } from './SurrenderButton';

export function PlaySettings() {
  const { isMusic, isSfx, setIsMusic, setIsSfx } = useSettingsStore();

  const handleMusicChange = (isChecked: boolean) => {
    setIsMusic(isChecked);
    setIsSfx(isChecked);
  };

  return (
    <div className={cx('absolute right-0 top-5 z-20 mr-2 flex gap-2', '2xl:top-10')}>
      <div className="h-9">
        <ConnectButton size={'game'} />
      </div>
      <SurrenderButton />
      <Switch
        checked={isMusic || isSfx}
        className={clsx('h-9 w-9 hover:opacity-70', !(isMusic || isSfx) && 'opacity-50')}
        onChange={handleMusicChange}
      >
        <div className="relative h-9 w-9 bg-purple-light">
          <div
            className={clsx(
              'absolute inset-0.5 flex items-center justify-center bg-black opacity-90'
            )}
          >
            <AiOutlineSound className="text-neutral-500" />
          </div>
        </div>
      </Switch>
      <PlaySettingsPopover />
    </div>
  );
}
