import React from 'react';

import { cx } from '@/core/utils';
import { useAttackValues } from '@/play/hooks';
import { useAttackValueStore } from '@/play/store';

import { AttackCalloutSvg } from './AttackValueCalloutSvg';

type AttackCalloutProps = {
  className?: string;
};

export function AttackValueCallout({ className }: AttackCalloutProps) {
  const { getActiveParameter, getOpponentValues } = useAttackValueStore();

  const opponentAttackValue = useAttackValues({
    cardId: getOpponentValues().cardID,
    activeParameter: getActiveParameter(),
    booster: getOpponentValues().boosterId,
  });

  return (
    <div className={cx('absolute', className)}>
      <dl className="absolute h-full w-full">
        <div className="absolute bottom-1 right-0 flex flex-col-reverse">
          <dt className="text-right text-sm font-black text-yellow-thirtiary">
            {getActiveParameter()?.prop ?? ''}
          </dt>
          <dd className="text-right text-base font-black text-yellow-thirtiary">
            {opponentAttackValue?.attackValue.toString() ?? ''}
          </dd>
        </div>
        <dd className="absolute -bottom-5 right-0 text-xs/4 text-[#E0E0E0]">OPPONENTS ATTACK</dd>
      </dl>

      <AttackCalloutSvg />
    </div>
  );
}
