'use client';

import { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_CARD_ABI } from '@/core/lib';

export type UseTokenCategoryProps = {
  ids: number;
};

export function useTokenCategory(props: UseTokenCategoryProps) {
  const { ids } = props;
  const [tokenIds, setTokenIds] = useState<undefined | number[] | number>(undefined);

  const { data, isLoading, isError } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_CARD as `0x${string}`,
    abi: DEGEN_CARD_ABI,
    functionName: 'tokenCategory',
    args: [BigInt(ids ?? 0)],
    staleTime: Infinity,
    enabled: ids !== undefined,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setTokenIds(Number(data));

    return;
  }, [data]);

  return { tokenIds, isLoading, isError };
}
