import { useContractRead } from 'wagmi';

import { DEGEN_CARD_ABI, CONTRACTS, BLAST_SEPOLIA_ID } from '@/core/lib';

type UseBalanceOfProps = {
  address: `0x${string}` | undefined;
};

export function useBalanceOf(props: UseBalanceOfProps) {
  const { address } = props;

  return useContractRead({
    abi: DEGEN_CARD_ABI,
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_CARD,
    functionName: 'balanceOf',
    args: [address as `0x${string}`],
    enabled: !!address,
  });
}
