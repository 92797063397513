import { ReactNode } from 'react';
import clsx from 'clsx';

export type PlayerType = 'winner' | 'looser';

export type GenericUserAvatarProps = {
  avatar: ReactNode;
  name: string;
  isShieldAnimated?: boolean | undefined;
  title?: string;
};

export function GenericUserAvatar(props: GenericUserAvatarProps) {
  const { avatar, name, isShieldAnimated, title } = props;

  return (
    <div className="relative">
      <div className="absolute bottom-11 right-0 flex">
        {isShieldAnimated && <div className="h-6 w-5 skew-y-[45deg] transform bg-blue-300"></div>}
        {isShieldAnimated && (
          <span className="absolute -right-24 top-3 animate-pulse text-blue-300">+1 shield</span>
        )}
      </div>
      <svg
        width="281"
        height="135"
        viewBox="0 0 281 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className="max-w-full"
      >
        <mask
          id="avatar_image_mask"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="116"
          height="130"
        >
          <path
            d="M17.4546 130.947H116.878H116.894V20.3778L98.0151 1.49854H1.39966V116.489L17.4546 130.947Z"
            fill="#CC2733"
          />
        </mask>

        <foreignObject width="116" height="130" mask="url(#avatar_image_mask)">
          {avatar}
        </foreignObject>

        <path
          d="M117.205 131.458H16.4734L0.207275 116.81V0.306274H98.0939L117.222 19.434V131.458H117.205ZM17.1718 129.618H115.365V20.2004L97.3276 2.16283H2.04683V115.992L17.1888 129.618H17.1718Z"
          fill="#CC2733"
        />
        <path d="M116.285 133.74H45.6162V134.66H116.285V133.74Z" fill="#CC2733" />
        <path
          d="M2.02982 38.3914L1.38257 37.7441L4.07376 35.07V1.22607H4.99348V35.4447L2.02982 38.3914Z"
          fill="#CC2733"
        />
        <path d="M116.285 125.564H56.0234L51.2712 130.3L116.285 130.538V125.564Z" fill="#CC2733" />
        <path
          d="M280.207 128.511H115.825V20.0131L108.637 12.8253H252.325L261.233 21.7334V83.2383L280.207 102.213V128.511ZM116.745 127.591H279.287V102.587L260.313 83.6131V22.1081L251.95 13.7451H110.868L116.762 19.6384V127.591H116.745Z"
          fill="#CC2733"
        />
        <path
          d="M262.612 29.5684H258.933V22.6702L251.371 15.1077H245.069V11.4286H252.887L262.612 21.1542V29.5684Z"
          fill="#CC2733"
        />
        <path d="M279.747 126.212H240.163V129.891H279.747V126.212Z" fill="#CC2733" />
        <path d="M231.119 126.212H220.525V129.891H231.119V126.212Z" fill="#CC2733" />
        <path
          d="M262.936 92.3848L256.31 85.7591V62.22H258.15V84.9926L264.23 91.0733L262.936 92.3848Z"
          fill="#CC2733"
        />
        <path
          d="M257.503 115.294H133.045V114.374H256.583V99.5045L251.149 94.0881H134.356V93.1683H251.541L257.503 99.1298V115.294Z"
          fill="#CC2733"
        />
        <path
          d="M257.043 113.131H216.676L215.364 114.442L257.043 114.834V113.131Z"
          fill="#CC2733"
        />
        <path d="M164.965 90.8008H134.544V92.6403H164.965V90.8008Z" fill="#CC2733" />
        <path
          d="M255.096 92.4235L253.795 93.7245L256.409 96.3375L257.71 95.0365L255.096 92.4235Z"
          fill="#CC2733"
        />
        <path
          opacity="0.22"
          d="M134.544 94.8035H155.937L140.625 112.739H134.152L134.544 94.8035Z"
          fill="#CC2733"
        />
        <path d="M251.559 29.1426H241.458V32.5151H251.559V29.1426Z" fill="#CC2733" />
        <path d="M251.559 41.849H241.458V45.2214H251.559V41.849Z" fill="#CC2733" />
        <path d="M272.015 107.782H265.491V110.303H272.015V107.782Z" fill="#CC2733" />
        <path d="M272.015 113.573H265.491V116.094H272.015V113.573Z" fill="#CC2733" />
        <path d="M133.045 21.4268H126.521V23.9476H133.045V21.4268Z" fill="#CC2733" />
        {title && (
          <text
            y="60"
            x="133"
            className={clsx(
              'font-display text-xl font-bold uppercase tracking-wider',
              title === 'winner' ? 'fill-yellow' : 'fill-red-900'
            )}
          >
            {title}
          </text>
        )}

        <text
          y={`${title ? '80' : '60'} `}
          x="133"
          className={clsx('uppercase', title ? 'fill-cyan text-sm' : 'fill-white')}
        >
          {name}
        </text>

        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image_avatar_url"
              transform="matrix(0.000244611 0 0 0.000366166 -0.000963497 0)"
            />
          </pattern>

          <image id="image_avatar_url" />
        </defs>
      </svg>
    </div>
  );
}
