'use client';

import { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';
import { convertBNtoNumber } from '@/core/utils';

export function useGameGlobalStats() {
  const [totalWinnings, setTotalWinnings] = useState<number | null>(null);
  const [totalRounds, setTotalRounds] = useState<number | null>(null);
  const [totalGames, setTotalGames] = useState<number | null>(null);

  const totalWinningsResult = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'totalWinnings',
  });

  const totalRoundsResult = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'totalRounds',
  });

  const totalGamesResult = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'totalGames',
  });

  useEffect(() => {
    if (totalWinningsResult.data) {
      setTotalWinnings(convertBNtoNumber(totalWinningsResult.data));
    }

    if (totalRoundsResult.data) {
      setTotalRounds(Number(totalRoundsResult.data));
    }

    if (totalGamesResult.data) {
      setTotalGames(Number(totalGamesResult.data));
    }
  }, [totalWinningsResult, totalWinningsResult, totalGamesResult]);

  return { totalWinnings, totalRounds, totalGames, totalWinningsResult, totalRoundsResult };
}
