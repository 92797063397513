import { useContractWrite, usePrepareContractWrite } from 'wagmi';
import { waitForTransaction } from '@wagmi/core';

import { useArenaStore } from '@/game';
import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_CARD_ABI } from '@/core/lib';

export function useSetApprovalForAll() {
  const isApprove = true;
  const { isSearching } = useArenaStore();

  const { config } = usePrepareContractWrite({
    chainId: BLAST_SEPOLIA_ID,
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_CARD,
    abi: DEGEN_CARD_ABI,
    functionName: 'setApprovalForAll',
    enabled: !isSearching,
    args: [CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS, isApprove],
  });

  const { writeAsync, ...rest } = useContractWrite(config);

  const awaitedTransactionWriteAsync = async () => {
    console.log('awaitedTransactionWriteAsync');
    if (writeAsync) {
      const { hash } = await writeAsync();

      await waitForTransaction({
        hash,
      });
    }
  };

  return {
    writeAsync: awaitedTransactionWriteAsync,
    ...rest,
  };
}
