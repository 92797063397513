import clsx from 'clsx';

import { cx } from '@/core/utils';

import { CardProperty } from '../store';

type PlaceholderAttackProps = {
  parameter: CardProperty;
};

export function PlaceholderAttackButton(props: PlaceholderAttackProps) {
  return (
    <div className={clsx('flex flex-col border-2 border-transparent')}>
      <div className="relative h-full">
        <div className={cx('z-0 flex h-full w-full justify-start bg-cyan-neutral')}>
          <div className={cx('flex w-1/2 bg-cyan-light')}></div>
          <div className={cx('flex', 'w-0.5 bg-cyan')}> </div>
        </div>
        <div className={cx('absolute left-0 top-0 h-full w-full')}>
          <div
            className={cx(
              'mt-[1%] flex items-center justify-between px-2',
              '2xl:px-3',
              'tall:mt-[3%]'
            )}
          >
            <div className="flex items-center">
              <div className={cx('text-left text-xs font-bold text-cyan')}>
                <p>
                  {props.parameter.prop !== 'techKnowHow' ? props.parameter.prop : 'Tech Know-How'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
