'use client';

import { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

export type UsePlayerOneTopCardProps = {
  gameId: number;
  secret: string;
  isEnabled?: boolean;
};

export function usePlayerOneTopCard(props: UsePlayerOneTopCardProps) {
  const { gameId, secret, isEnabled = false } = props;
  const [playerOneTopCard, setPlayerOneTopCard] = useState<number | null>(null);
  const [isGetPlayerOneTopCardError, setIsGetPlayerOneTopCardError] = useState<boolean>();
  const [isGetPlayerOneTopCardLoading, setIsGetPlayerOneTopCardLoading] = useState<boolean>();

  const { data, isError, isLoading, refetch } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'getPlayerOneTopCard',
    enabled: isEnabled,
    args: [BigInt(gameId), BigInt(secret)],
  });

  useEffect(() => {
    isError && setIsGetPlayerOneTopCardError(isError);
    isLoading && setIsGetPlayerOneTopCardLoading(isLoading);
    if (data) {
      setPlayerOneTopCard(Number(data as bigint));
    }
  }, [data, isError, isLoading]);

  return {
    isGetPlayerOneTopCardError,
    isGetPlayerOneTopCardLoading,
    playerOneTopCard,
    refetch,
  };
}
