import Image from 'next/image';
import clsx from 'clsx';

import AvatarOne from '@/assets/images/avatars/avatar-1.svg';
import AvatarTwo from '@/assets/images/avatars/avatar-2.svg';
import AvatarThree from '@/assets/images/avatars/avatar-3.svg';
import AvatarFour from '@/assets/images/avatars/avatar-4.svg';
import AvatarFive from '@/assets/images/avatars/avatar-5.svg';
import AvatarSix from '@/assets/images/avatars/avatar-6.svg';
import AvatarSeven from '@/assets/images/avatars/avatar-7.svg';
import AvatarEight from '@/assets/images/avatars/avatar-8.svg';
import AvatarNine from '@/assets/images/avatars/avatar-9.svg';

type AvatarProps = {
  address: string | undefined;
};

const avatars = [
  AvatarOne,
  AvatarTwo,
  AvatarThree,
  AvatarFour,
  AvatarFive,
  AvatarSix,
  AvatarSeven,
  AvatarEight,
  AvatarNine,
];

const addressToIndex = (address: string) => {
  let hash = 0;

  for (let i = 0; i < address.length; i++) {
    const charCode = address.charCodeAt(i);

    hash += charCode;
  }

  return hash % avatars.length;
};

export function Avatar(props: AvatarProps) {
  const { address = '' } = props;

  return (
    <div
      className={clsx(
        'aspect-[116/130] clip-user-avatar [&>div]:absolute [&>div]:inset-0 [&>div]:!h-full [&>div]:!w-full'
      )}
    >
      {/* <Avvvatars style="shape" value={address} size={116} radius={1} /> */}
      <Image alt={'avatar'} src={avatars[addressToIndex(address)]} />
    </div>
  );
}
