import { useContractWrite, usePrepareContractWrite } from 'wagmi';
import * as web3Utils from 'web3-utils';

import { useArenaStore, useGameSecretsStore } from '@/game';
import { GameSteps } from '@/play';
import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

export type UseAttackProps = {
  gameId: number;
  boosterId: number;
  propId: number;
  value: number;
  currentGameStep: GameSteps;
};

export function useAttack(props: UseAttackProps) {
  const { gameId, boosterId, propId, value, currentGameStep } = props;
  const gameSecrets = useGameSecretsStore();
  const { isExpired } = useArenaStore();

  const secret_ = gameSecrets.secret2;
  const hash3_ = web3Utils.soliditySha3({ t: 'uint256', v: gameSecrets.secret4 });

  const { config, refetch } = usePrepareContractWrite({
    chainId: BLAST_SEPOLIA_ID,
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'attack',
    args: [
      BigInt(gameId),
      BigInt(secret_),
      BigInt(gameSecrets.randomString),
      BigInt(hash3_ as string),
      BigInt(boosterId),
      BigInt(propId),
    ],
    enabled: !isExpired && (currentGameStep === 'choose attack' || currentGameStep === 'attack'),
    value: BigInt(value),
  });

  return {
    ...useContractWrite({
      ...config,
      request: {
        ...config.request,
      },
    }),
    refetch,
  };
}
