import * as React from 'react';
import { useContractEvent } from 'wagmi';
import dayjs from 'dayjs';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';
import { PlayEventsState } from '@/play/store';

export type UseHandPlayedEventProps = {
  currentGameId: number;
};

export function useHandPlayedEvent(props: UseHandPlayedEventProps) {
  const { currentGameId } = props;
  const [handPlayed, setHandPlayed] = React.useState<PlayEventsState['handPlayed']>();

  useContractEvent({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    eventName: 'HandPlayed',
    listener: (logs) => {
      const log = logs[0];

      if (!log) return;

      const {
        args: {
          attackerProp,
          boosterId,
          gameId,
          isAttacker,
          order,
          player,
          playerCard,
          roundNumber,
        },
      } = log;

      const id = Number(gameId as bigint);
      const timestamp = dayjs().format('HH:mm:ss');

      console.group('HandPlayed');
      console.log('timestamp', timestamp);
      console.log('player', player);
      console.log('id', id);
      console.log('currentGameId', currentGameId);
      console.log('roundNumber', Number(roundNumber as bigint));
      console.log('playerCard', Number(playerCard as bigint));
      console.log('order', Number(order as bigint));
      console.log('attackerProp', Number(attackerProp as bigint));
      console.log('boosterId', Number(boosterId as bigint));
      console.log('isAttacker', isAttacker as boolean);
      console.groupEnd();

      if (id !== currentGameId) {
        return;
      }

      console.log('setHandPlayed');

      setHandPlayed({
        timestamp,
        player: player as `0x${string}`,
        roundNumber: Number(roundNumber as bigint),
        playerCard: Number(playerCard as bigint),
        order: Number(order as bigint),
        attackerProp: Number(attackerProp as bigint),
        boosterId: Number(boosterId as bigint),
        isAttacker: isAttacker as boolean,
      });
    },
  });

  return handPlayed;
}
