import * as React from 'react';
import { LuHelpCircle } from 'react-icons/lu';

import { cx } from '@/core/utils';
import {
  Tooltip,
  ApesBoostIcon,
  BearBoostIcon,
  BuilderBoostIcon,
  ChadBoostIcon,
  DegenBoostIcon,
  // PlebBoostIcon,
} from '@/ui/components';
import { GameMode, useGetCardData } from '@/core/hooks';
import { GameSteps, useArenaStore } from '@/game';

type ArenaEffectProps = {
  arenaId: number | undefined;
  cardId: number | undefined;
  currentGameStep: GameSteps;
  gameMode: GameMode | undefined;
};

const t = {
  title: 'Arena Effect',
  tooltip:
    'Arenas are randomly chosen after matching players and adjust card properties by ±10% depending on the card category.',
};

type Category = 'Degen' | 'Bear' | 'Builder' | 'Chad' | 'Apes'; // 'Pleb';

const iconProps = {
  className: cx('h-5 text-neutral-500'),
};

const ICONS = {
  Degen: <DegenBoostIcon {...iconProps} />,
  Bear: <BearBoostIcon {...iconProps} />,
  Builder: <BuilderBoostIcon {...iconProps} />,
  Chad: <ChadBoostIcon {...iconProps} />,
  Apes: <ApesBoostIcon {...iconProps} />,
  // Pleb: <PlebBoostIcon {...iconProps} />,
} as const;

type UseArenaEffectType = (arenaId: number | undefined) => {
  boosted: Category;
  weakened: Category;
};
const useArenaEffect: UseArenaEffectType = (arenaId) => {
  switch (arenaId) {
    case 5:
      return {
        boosted: 'Builder',
        weakened: 'Bear',
      };
    case 4:
      return {
        boosted: 'Bear',
        weakened: 'Degen',
      };
    case 3:
      return {
        boosted: 'Chad',
        weakened: 'Apes',
      };
    case 2:
      return {
        boosted: 'Apes',
        weakened: 'Chad',
      };
    case 1:
      return {
        boosted: 'Degen',
        weakened: 'Builder',
      };
    default:
      return {
        boosted: 'Builder',
        weakened: 'Bear',
      };
  }
};

export function ArenaEffect(props: ArenaEffectProps) {
  const { arenaId, cardId, currentGameStep, gameMode } = props;
  const { boosted, weakened } = useArenaEffect(arenaId);

  const [activeCard, setActiveCard] = React.useState<number>();

  const { effect, refetch: refetchCard } = useGetCardData({
    nftId: activeCard,
  });

  const { shouldHideProperties } = useArenaStore();
  const [safeEffect, setSafeEffect] = React.useState<number>(100);

  React.useEffect(() => {
    if (effect) {
      setSafeEffect(effect);
    }
  }, [effect]);

  React.useEffect(() => {
    if (
      currentGameStep === 'choose attack' ||
      currentGameStep === 'complete turn' ||
      currentGameStep === 'you lose' ||
      currentGameStep === 'enemy turn'
    ) {
      setActiveCard(cardId);
      refetchCard();
    }
  }, [cardId, currentGameStep]);

  if (gameMode === GameMode.Hardcore) {
    return null;
  }

  return (
    <div className={cx('my-3', 'tall:my-8')}>
      <div className={cx('flex items-center justify-between')}>
        <h3 className={cx('text-[0.625rem] font-bold leading-none text-white', 'tall:text-xs')}>
          {t.title}
        </h3>

        <Tooltip content={t.tooltip} delayDuration={300}>
          <div className={cx('mr-4')}>
            <LuHelpCircle className={cx('h-4 w-4 text-neutral-500', 'hover:text-white')} />
          </div>
        </Tooltip>
      </div>

      <hr className={cx('my-2 h-px w-full border-none bg-purple-light')} />

      <div className={cx('grid grid-cols-2 gap-2')}>
        {/* boosted */}
        <div
          className={cx(
            'flex items-center justify-between border px-3 py-1 font-display text-xs',
            !shouldHideProperties && safeEffect === 110
              ? 'border-green shadow-green'
              : 'border-purple-light'
          )}
        >
          <div className={cx('flex items-center gap-2')}>
            {ICONS[boosted]}
            <span>{boosted}</span>
          </div>
          {!shouldHideProperties && safeEffect === 110 ? (
            <strong className={cx('text-green glow-green')}>{`+10%`}</strong>
          ) : (
            <strong className={cx('text-neutral-600')}>{`--`}</strong>
          )}
        </div>

        {/* weakened */}
        <div
          className={cx(
            'flex items-center justify-between border px-3 py-1 font-display text-xs',
            !shouldHideProperties && safeEffect === 90
              ? 'border-red shadow-red'
              : 'border-purple-light'
          )}
        >
          <div className={cx('flex items-center gap-2')}>
            {ICONS[weakened]}
            <span>{weakened}</span>
          </div>
          {!shouldHideProperties && safeEffect === 90 ? (
            <strong className={cx('text-red glow-red')}>{`-10%`}</strong>
          ) : (
            <strong className={cx('text-neutral-600')}>{`--`}</strong>
          )}
        </div>
      </div>
    </div>
  );
}
