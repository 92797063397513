'use client';

import { Popover } from '@headlessui/react';
import { BsGearFill } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';

import { useSettingsStore } from '@/play/store';

import { SettingsSwitchToggle } from './SettingsSwitchToggle';

export function PlaySettingsPopover() {
  const { isArenaBg, isMusic, isSfx, isTooltip, setIsArenaBg, setIsMusic, setIsSfx, setIsTooltip } =
    useSettingsStore();

  return (
    <Popover className="relative">
      <Popover.Button>
        <div className="relative h-9 w-9 bg-purple-light">
          <div className="absolute inset-0.5 flex items-center justify-center bg-black opacity-90">
            <BsGearFill className="text-neutral-500" />
          </div>
        </div>
      </Popover.Button>
      <Popover.Panel className="absolute right-0 z-20 h-60 w-96 border border-neutral-800 bg-black p-8">
        {({ close }) => (
          <div className="flex h-full w-full flex-col">
            <div className="mb-6 flex justify-between">
              <span className="text-lg font-bold text-primary-400">settings</span>
              <button className="mb-0.5 cursor-pointer" onClick={() => close()}>
                <IoMdClose fill="gray" className="h-6 w-6" />
              </button>
            </div>
            <div className="mb-2.5 flex justify-between">
              <span className="text-lg text-neutral-500">Tool Tip</span>
              <SettingsSwitchToggle isEnabled={isTooltip} setEnabled={setIsTooltip} />
            </div>
            <div className="mb-2.5 flex justify-between">
              <span className="text-lg text-neutral-500">Music</span>
              <SettingsSwitchToggle isEnabled={isMusic} setEnabled={setIsMusic} />
            </div>
            <div className="mb-2.5 flex justify-between">
              <span className="text-lg text-neutral-500">Sound Effects</span>
              <SettingsSwitchToggle isEnabled={isSfx} setEnabled={setIsSfx} />
            </div>
            <div className="mb-2.5 flex justify-between">
              <span className="text-lg text-neutral-500">Arena Background</span>
              <SettingsSwitchToggle isEnabled={isArenaBg} setEnabled={setIsArenaBg} />
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}
