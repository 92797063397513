/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import { useIsClient } from 'usehooks-ts';

type UseAudioProps = React.ComponentPropsWithoutRef<'audio'> & { volume?: number };

const audioCache = new Map<string, HTMLAudioElement>();

export function useAudio(props: UseAudioProps) {
  const { src, preload = 'auto', volume = 0.1, ...rest } = props;
  const isClient = useIsClient();
  const [audio, setAudio] = React.useState<HTMLAudioElement | null>(null);

  React.useEffect(() => {
    if (isClient && src) {
      let audio = audioCache.get(src);

      if (!audio) {
        audio = new Audio(src);
        audioCache.set(src, audio);
      }

      Object.assign(audio, { ...rest, preload, volume });
      setAudio(audio);
    }
  }, [isClient, src, preload, volume, rest]);

  return audio;
}
