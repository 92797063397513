import * as React from 'react';
import Link, { LinkProps } from 'next/link';

import { cx } from '@/core/utils';

import { SNAZZY_BUTTON_CLASSES, SnazzySecondaryButtonSvg } from './SnazzyButton';

export type SnazzyLinkButtonProps = React.ComponentPropsWithoutRef<'a'> &
  LinkProps & {
    isExternal?: boolean;
    variant?: 'primary' | 'secondary';
  };

export const SnazzyLinkButton = React.forwardRef<HTMLAnchorElement, SnazzyLinkButtonProps>(
  (props, ref) => {
    const { className, isExternal, variant = 'primary', children, ...rest } = props;

    if (isExternal) {
      return (
        <a
          ref={ref}
          className={cx(SNAZZY_BUTTON_CLASSES({ variant }), className)}
          target="_blank"
          rel="noopener noreferrer"
          {...rest}
        >
          {children}
        </a>
      );
    }

    return (
      <Link ref={ref} className={cx(SNAZZY_BUTTON_CLASSES({ variant }), className)} {...rest}>
        {variant === 'primary' && children}

        {variant === 'secondary' && (
          <SnazzySecondaryButtonSvg>
            <div className="flex h-full w-full items-center justify-center">{children}</div>
          </SnazzySecondaryButtonSvg>
        )}
      </Link>
    );
  }
);

SnazzyLinkButton.displayName = 'SnazzyLinkButton';
