import * as React from 'react';

export const DegenBoostIcon = React.forwardRef<
  SVGSVGElement,
  React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => {
  return (
    <svg
      ref={ref}
      width="39"
      height="35"
      viewBox="0 0 39 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.6197 15.0095C20.6222 14.4913 20.3885 14.25 19.8602 14.2525C18.392 14.2627 16.9238 14.2652 15.4556 14.2525C14.9019 14.2474 14.6504 14.4913 14.6504 15.0349C14.6529 17.0771 14.6529 19.1219 14.6504 21.1642C14.6504 21.7078 14.8942 21.9542 15.4505 21.9492C16.8984 21.9339 18.3463 21.9492 19.7941 21.9415C20.4597 21.9389 20.607 21.7992 20.6146 21.1388C20.6248 20.1278 20.6171 19.1143 20.6146 18.1033C20.6146 17.0721 20.6095 16.0408 20.6146 15.0095H20.6197ZM19.2506 16.8866C18.6409 18.0754 18.0262 19.254 18.1177 20.674H16.6825C16.4463 19.0635 17.3963 18.0271 18.4631 17.0009C18.0491 16.9679 17.7138 16.9628 17.3836 16.912C16.6622 16.8028 16.5453 16.8841 16.4742 17.6055C16.464 17.702 16.4539 17.7985 16.4412 17.9332H15.7147V15.5861C16.0119 15.6369 16.2888 15.6826 16.7206 15.7537C17.0254 15.4057 17.5309 15.4337 18.0694 15.6521C18.4123 15.7918 18.7552 16.1017 19.1108 15.6343C19.1667 15.5607 19.4055 15.6242 19.6925 15.6242C19.53 16.0941 19.4385 16.5158 19.248 16.8866H19.2506Z"
        fill="currentColor"
      />
      <path
        d="M20.6197 15.0095C20.6222 14.4913 20.3885 14.25 19.8602 14.2525C18.392 14.2627 16.9238 14.2652 15.4556 14.2525C14.9019 14.2474 14.6504 14.4913 14.6504 15.0349C14.6529 17.0771 14.6529 19.1219 14.6504 21.1642C14.6504 21.7078 14.8942 21.9542 15.4505 21.9492C16.8984 21.9339 18.3463 21.9492 19.7941 21.9415C20.4597 21.9389 20.607 21.7992 20.6146 21.1388C20.6248 20.1278 20.6171 19.1143 20.6146 18.1033C20.6146 17.0721 20.6095 16.0408 20.6146 15.0095H20.6197ZM19.2506 16.8866C18.6409 18.0754 18.0262 19.254 18.1177 20.674H16.6825C16.4463 19.0635 17.3963 18.0271 18.4631 17.0009C18.0491 16.9679 17.7138 16.9628 17.3836 16.912C16.6622 16.8028 16.5453 16.8841 16.4742 17.6055C16.464 17.702 16.4539 17.7985 16.4412 17.9332H15.7147V15.5861C16.0119 15.6369 16.2888 15.6826 16.7206 15.7537C17.0254 15.4057 17.5309 15.4337 18.0694 15.6521C18.4123 15.7918 18.7552 16.1017 19.1108 15.6343C19.1667 15.5607 19.4055 15.6242 19.6925 15.6242C19.53 16.0941 19.4385 16.5158 19.248 16.8866H19.2506Z"
        fill="currentColor"
      />
      <path
        d="M35.77 0.0457223H34.5457V0H0V34.5457H34.5457V23.6232L38.0562 20.1127V2.33183L35.77 0.0457223ZM31.7592 9.7617C32.4044 9.78202 32.9099 10.3104 32.9048 10.9581C32.8997 11.6363 32.3688 12.157 31.6931 12.1494C31.0225 12.1393 30.4891 11.5855 30.512 10.9225C30.5323 10.257 31.0886 9.74138 31.7617 9.7617H31.7592ZM32.224 16.6404C32.2189 16.8385 31.8811 17.0239 31.6982 17.2144C31.5255 17.0138 31.208 16.8156 31.2004 16.6099C31.1572 15.2077 31.1775 13.8056 31.1775 12.3856H32.2494C32.2494 13.798 32.2697 15.2204 32.224 16.6404ZM27.4257 24.784C20.9154 24.784 14.4025 24.784 7.89217 24.784C6.11408 24.784 5.09549 23.7603 5.09549 21.9721C5.09549 19.371 5.09549 16.7699 5.09549 14.1714C5.09549 12.4568 6.12678 11.4229 7.83375 11.4204C11.1283 11.4179 14.4254 11.4204 17.7199 11.4204C20.956 11.4204 24.1922 11.4204 27.4283 11.4204C29.1683 11.4204 30.1995 12.4517 30.2046 14.2018C30.2122 16.8029 30.2122 19.404 30.2046 22.0026C30.1995 23.7502 29.1632 24.784 27.4283 24.784H27.4257ZM32.9607 20.4328C32.9607 20.7985 32.7397 20.9637 32.3866 20.9662C31.7744 20.9662 31.1623 20.9662 30.5069 20.9662V15.3195C30.5628 15.2763 30.6187 15.2331 30.672 15.1899C30.7533 15.3271 30.8905 15.4567 30.9082 15.6014C30.9438 15.9139 30.9133 16.2339 30.9209 16.5515C30.9362 17.1154 31.2435 17.4761 31.7033 17.4837C32.1605 17.4913 32.4933 17.123 32.5085 16.5743C32.5187 16.1603 32.5085 15.7437 32.6508 15.2458C32.7549 15.4109 32.948 15.5735 32.9505 15.7386C32.9734 17.3033 32.9683 18.8706 32.9632 20.4353L32.9607 20.4328Z"
        fill="currentColor"
      />
      <path
        d="M35.77 0.0457223H34.5457V0H0V34.5457H34.5457V23.6232L38.0562 20.1127V2.33183L35.77 0.0457223ZM31.7592 9.7617C32.4044 9.78202 32.9099 10.3104 32.9048 10.9581C32.8997 11.6363 32.3688 12.157 31.6931 12.1494C31.0225 12.1393 30.4891 11.5855 30.512 10.9225C30.5323 10.257 31.0886 9.74138 31.7617 9.7617H31.7592ZM32.224 16.6404C32.2189 16.8385 31.8811 17.0239 31.6982 17.2144C31.5255 17.0138 31.208 16.8156 31.2004 16.6099C31.1572 15.2077 31.1775 13.8056 31.1775 12.3856H32.2494C32.2494 13.798 32.2697 15.2204 32.224 16.6404ZM27.4257 24.784C20.9154 24.784 14.4025 24.784 7.89217 24.784C6.11408 24.784 5.09549 23.7603 5.09549 21.9721C5.09549 19.371 5.09549 16.7699 5.09549 14.1714C5.09549 12.4568 6.12678 11.4229 7.83375 11.4204C11.1283 11.4179 14.4254 11.4204 17.7199 11.4204C20.956 11.4204 24.1922 11.4204 27.4283 11.4204C29.1683 11.4204 30.1995 12.4517 30.2046 14.2018C30.2122 16.8029 30.2122 19.404 30.2046 22.0026C30.1995 23.7502 29.1632 24.784 27.4283 24.784H27.4257ZM32.9607 20.4328C32.9607 20.7985 32.7397 20.9637 32.3866 20.9662C31.7744 20.9662 31.1623 20.9662 30.5069 20.9662V15.3195C30.5628 15.2763 30.6187 15.2331 30.672 15.1899C30.7533 15.3271 30.8905 15.4567 30.9082 15.6014C30.9438 15.9139 30.9133 16.2339 30.9209 16.5515C30.9362 17.1154 31.2435 17.4761 31.7033 17.4837C32.1605 17.4913 32.4933 17.123 32.5085 16.5743C32.5187 16.1603 32.5085 15.7437 32.6508 15.2458C32.7549 15.4109 32.948 15.5735 32.9505 15.7386C32.9734 17.3033 32.9683 18.8706 32.9632 20.4353L32.9607 20.4328Z"
        fill="currentColor"
      />
      <path
        d="M27.3384 12.8154C20.8662 12.8154 14.3965 12.8154 7.92423 12.8154C6.87516 12.8154 6.49414 13.2041 6.49414 14.2557C6.49414 16.7577 6.49414 19.2572 6.49414 21.7592C6.49414 23.0648 6.80912 23.3874 8.09442 23.3874C11.2696 23.3874 14.4473 23.3874 17.6224 23.3874C20.8789 23.3874 24.1328 23.3874 27.3892 23.3874C28.3951 23.3874 28.7939 22.9835 28.7964 21.9777C28.799 19.4172 28.7964 16.8568 28.7964 14.2963C28.7964 13.2041 28.4154 12.8154 27.3384 12.8154ZM28.3418 21.97C28.3418 22.7448 28.1309 22.9581 27.3536 22.9581C20.8839 22.9581 14.4117 22.9581 7.94201 22.9581C7.15203 22.9581 6.93866 22.7524 6.93866 21.9777C6.93358 19.3969 6.93358 16.8161 6.93866 14.2379C6.93866 13.4505 7.14695 13.2523 7.93185 13.2523C11.168 13.2498 14.4016 13.2523 17.6377 13.2523C20.8535 13.2523 24.0693 13.2523 27.2851 13.2523C28.1538 13.2523 28.3392 13.4352 28.3392 14.2887C28.3392 16.8491 28.3392 19.4096 28.3392 21.97H28.3418Z"
        fill="currentColor"
      />
      <path
        d="M27.3384 12.8154C20.8662 12.8154 14.3965 12.8154 7.92423 12.8154C6.87516 12.8154 6.49414 13.2041 6.49414 14.2557C6.49414 16.7577 6.49414 19.2572 6.49414 21.7592C6.49414 23.0648 6.80912 23.3874 8.09442 23.3874C11.2696 23.3874 14.4473 23.3874 17.6224 23.3874C20.8789 23.3874 24.1328 23.3874 27.3892 23.3874C28.3951 23.3874 28.7939 22.9835 28.7964 21.9777C28.799 19.4172 28.7964 16.8568 28.7964 14.2963C28.7964 13.2041 28.4154 12.8154 27.3384 12.8154ZM28.3418 21.97C28.3418 22.7448 28.1309 22.9581 27.3536 22.9581C20.8839 22.9581 14.4117 22.9581 7.94201 22.9581C7.15203 22.9581 6.93866 22.7524 6.93866 21.9777C6.93358 19.3969 6.93358 16.8161 6.93866 14.2379C6.93866 13.4505 7.14695 13.2523 7.93185 13.2523C11.168 13.2498 14.4016 13.2523 17.6377 13.2523C20.8535 13.2523 24.0693 13.2523 27.2851 13.2523C28.1538 13.2523 28.3392 13.4352 28.3392 14.2887C28.3392 16.8491 28.3392 19.4096 28.3392 21.97H28.3418Z"
        fill="currentColor"
      />
      <path
        d="M26.263 14.2529C24.7745 14.2631 23.286 14.2606 21.7975 14.2529C21.2641 14.2529 21.0532 14.5197 21.0532 15.0302C21.0583 17.075 21.0608 19.1173 21.0532 21.1621C21.0532 21.7133 21.2971 21.9495 21.8483 21.9445C23.2962 21.9318 24.7466 21.9445 26.1944 21.9394C26.8701 21.9394 27.0174 21.7819 27.02 21.0808C27.0251 20.108 27.02 19.1351 27.02 18.1648C27.02 17.1131 27.0174 16.0615 27.02 15.0099C27.02 14.4917 26.7837 14.2504 26.2579 14.2529H26.263ZM25.5289 17.1386C24.9498 18.2359 24.4392 19.3459 24.5256 20.6642H23.1006C22.8186 19.0386 23.8347 18.0149 24.8609 16.9938C24.4672 16.9658 24.1369 16.9633 23.8143 16.915C23.0726 16.8007 22.9329 16.8972 22.8618 17.6313C22.8516 17.7279 22.844 17.8218 22.8313 17.9412H22.1277V15.5561C22.4503 15.6297 22.7272 15.6907 23.098 15.7745C23.3927 15.4417 23.8778 15.4138 24.4011 15.6246C24.7821 15.777 25.153 16.0793 25.5492 15.6246C25.6077 15.5586 25.8109 15.6145 26.1284 15.6145C25.9023 16.1987 25.7652 16.694 25.5289 17.1386Z"
        fill="currentColor"
      />
      <path
        d="M26.263 14.2529C24.7745 14.2631 23.286 14.2606 21.7975 14.2529C21.2641 14.2529 21.0532 14.5197 21.0532 15.0302C21.0583 17.075 21.0608 19.1173 21.0532 21.1621C21.0532 21.7133 21.2971 21.9495 21.8483 21.9445C23.2962 21.9318 24.7466 21.9445 26.1944 21.9394C26.8701 21.9394 27.0174 21.7819 27.02 21.0808C27.0251 20.108 27.02 19.1351 27.02 18.1648C27.02 17.1131 27.0174 16.0615 27.02 15.0099C27.02 14.4917 26.7837 14.2504 26.2579 14.2529H26.263ZM25.5289 17.1386C24.9498 18.2359 24.4392 19.3459 24.5256 20.6642H23.1006C22.8186 19.0386 23.8347 18.0149 24.8609 16.9938C24.4672 16.9658 24.1369 16.9633 23.8143 16.915C23.0726 16.8007 22.9329 16.8972 22.8618 17.6313C22.8516 17.7279 22.844 17.8218 22.8313 17.9412H22.1277V15.5561C22.4503 15.6297 22.7272 15.6907 23.098 15.7745C23.3927 15.4417 23.8778 15.4138 24.4011 15.6246C24.7821 15.777 25.153 16.0793 25.5492 15.6246C25.6077 15.5586 25.8109 15.6145 26.1284 15.6145C25.9023 16.1987 25.7652 16.694 25.5289 17.1386Z"
        fill="currentColor"
      />
      <path
        d="M13.4831 14.2556C11.9946 14.2658 10.5061 14.2632 9.01758 14.2556C8.49178 14.2531 8.26063 14.4918 8.26571 15.0151C8.27587 16.0261 8.26825 17.0396 8.27079 18.0506C8.27079 19.1022 8.27587 20.1538 8.27079 21.2054C8.26825 21.6931 8.47908 21.9446 8.98202 21.942C10.4909 21.9369 11.9997 21.9369 13.506 21.942C14.0064 21.942 14.2375 21.6931 14.2401 21.213C14.2426 19.1479 14.2401 17.0853 14.2401 15.0202C14.2401 14.5172 14.0191 14.248 13.4831 14.2505V14.2556ZM12.9853 16.59C12.7262 17.1894 12.3604 17.7457 12.1521 18.3604C11.9082 19.0818 11.7812 19.8439 11.5882 20.6593H10.2749C10.0438 19.0615 11.0192 18.0378 12.0505 16.9989C11.6593 16.971 11.3393 16.971 11.0243 16.9202C10.2572 16.7983 10.1327 16.8897 10.0717 17.6822C10.0667 17.7584 10.0514 17.8321 10.0362 17.9464H9.30462V15.5841C9.60181 15.6324 9.87361 15.6781 10.3003 15.7492C10.656 15.3428 11.2453 15.452 11.8625 15.7289C12.1115 15.8406 12.3782 16.0718 12.6347 15.6781C12.6957 15.5841 12.9522 15.6171 13.2571 15.5714C13.1529 15.9702 13.1097 16.2979 12.9827 16.5874L12.9853 16.59Z"
        fill="currentColor"
      />
      <path
        d="M13.4831 14.2556C11.9946 14.2658 10.5061 14.2632 9.01758 14.2556C8.49178 14.2531 8.26063 14.4918 8.26571 15.0151C8.27587 16.0261 8.26825 17.0396 8.27079 18.0506C8.27079 19.1022 8.27587 20.1538 8.27079 21.2054C8.26825 21.6931 8.47908 21.9446 8.98202 21.942C10.4909 21.9369 11.9997 21.9369 13.506 21.942C14.0064 21.942 14.2375 21.6931 14.2401 21.213C14.2426 19.1479 14.2401 17.0853 14.2401 15.0202C14.2401 14.5172 14.0191 14.248 13.4831 14.2505V14.2556ZM12.9853 16.59C12.7262 17.1894 12.3604 17.7457 12.1521 18.3604C11.9082 19.0818 11.7812 19.8439 11.5882 20.6593H10.2749C10.0438 19.0615 11.0192 18.0378 12.0505 16.9989C11.6593 16.971 11.3393 16.971 11.0243 16.9202C10.2572 16.7983 10.1327 16.8897 10.0717 17.6822C10.0667 17.7584 10.0514 17.8321 10.0362 17.9464H9.30462V15.5841C9.60181 15.6324 9.87361 15.6781 10.3003 15.7492C10.656 15.3428 11.2453 15.452 11.8625 15.7289C12.1115 15.8406 12.3782 16.0718 12.6347 15.6781C12.6957 15.5841 12.9522 15.6171 13.2571 15.5714C13.1529 15.9702 13.1097 16.2979 12.9827 16.5874L12.9853 16.59Z"
        fill="currentColor"
      />
    </svg>
  );
});

DegenBoostIcon.displayName = 'DegenBoostIcon';
