import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { CardProperty } from '@/systems/game/store/arena';

const emptyAttackValues: AttackValues = {
  activeParameter: null,
  player: {
    cardID: 0,
    boosterId: 0,
  },
  opponent: {
    cardID: 0,
    boosterId: 0,
  },
};

type AttackValues = {
  activeParameter: CardProperty | null;
  player: {
    cardID: number;
    boosterId: number;
  };
  opponent: {
    cardID: number;
    boosterId: number;
  };
};

type AttackValuesState = {
  attackValues: AttackValues;
  setActiveParameterAttackValues: (activeParameter: CardProperty | null) => void;
  setPlayerValues: (cardID: number, boosterId: number) => void;
  setPlayerCardValue: (cardID: number) => void;
  setOpponentValues: (cardID: number, boosterId: number) => void;
  getAttackValues: () => AttackValues;
  getActiveParameter: () => CardProperty | null;
  getPlayerValues: () => {
    cardID: number;
    boosterId: number;
  };
  getOpponentValues: () => {
    cardID: number;
    boosterId: number;
  };
  deleteAttackValues: () => void;
};

export const useAttackValueStore = create<AttackValuesState>()(
  devtools(
    persist(
      (set, get) => ({
        attackValues: emptyAttackValues,
        setActiveParameterAttackValues: (activeParameter: CardProperty | null) =>
          set((state) => ({ attackValues: { ...state.attackValues, activeParameter } })),
        setPlayerValues: (cardID: number, boosterId: number) =>
          set((state) => ({
            attackValues: {
              ...state.attackValues,
              player: {
                cardID,
                boosterId,
              },
            },
          })),
        setPlayerCardValue: (cardID: number) =>
          set((state) => ({
            attackValues: {
              ...state.attackValues,
              player: {
                ...state.attackValues.player,
                cardID,
              },
            },
          })),
        setOpponentValues: (cardID: number, boosterId: number) =>
          set((state) => ({
            attackValues: {
              ...state.attackValues,
              opponent: {
                cardID,
                boosterId,
              },
            },
          })),
        getAttackValues: () => {
          return get().attackValues;
        },
        getActiveParameter: () => {
          return get().attackValues.activeParameter;
        },
        getPlayerValues: () => {
          return get().attackValues.player;
        },
        getOpponentValues: () => {
          return get().attackValues.opponent;
        },
        deleteAttackValues: () => {
          set({
            attackValues: emptyAttackValues,
          });
        },
      }),
      {
        name: 'attack-values',
      }
    )
  )
);
