import React from 'react';

import { useCancelGame, useExpireGame, useGame } from '@/core/hooks';
import { cx } from '@/core/utils';

import { useArenaStore } from '../store';

export function SurrenderButton() {
  const { activeGameId, isExpired } = useArenaStore();

  const { status } = useGame({
    gameId: activeGameId || 0,
  });

  const { writeAsync: cancelGame, isLoading: isCancelGameLoading } = useCancelGame({
    gameId: activeGameId || 0,
    status,
  });

  const { writeAsync: expireGame, isLoading: isExpireGameLoading } = useExpireGame({
    gameId: activeGameId || 0,
    status,
  });

  const handleCancelGame = async () => {
    if (isExpired) {
      try {
        await expireGame?.();
      } catch (error) {
        console.error(error);
      }

      return;
    }

    try {
      await cancelGame?.();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <button
      className={cx(
        'h-9 border-[1.5px] border-yellow bg-yellow-thirtiary bg-opacity-20 px-4 text-sm font-bold text-yellow-thirtiary',
        'hover:bg-opacity-40 focus:outline-none focus:ring-2 focus:ring-yellow focus:ring-opacity-50',
        'disabled:cursor-not-allowed disabled:opacity-50'
      )}
      disabled={(isExpired && !expireGame) || isCancelGameLoading || isExpireGameLoading}
      onClick={handleCancelGame}
    >
      <p>{isExpired ? 'End Game' : 'surrender'}</p>
    </button>
  );
}
