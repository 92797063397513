export const routes = {
  root: '/',
  gameplay: '/gameplay',
  gameMechanics: '/mechanics',
  staking: '/staking',
  stakingLottery: '/staking/lottery',
  rentingMarket: {
    root: '/marketplace',
    borrow: '/marketplace/borrow',
    lend: '/marketplace/lend',
    lendWithdraw: '/marketplace/lend/withdraw',
    earnings: '/marketplace/earnings',
  },
  game: {
    root: '/game',
    play: '/game/play',
    lobby: '/game/lobby',
    hardcore: '/game/lobby?mode=hardcore',
    debug: '/game/debug',
    leaderboard: '/game/leaderboard',
    decks: '/game/decks',
    decksCreate: '/game/decks/create',
    decksEdit: '/game/decks/edit',
    mint: '/game/mint',
    mintSuccess: '/game/mint/success',
  },
  external: {
    medium: 'https://medium.com/@degenwars',
    gitbook: 'https://degen-wars.gitbook.io/war-docs',
    discord: 'https://discord.gg/degenwars',
    airdrop: 'https://www.zap.tech/drops/dropsdegenwars/',
  },
};
