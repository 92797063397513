import { cx } from '@/core/utils';
import { CardStackIcon } from '@/ui';

type CardsAmountProps = {
  cardsAmount: number;
};

export function CardsAmount(props: CardsAmountProps) {
  const { cardsAmount } = props;

  return (
    <div
      className={cx(
        'my-2 flex h-fit items-center gap-2 border border-purple-light bg-[#171617] px-2 py-2',
        '2xl:my-0'
      )}
    >
      <CardStackIcon color="gray" />
      <div className="flex-col items-center justify-center">
        <p className="whitespace-nowrap !font-display text-xs !capitalize">Card Stack</p>
        <div className="flex w-full text-center  text-base font-bold">
          <span className="text-teal">{cardsAmount || 0}</span>
          <span className="text-gray">/10</span>
        </div>
      </div>
    </div>
  );
}
