'use client';

import { Dispatch, SetStateAction, useState, useCallback } from 'react';
import Image, { StaticImageData } from 'next/image';
import { useLottie } from 'lottie-react';
import { LuHelpCircle } from 'react-icons/lu';

import { Tooltip } from '@/ui';
import arenaBoost from '@/assets/images/boosters/arena-effect-booster-pill.png';
import healthBoost from '@/assets/images/boosters/health-booster-pill.png';
import shieldBoost from '@/assets/images/boosters/shield-booster-pill.png';
import shieldAnimation from '@/assets/images/lottie/card-booster/shield-pill.json';
import healthAnimation from '@/assets/images/lottie/card-booster/health-pill.json';
import arenaEffectAnimation from '@/assets/images/lottie/card-booster/arena-pill.json';
import { GameSteps, useArenaStore } from '@/game';
import { GameMode } from '@/core/hooks';
import { cx } from '@/core/utils';

import { ActionCallout } from './CalloutActionHelper/ActionCallout';

export type Booster = {
  logo: StaticImageData;
  title: string;
  description: string;
  id: number;
};

type BoostersProps = {
  isAnimated: boolean;
  isAttacker: boolean;
  unusedBoosters: number[] | undefined;
  currentGameStep: GameSteps;
  setIsAnimated: Dispatch<SetStateAction<boolean>>;
  gameMode: GameMode | undefined;
};

const t = {
  title: '//: Choose Boosters',
  tooltip:
    "Arena: Neutralize arena effects on all cards for the next round, boosting both offensive and defensive capabilities. \n\n Shield: Shield your card from a potential loss. Neither player loses a card for this round, and you will lead the attack in the next round. \n\n Health: Replenishes a card's properties to full strength, enabling defensive fortification or offensive strikes at full strength.",
};

const BOOSTERS: Booster[] = [
  {
    logo: healthBoost,
    title: 'ENDURANCE',
    description: 'Healt: Restore all Properties',
    id: 1,
  },
  {
    logo: arenaBoost,
    title: 'ARENA',
    description: 'Arena: Disable Arena Effect',
    id: 2,
  },
  {
    logo: shieldBoost,
    title: 'SHIELD',
    description: 'Shield: One Round Protection',
    id: 3,
  },
];

export function Boosters(props: BoostersProps) {
  const { isAnimated, unusedBoosters, currentGameStep, setIsAnimated, gameMode = 1 } = props;
  const [animatedBoosterId, setAnimatedBoosterId] = useState<number | null>(null);

  const { activeBlind, activeBooster, setActiveBooster } = useArenaStore();

  const getActiveAnimation = (id: number) => {
    switch (id) {
      case 1:
        return healthAnimation;
      case 2:
        return arenaEffectAnimation;
      case 3:
        return shieldAnimation;
      default:
        return '';
    }
  };

  const boosterOptions = {
    animationData: animatedBoosterId && getActiveAnimation(animatedBoosterId),
    loop: !isAnimated,
    onComplete: () => {
      setIsAnimated(false);
    },
  };

  const { View: Booster } = useLottie(boosterOptions);

  const activateBooster = useCallback(
    (id: number) => {
      if (activeBooster === id) {
        setActiveBooster(0);
        setAnimatedBoosterId(null);
        setIsAnimated(false);

        return;
      }

      setActiveBooster(id);
      setAnimatedBoosterId(id);
      setIsAnimated(true);
    },
    [activeBooster]
  );

  const getIsBoosterUnused = (id: number) => {
    return unusedBoosters?.includes(id);
  };

  if (gameMode === GameMode.Hardcore) {
    return null;
  }

  return (
    <>
      <div
        className={cx(
          'foo-bar absolute z-40 overflow-clip',
          !isAnimated && 'hidden',
          'right-full top-0 w-full [transform:scale(-1,1)]',
          '2xl:w-[30vw]',
          'fhd:-top-24 fhd:w-[35vw]'
        )}
      >
        <div>{Booster}</div>
      </div>

      <div className={cx('pt-12', '2xl:pt-0')}>
        {/* title */}
        <div className={cx('relative')}>
          <TitleBg currentGameStep={currentGameStep} />
          <div
            className={cx('absolute bottom-0 z-10 flex w-full items-baseline gap-5 pb-[3.5%] pl-4')}
          >
            <BoosterIcon />
            <span
              className={cx(
                'translate-y-0.5 text-[0.625rem] text-green text-glow-green',
                'xl:tall:text-xs',
                '2xl:translate-y-0'
              )}
            >
              {t.title}
            </span>
            <Tooltip content={t.tooltip} delayDuration={300}>
              <div className={cx('absolute bottom-0 right-4 pb-[3.5%]')}>
                <LuHelpCircle className={cx('h-4 w-4 text-neutral-500', 'hover:text-white')} />
              </div>
            </Tooltip>
          </div>
        </div>

        {/* body */}
        <div className={cx('relative')}>
          {currentGameStep === 'complete turn' && (
            <ActionCallout
              type="DEFEND"
              className="absolute top-1/3 mr-8 -translate-x-full -translate-y-1/2"
            />
          )}

          <div
            className={cx(
              'absolute inset-0 grid w-full grid-cols-3 items-center gap-2 px-2',
              'gap-3 2xl:px-3'
            )}
          >
            {BOOSTERS.map((booster) => (
              <Tooltip key={booster.id} content={booster.description} delayDuration={100}>
                <button
                  className={cx(
                    'group relative flex flex-col items-center',
                    'disabled:cursor-not-allowed disabled:opacity-50 disabled:saturate-0'
                  )}
                  onClick={() => activateBooster(booster.id)}
                  disabled={currentGameStep === 'enemy turn' || !getIsBoosterUnused(booster.id)}
                >
                  <BoosterFrame booster={booster} isActive={activeBooster === booster.id} />
                  <Image
                    src={booster.logo}
                    alt="Booster"
                    className={cx(
                      'aspect-square w-full border border-red object-cover object-center',
                      booster.id === 1 && 'glow-green',
                      booster.id === 2 && 'glow-yellow',
                      booster.id === 3 && 'glow-blue'
                    )}
                  />

                  {/* price */}
                  <span
                    className={cx(
                      '-mt-px flex h-[1.375rem] w-full items-center justify-center border border-red font-display text-xs text-red',
                      'tall:text-sm'
                    )}
                  >
                    {Number(activeBlind.prize) / 10} Ξ
                  </span>
                </button>
              </Tooltip>
            ))}
          </div>

          <ContentBg currentGameStep={currentGameStep} />
        </div>
      </div>
    </>
  );
}

function TitleBg(props: { currentGameStep: GameSteps }) {
  const { currentGameStep } = props;

  return (
    <svg
      width="319"
      height="46"
      viewBox="0 0 319 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('h-auto w-full')}
    >
      <path
        d="M0.75 44.5455V44.7955H1H318H318.25V44.5455V11.6049V11.3549H318H57.5973L46.814 0.821166L46.7411 0.75H46.6393H1H0.75V1V44.5455Z"
        fill="#3A3838"
        fillOpacity="0.18"
        className={cx(
          currentGameStep === 'complete turn' || currentGameStep === 'defence'
            ? 'stroke-yellow'
            : 'stroke-red'
        )}
        strokeWidth="0.5"
      />
      <path
        opacity="0.44"
        d="M51.7385 1H94.095L101.694 9.20385H59.3378L51.7385 1Z"
        className={cx(
          currentGameStep === 'complete turn' || currentGameStep === 'defence'
            ? 'fill-yellow'
            : 'fill-red-900'
        )}
      />
      <path
        opacity="0.19"
        d="M98.0844 1.00006H140.45L148.04 9.20391H105.684L98.0844 1.00006Z"
        className={cx(
          currentGameStep === 'complete turn' || currentGameStep === 'defence'
            ? 'fill-yellow'
            : 'fill-red-900'
        )}
      />
    </svg>
  );
}

function BoosterIcon() {
  return (
    <svg
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('h-4 w-4', 'xl:h-5 xl:w-5', '2xl:h-6 2xl:w-6')}
    >
      <g clipPath="url(#clip0_14_1387)">
        <path
          d="M21.161 25.9998C18.5655 25.9998 16.4532 23.8562 16.4532 21.2209C16.4532 18.5081 20.1483 14.1979 20.57 13.7145C20.8681 13.3735 21.454 13.3735 21.7521 13.7145C22.1737 14.1968 25.8688 18.5081 25.8688 21.2209C25.8688 23.8562 23.7566 25.9998 21.161 25.9998Z"
          fill="#FBFBFB"
        />
        <path
          d="M18.8174 0.891479C16.9343 0.891479 15.1662 1.6238 13.8376 2.95245L2.82135 13.9687C1.4927 15.2973 0.760376 17.0654 0.760376 18.9485C0.760376 22.8403 3.91983 25.9997 7.81161 25.9997C9.69473 25.9997 11.4628 25.2674 12.7914 23.9388L14.4444 22.2858C14.3921 21.9406 14.3607 21.5849 14.3607 21.2187C14.3607 20.4968 14.5281 19.7331 14.8105 18.959L11.3163 22.4532C10.3852 23.3948 9.1298 23.9074 7.81161 23.9074C5.08109 23.9074 2.85273 21.679 2.85273 18.9485C2.85273 17.6303 3.36536 16.3854 4.30692 15.4438L9.07749 10.6732L15.5847 17.2014C15.9195 16.5528 16.3066 15.9251 16.6937 15.3392L10.5526 9.19813L15.3127 4.43802C16.2438 3.49646 17.4992 2.98383 18.8174 2.98383C21.5479 2.98383 23.7763 5.21219 23.7763 7.94272C23.7763 9.2609 23.2637 10.5059 22.3221 11.4474L22.1861 11.5834C22.6255 11.7299 23.023 11.981 23.3264 12.3367C23.452 12.4831 23.6194 12.6819 23.8181 12.9121C25.1363 11.5834 25.8686 9.82584 25.8686 7.94272C25.8686 4.05094 22.7092 0.891479 18.8174 0.891479Z"
          fill="#FBFBFB"
        />
      </g>
      <defs>
        <clipPath id="clip0_14_1387">
          <rect
            width="25.1083"
            height="25.1083"
            fill="white"
            transform="translate(0.759949 0.891235)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function ContentBg(props: { currentGameStep: GameSteps }) {
  const { currentGameStep } = props;

  return (
    <svg
      width="278"
      height="121"
      viewBox="0 0 278 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('-mt-[0.6%] h-auto w-full')}
    >
      <g filter="url(#filter0_b_28_54447)">
        <mask
          id="path-1-outside-1_28_54447"
          maskUnits="userSpaceOnUse"
          x="0"
          y="-0.381531"
          width="278"
          height="122"
          fill="black"
        >
          <rect fill="white" y="-0.381531" width="278" height="122" />
          <path d="M1 0.618469H277.278V120.287H1V0.618469Z" />
        </mask>
        <path d="M1 0.618469H277.278V120.287H1V0.618469Z" fill="#3A3838" fillOpacity="0.18" />
        <path
          d="M1 0.618469V0.1827H0.564231V0.618469H1ZM277.278 0.618469H277.713V0.1827H277.278V0.618469ZM277.278 120.287V120.722H277.713V120.287H277.278ZM1 120.287H0.564231V120.722H1V120.287ZM1 1.05424H277.278V0.1827H1V1.05424ZM276.842 0.618469V120.287H277.713V0.618469H276.842ZM277.278 119.851H1V120.722H277.278V119.851ZM1.43577 120.287V0.618469H0.564231V120.287H1.43577Z"
          className={cx(
            currentGameStep === 'complete turn' || currentGameStep === 'defence'
              ? 'fill-yellow'
              : 'fill-red'
          )}
          mask="url(#path-1-outside-1_28_54447)"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_28_54447"
          x="-35.1803"
          y="-35.562"
          width="348.638"
          height="192.029"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="17.8724" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_28_54447" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_28_54447"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

function BoosterFrame(props: { booster: Booster; isActive?: boolean }) {
  const { booster, isActive } = props;

  return (
    <svg
      width="92"
      height="92"
      viewBox="0 0 92 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(
        'pointer-events-none absolute left-0 top-0 z-10 hidden h-auto w-full',
        'group-hover:block',
        isActive && '!block'
      )}
    >
      <g clipPath="url(#clip0_16_2746)" filter="url(#filter0_b_16_2746)">
        <rect width="91.3068" height="91.3068" fill="#3A3838" fillOpacity="0.18" />
        <path
          d="M0.375153 0.375183H90.932V90.932H0.375153V0.375183Z"
          className={cx(
            booster.id === 1 && 'stroke-green',
            booster.id === 2 && 'stroke-yellow',
            booster.id === 3 && 'stroke-blue'
          )}
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M90.415 79.4578V90.3759H76.6702"
          className={cx(
            booster.id === 1 && 'stroke-green',
            booster.id === 2 && 'stroke-yellow',
            booster.id === 3 && 'stroke-blue'
          )}
          strokeWidth="2.091"
          strokeMiterlimit="10"
        />
        <path
          d="M0.697571 11.6096V0.697083H14.4493"
          className={cx(
            booster.id === 1 && 'stroke-green',
            booster.id === 2 && 'stroke-yellow',
            booster.id === 3 && 'stroke-blue'
          )}
          strokeWidth="2.091"
          strokeMiterlimit="10"
        />
        <path
          d="M82 46L91 39.9378L91 52.0622L82 46Z"
          className={cx(
            booster.id === 1 && 'fill-green',
            booster.id === 2 && 'fill-yellow',
            booster.id === 3 && 'fill-blue'
          )}
        />
        <path
          d="M9 46L-5.70966e-07 52.0622L-4.09935e-08 39.9378L9 46Z"
          className={cx(
            booster.id === 1 && 'fill-green',
            booster.id === 2 && 'fill-yellow',
            booster.id === 3 && 'fill-blue'
          )}
        />
      </g>
      <defs>
        <filter
          id="filter0_b_16_2746"
          x="-10.8642"
          y="-10.8642"
          width="113.035"
          height="113.035"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.43208" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_16_2746" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_16_2746"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_16_2746">
          <rect width="91.3068" height="91.3068" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
