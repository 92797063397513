import * as React from 'react';

export type LoadingProps = {
  value: number;
};

export function Loading(props: LoadingProps) {
  const { value } = props;

  return (
    <svg
      width="1059"
      height="115"
      viewBox="0 0 1059 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1059 94.7436H0.536743V70.3649H1059V94.7436ZM1.65437 93.6169H1057.88V71.4916H1.65437V93.6169Z"
        fill="currentColor"
      />

      <foreignObject width="calc(100% - 10px)" y="75px" x="5px" height="15px">
        <div
          aria-hidden
          className="h-[15px] bg-red transition-all"
          style={{ width: `${value}%` }}
        ></div>

        <progress className="sr-only" value={props.value}></progress>
      </foreignObject>

      <text x="75px" y="55px" className="fill-lime text-xl text-glow-lime">
        Loading...
      </text>

      <path
        d="M197.475 104.884L202.536 109.945H938.851L943.821 114.906H1036.36L1046.58 104.693L197.475 104.884Z"
        fill="currentColor"
      />
      <path
        d="M65.4134 0V60.8969H4.50739V0H65.4043H65.4134ZM64.0686 59.5521V1.34479H5.86125V59.5521H64.0686Z"
        fill="currentColor"
      />
      <path
        d="M34.8197 4.29781L34.8833 4.26147L57.1722 17.1278L57.2358 17.1641V42.9785L34.9469 55.8449L34.8833 55.8812L12.5944 43.0149L12.5308 42.9785V17.1641L34.8197 4.29781ZM34.8833 55.5813L56.9723 42.8241V17.3186L34.8833 4.56133L12.7943 17.3186V42.8241L34.8833 55.5813Z"
        fill="currentColor"
      />
      <path
        d="M50.2602 12.6812L49.6559 13.7278L57.0922 18.0211L57.6964 16.9745L50.2602 12.6812Z"
        fill="currentColor"
      />
      <path d="M57.6992 16.9733H56.4907V25.5599H57.6992V16.9733Z" fill="currentColor" />
      <path
        d="M12.8276 42.1576L12.2234 43.2042L19.6596 47.4975L20.2639 46.451L12.8276 42.1576Z"
        fill="currentColor"
      />
      <path d="M13.4303 34.6191H12.2218V43.2058H13.4303V34.6191Z" fill="currentColor" />
      <path
        d="M38.0274 51.8193L34.2746 53.9141L34.5714 54.4456L38.3242 52.3508L38.0274 51.8193Z"
        fill="currentColor"
      />
      <path
        d="M31.1945 51.7182L30.8825 52.241L34.5731 54.4436L34.8851 53.9208L31.1945 51.7182Z"
        fill="currentColor"
      />
      <path
        d="M34.6366 5.65998L30.8838 7.75476L31.1805 8.28634L34.9333 6.19155L34.6366 5.65998Z"
        fill="currentColor"
      />
      <path
        d="M34.6252 5.65704L34.3132 6.17981L38.0037 8.38243L38.3157 7.85966L34.6252 5.65704Z"
        fill="currentColor"
      />
      <path
        d="M55.1458 41.7428V18.6725L48.2765 14.5654V46.2133L55.1458 41.7428Z"
        fill="currentColor"
      />
      <path
        d="M19.9997 40.5705C19.9997 40.934 20.136 41.2975 20.4177 41.57C20.972 42.1243 21.8624 42.1243 22.4167 41.57L30.5308 33.465C31.0851 32.9107 31.0851 32.0203 30.5308 31.466C29.9766 30.9117 29.0861 30.9117 28.5318 31.466L20.4177 39.571C20.1451 39.8436 19.9997 40.2071 19.9997 40.5705Z"
        fill="currentColor"
      />
      <path
        d="M32.6661 27.9042C32.6661 28.2676 32.8024 28.6311 33.0841 28.9036C33.6383 29.4579 34.5288 29.4579 35.0831 28.9036L43.4153 20.5714C43.9696 20.0172 43.9696 19.1267 43.4153 18.5724C42.861 18.0182 41.9706 18.0182 41.4163 18.5724L33.0841 26.9046C32.8115 27.1772 32.6661 27.5407 32.6661 27.9042Z"
        fill="currentColor"
      />
      <path
        d="M19.9998 19.5629C19.9998 19.9264 20.1361 20.2898 20.4178 20.5624L28.75 28.8946C29.3042 29.4489 30.1947 29.4489 30.749 28.8946C31.3033 28.3403 31.3033 27.4499 30.749 26.8956L22.4168 18.5634C21.8625 18.0091 20.972 18.0091 20.4178 18.5634C20.1452 18.836 19.9998 19.1994 19.9998 19.5629Z"
        fill="currentColor"
      />
      <path
        d="M32.8933 32.4655C32.8933 32.8289 33.0295 33.1924 33.3112 33.465L41.4163 41.57C41.9705 42.1243 42.861 42.1243 43.4153 41.57C43.9695 41.0158 43.9695 40.1253 43.4153 39.571L35.3011 31.466C34.7469 30.9117 33.8564 30.9117 33.3021 31.466C33.0295 31.7386 32.8842 32.102 32.8842 32.4655H32.8933Z"
        fill="currentColor"
      />
    </svg>
  );
}
