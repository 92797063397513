import { useQuery } from 'wagmi';

import { PlayerStats } from '@/app/api/wins-and-losses/route';

type UseWinsAndLossesProps = {
  playerAddress: string;
  isEnabled: boolean;
};

export function useWinsAndLosses({ playerAddress, isEnabled }: UseWinsAndLossesProps) {
  const getPlayerStats = async (playerAddress: string) => {
    const url = window.location.origin || 'http://localhost:3000';
    const res = await fetch(`${url}/api/wins-and-losses`, {
      body: JSON.stringify({ playerAddress }),
      method: 'POST',
    });
    const data = (await res.json()) as PlayerStats;

    return data;
  };

  const { data, error, isLoading } = useQuery(
    ['playerStats'],
    () => getPlayerStats(playerAddress),
    {
      enabled: isEnabled,
    }
  );

  return { data, error, isLoading };
}
