import { cx } from '@/core/utils';
import { useArenaStore } from '@/game';
import {
  ApesBoostIcon,
  BearBoostIcon,
  BuilderBoostIcon,
  ChadBoostIcon,
  DegenBoostIcon,
  // PlebBoostIcon,
} from '@/ui/components';

type Category = 'Degen' | 'Bear' | 'Builder' | 'Chad' | 'Apes'; // | 'Pleb';

const iconProps = {
  className: cx('h-5 text-white'),
};

const ICONS = {
  Degen: <DegenBoostIcon {...iconProps} />,
  Bear: <BearBoostIcon {...iconProps} />,
  Builder: <BuilderBoostIcon {...iconProps} />,
  Apes: <ApesBoostIcon {...iconProps} />,
  Chad: <ChadBoostIcon {...iconProps} />,
  // Pleb: <PlebBoostIcon {...iconProps} />,
} as const;

function useArenaEffect(arenaId: number | undefined): {
  boosted: Category;
  weakened: Category;
} {
  switch (arenaId) {
    case 5:
      return {
        boosted: 'Builder',
        weakened: 'Bear',
      };
    case 4:
      return {
        boosted: 'Bear',
        weakened: 'Degen',
      };
    case 3:
      return {
        boosted: 'Chad',
        weakened: 'Apes',
      };
    case 2:
      return {
        boosted: 'Apes',
        weakened: 'Chad',
      };
    case 1:
      return {
        boosted: 'Degen',
        weakened: 'Builder',
      };
    default:
      return {
        boosted: 'Builder',
        weakened: 'Bear',
      };
  }
}

export function ArenaShuffleEffect() {
  const { activeArenaId } = useArenaStore();
  const { boosted, weakened } = useArenaEffect(activeArenaId);

  return (
    <div className={cx('mt-40 w-full')}>
      <h3
        className={cx('mb-4 border-b border-b-purple-light pb-1 text-xl font-bold')}
      >{`Category Boost`}</h3>

      <div className={cx('grid grid-cols-2 gap-2', 'sm:grid-cols-3', 'xl:grid-cols-6')}>
        {Object.entries(ICONS).map(([category, icon]) => {
          const isWeakened = category === weakened;
          const isBoosted = category === boosted;
          const isDefault = !isWeakened && !isBoosted;

          return (
            <div
              key={category}
              className={cx(
                'flex items-center justify-between border px-3 py-2 font-display',
                isWeakened && 'border-primary-500',
                isBoosted && 'border-green',
                isDefault && 'border-purple-light'
              )}
            >
              <div className={cx('flex items-center gap-2')}>
                <div className={cx(isDefault && 'opacity-50')}>{icon}</div>
                <span className={cx(isWeakened || isBoosted ? 'text-white' : 'text-neutral-500')}>
                  {category}
                </span>
              </div>

              <div className={cx('text-neutral-500')}>
                {isWeakened && (
                  <span
                    className={cx('text-xs text-primary-500 text-glow-primary-500')}
                  >{`-10%`}</span>
                )}
                {isBoosted && (
                  <span className={cx('text-xs text-green text-glow-green')}>{`+10%`}</span>
                )}
                {isDefault && '--'}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
