import React from 'react';

type Props = {
  className?: string;
};

function TimeSvg(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 174 170"
      className="h-auto w-full"
      {...props}
    >
      <g filter="url(#filter0_b_5828_90518)">
        <circle cx="84" cy="85.436" r="71" fill="#000" fillOpacity="0.3"></circle>
      </g>
      <path
        fill="#CC2733"
        d="M164.566 86.677l.015-.512 8.488.129-.01.564-8.493-.181zM164.308 91.66l.038-.512 4.342.335-.043.54-4.337-.363z"
      ></path>
      <path
        fill="#CC2733"
        d="M164.566 86.677l.015-.512 4.351.067-.014.54-4.352-.095zM164.308 91.66l.038-.512 4.342.335-.043.54-4.337-.363zM163.749 96.628l.067-.511 4.308.602-.067.54-4.308-.63zM162.869 101.554l.115-.507 4.265.875-.119.526-4.261-.894zM161.673 106.412l.139-.497 4.204 1.138-.149.521-4.194-1.162zM160.186 111.19l.163-.488 4.127 1.397-.177.516-4.113-1.425zM158.411 115.867l.192-.479 4.026 1.655-.201.507-4.017-1.683zM156.327 120.419l.235-.459 3.916 1.903-.249.483-3.902-1.927zM153.964 124.828l.258-.449 3.788 2.147-.268.469-3.778-2.167zM151.349 129.084l.277-.435 3.649 2.377-.297.459-3.629-2.401zM148.47 133.169c.105-.134.215-.268.311-.412l3.49 2.602c-.105.148-.215.287-.325.43l-3.476-2.62zM145.328 137.052l.335-.388 3.323 2.812-.354.412-3.304-2.836zM141.962 140.744l.359-.374 3.141 3.013-.373.392-3.127-3.031zM138.384 144.23l.378-.354 2.95 3.204-.396.368-2.932-3.218zM134.578 147.467l.406-.32 2.745 3.376-.426.339-2.725-3.395zM130.585 150.465l.421-.296 2.53 3.543-.44.311-2.511-3.558zM126.42 153.229l.435-.277 2.305 3.692-.459.292-2.281-3.707zM122.082 155.711l.459-.234 2.071 3.826-.483.253-2.047-3.845zM117.592 157.916l.468-.21 1.827 3.95-.493.224-1.802-3.964zM112.977 159.848l.478-.191 1.573 4.06-.502.201-1.549-4.07zM108.248 161.493l.492-.158 1.315 4.146-.516.172-1.291-4.16zM103.422 162.813l.502-.115 1.052 4.223-.531.124-1.023-4.232zM98.52 163.836l.508-.091.789 4.28-.536.096-.76-4.285zM93.576 164.558l.512-.062.516 4.323-.535.067-.493-4.328zM88.598 164.984l.517-.038.248 4.347-.54.038-.225-4.347zM83.615 165.07l.511.014-.019 4.352-.54-.009.048-4.357zM78.627 164.86l.517.038-.287 4.347-.54-.043.31-4.342zM73.659 164.348l.512.067-.555 4.318-.54-.072.583-4.313zM68.723 163.535l.507.096-.823 4.275-.535-.1.85-4.271zM63.85 162.401l.498.139-1.09 4.218-.521-.148 1.114-4.209zM59.058 160.957l.488.168-1.353 4.136-.512-.172 1.377-4.132zM54.362 159.226l.479.191-1.607 4.046-.507-.201 1.635-4.036zM49.782 157.218l.468.215-1.86 3.936-.493-.225 1.885-3.926z"
      ></path>
      <path
        fill="#CC2733"
        d="M49.781 157.218l.469.215-3.63 7.675-.516-.234 3.677-7.656zM51.51 147.379c5.313 2.787 10.954 4.956 16.81 6.281 2.912.731 5.89 1.139 8.87 1.507 2.99.244 5.998.436 9.011.323 3.013-.023 5.998-.374 8.977-.759 2.957-.515 5.908-1.071 8.779-1.943 5.777-1.62 11.305-4.066 16.476-7.108.663-.357 1.28-.787 1.915-1.184.628-.413 1.274-.793 1.886-1.229 1.218-.883 2.469-1.71 3.625-2.667 2.395-1.818 4.599-3.869 6.74-5.97 4.197-4.299 7.94-9.057 10.919-14.273 1.552-2.571 2.815-5.301 4.033-8.043l.821-2.095.414-1.048.357-1.065.725-2.129.611-2.164c1.643-5.772 2.43-11.77 2.543-17.784l.907.01c-.114 6.073-.907 12.138-2.56 17.972l-.618 2.186-.73 2.153-.363 1.076c-.13.357-.277.708-.413 1.059l-.833 2.113c-1.223 2.769-2.503 5.528-4.061 8.127-3.007 5.273-6.774 10.093-11.01 14.437-2.158 2.136-4.384 4.203-6.797 6.049-2.368 1.898-4.905 3.58-7.493 5.16-5.222 3.076-10.796 5.579-16.635 7.227l-2.186.618-2.215.509-1.104.261-1.122.198-2.237.402c-3.002.419-6.02.759-9.062.804-.759.012-1.518.051-2.277.046l-2.277-.057-1.138-.028-1.133-.091-2.271-.17c-3.013-.34-6.02-.764-8.972-1.478-2.968-.634-5.85-1.575-8.71-2.571-1.4-.578-2.838-1.065-4.203-1.728l-2.073-.934-2.022-1.037.119-.957-.023-.006z"
      ></path>
      <path
        fill="#CC2733"
        d="M148.266 108.123a67.769 67.769 0 002.928-10.892c.685-3.704 1.076-7.465 1.104-11.248l2.878.045c-.029 3.937-.437 7.862-1.15 11.724-.714 3.857-1.716 7.664-3.059 11.356l-2.701-.979v-.006zM42.862 153.463l.445.259-4.366 7.287-.493-.286 4.414-7.26zM38.682 150.724l.421.296-2.467 3.587-.44-.311 2.486-3.572z"
      ></path>
      <path
        fill="#CC2733"
        d="M42.862 153.463l.445.259-2.238 3.734-.469-.272 2.262-3.721zM38.682 150.724l.421.296-2.467 3.587-.44-.311 2.486-3.572zM34.675 147.739l.406.316-2.687 3.429-.426-.335 2.707-3.41zM30.854 144.521l.387.34-2.893 3.251-.406-.354 2.912-3.237zM27.249 141.063l.358.373-3.089 3.071-.373-.393 3.104-3.051zM23.868 137.386l.34.392-3.272 2.869-.358-.411 3.29-2.85zM20.712 133.517l.32.406-3.448 2.664-.334-.43 3.462-2.64zM17.799 129.457c.096.144.182.292.282.43l-3.6 2.444c-.106-.148-.202-.301-.302-.454l3.62-2.42zM15.164 125.211l.254.445-3.745 2.214-.273-.469 3.764-2.19zM12.782 120.811l.235.459-3.879 1.975-.248-.483 3.892-1.951zM10.668 116.278l.21.468-3.997 1.731-.22-.497 4.007-1.702zM8.866 111.61l.168.488-4.099 1.473-.177-.512 4.108-1.449zM7.355 106.838l.138.497-4.18 1.215-.148-.526 4.19-1.186zM6.135 101.984l.115.502-4.247.952-.124-.531 4.256-.923zM5.218 97.068l.086.507-4.3.683-.09-.535 4.304-.655zM4.633 92.104l.044.512-4.338.41-.043-.54 4.337-.382zM4.352 87.121l.014.512-4.352.144-.014-.54 4.352-.116zM4.37 82.134l-.014.516-4.351-.124.014-.54 4.352.148zM4.681 77.15l-.038.517-4.337-.392.043-.54 4.332.415zM5.322 72.196l-.09.507-4.305-.66.096-.536 4.299.689zM6.274 67.285l-.115.502-4.25-.928.119-.53 4.246.956zM7.526 62.436l-.143.497-4.184-1.19.148-.527 4.18 1.22zM9.067 57.678l-.168.487-4.103-1.453.177-.517 4.094 1.483zM10.922 53.03l-.21.468-4.008-1.707.225-.497 3.993 1.736zM13.065 48.506l-.234.459-3.888-1.956.244-.483 3.878 1.98zM15.475 44.125l-.258.445-3.759-2.195.268-.469 3.75 2.22zM18.148 39.902c-.1.139-.196.282-.287.426l-3.615-2.425c.096-.153.196-.3.306-.45l3.601 2.45h-.005zM21.094 35.867l-.316.406-3.457-2.645.334-.425 3.439 2.663zM24.274 32.017l-.335.387-3.285-2.855.353-.406 3.267 2.874zM27.68 28.363l-.355.369-3.103-3.051.373-.393 3.084 3.075zM31.323 24.944l-.388.34-2.912-3.243.411-.354 2.889 3.257zM35.163 21.75l-.402.32-2.701-3.414.425-.335 2.678 3.429zM39.19 18.794l-.422.297-2.482-3.577.44-.311 2.463 3.591z"
      ></path>
      <path
        fill="#CC2733"
        d="M39.19 18.794l-.421.297-4.845-6.978.464-.325 4.801 7.006zM46.874 25.187l-1.898 1.212-1.835 1.308-.917.652-.884.696-1.773 1.388-1.699 1.478-.855.737-.816.776-1.63 1.552-1.553 1.63-.781.816-.737.85-1.478 1.699-1.393 1.773-.697.883-.651.918-1.309 1.835-1.217 1.892c-.844 1.24-1.53 2.582-2.3 3.868-2.837 5.284-5.075 10.897-6.508 16.725-1.399 5.829-2.175 11.826-2.061 17.853.13 12.03 3.33 23.969 9.368 34.362 5.953 10.433 14.697 19.246 24.983 25.437l-.464.781c-2.583-1.586-5.12-3.262-7.482-5.171-2.408-1.852-4.64-3.919-6.786-6.06-4.236-4.344-7.986-9.181-10.988-14.454-6.11-10.49-9.373-22.554-9.526-34.703-.13-6.083.629-12.154 2.039-18.045.153-.742.374-1.467.583-2.197.21-.725.402-1.462.629-2.186l.73-2.153.369-1.076.419-1.059.832-2.113.94-2.072.465-1.037.515-1.014 1.037-2.028c5.805-10.642 14.301-19.868 24.57-26.348l.776.584-.017.011z"
      ></path>
      <path
        fill="#CC2733"
        d="M32.492 128.581c4.838 5.794 10.7 10.71 17.14 14.618l-1.479 2.464c-6.717-4.067-12.823-9.193-17.864-15.23l2.203-1.852zM45.89 14.667l-.449.254-4.136-7.418.492-.277 4.094 7.441zM50.347 12.4l-.468.21-1.88-3.926.493-.224 1.855 3.94z"
      ></path>
      <path
        fill="#CC2733"
        d="M45.89 14.667l-.449.253-2.119-3.801.47-.268 2.099 3.816zM50.347 12.4l-.468.21-1.88-3.926.493-.224 1.855 3.94zM54.944 10.416l-.479.191-1.63-4.036.507-.2 1.602 4.045zM59.648 8.718l-.487.163-1.373-4.132.517-.172 1.343 4.141zM64.45 7.303l-.497.138-1.11-4.208.526-.143 1.081 4.213zM69.338 6.227l-.507.09-.842-4.27.53-.095.819 4.275zM74.277 5.447l-.512.067-.574-4.318.536-.067.55 4.318zM79.25 4.969l-.51.038-.312-4.342.54-.043.283 4.347zM84.233 4.787l-.511.015-.038-4.357.54-.01.01 4.352zM89.221 4.936l-.516-.039.23-4.347.54.039-.254 4.347zM94.195 5.39l-.512-.062.497-4.323.54.067-.525 4.318zM99.135 6.15l-.507-.09.765-4.29.535.096-.793 4.284zM104.027 7.202l-.502-.114 1.033-4.233.531.125-1.062 4.222zM108.842 8.575l-.492-.163 1.301-4.156.511.173-1.32 4.146zM113.557 10.253l-.483-.186 1.555-4.065.506.196-1.578 4.055zM118.158 12.214l-.469-.21 1.808-3.96.497.22-1.836 3.95zM122.634 14.447l-.454-.234 2.051-3.84.479.248-2.076 3.826zM126.948 16.982l-.436-.278 2.286-3.706.459.292-2.309 3.692zM131.093 19.77l-.416-.302 2.511-3.553.444.316-2.539 3.539zM135.067 22.792l-.402-.316 2.731-3.39.426.335-2.755 3.371zM138.84 26.072l-.373-.354 2.932-3.218.397.373-2.956 3.2zM142.393 29.578l-.353-.373 3.127-3.027.373.392-3.147 3.008zM145.737 33.288l-.335-.387 3.309-2.826.354.411-3.328 2.802zM148.845 37.2c-.101-.139-.201-.277-.306-.411l3.476-2.616c.115.143.22.287.325.435l-3.495 2.597V37.2zM151.685 41.313l-.277-.435 3.634-2.396.292.46-3.649 2.371zM154.277 45.588l-.253-.45 3.773-2.16.272.468-3.792 2.142zM156.611 50.012l-.235-.46 3.907-1.927.244.488-3.916 1.899zM158.643 54.583l-.191-.478 4.017-1.678.201.506-4.027 1.65zM160.389 59.275l-.168-.488 4.118-1.42.172.511-4.122 1.397zM161.848 64.062l-.144-.498 4.199-1.157.148.521-4.203 1.134zM163.005 68.93c-.029-.168-.072-.335-.11-.502l4.26-.895c.039.177.086.354.115.531l-4.265.866zM163.832 73.865l-.067-.512 4.309-.626.067.54-4.309.598zM164.358 78.834l-.038-.517 4.337-.354.044.54-4.343.33z"
      ></path>
      <path
        fill="#CC2733"
        d="M164.358 78.834l-.038-.517 8.464-.693.043.569-8.469.64zM154.979 82.25c-.17-6.015-1.252-11.968-2.934-17.717-.181-.725-.464-1.427-.691-2.14-.249-.709-.459-1.428-.736-2.125-.561-1.387-1.065-2.803-1.716-4.157-1.184-2.758-2.656-5.38-4.18-7.963-3.177-5.086-6.904-9.867-11.26-14.007-2.129-2.124-4.48-4.004-6.853-5.845l-1.835-1.303-.918-.651-.951-.606-1.898-1.212-1.954-1.122-.974-.56-1.002-.51-2-1.03-2.05-.924-1.025-.464-1.048-.414-2.09-.827-2.129-.725c-1.411-.52-2.872-.866-4.305-1.291-5.805-1.512-11.803-2.26-17.818-2.305-6.021.005-12.02.77-17.836 2.231-5.788 1.552-11.424 3.733-16.657 6.69l-.442-.794c5.284-2.985 10.977-5.194 16.827-6.762a74.473 74.473 0 0118.017-2.272c6.083.04 12.138.793 18.011 2.306l2.186.623 1.093.311 1.076.368 2.153.725 2.112.838 1.059.414 1.037.464 2.073.935 2.028 1.03c.679.34 1.353.686 2.005 1.077l1.971 1.133c.651.39 1.28.815 1.92 1.217 1.297.788 2.503 1.716 3.749 2.583 2.407 1.84 4.78 3.75 6.944 5.88 4.412 4.173 8.207 8.976 11.424 14.114a72.14 72.14 0 017.482 16.52c1.727 5.806 2.815 11.816 3.047 17.893l-.901.38-.011-.006z"
      ></path>
      <path
        fill="#CC2733"
        d="M72.62 18.112c-7.43 1.263-14.595 3.874-21.182 7.522l-1.399-2.51c6.865-3.805 14.34-6.53 22.083-7.844l.499 2.832z"
      ></path>
      <defs>
        <filter
          id="filter0_b_5828_90518"
          width="212.059"
          height="212.059"
          x="-22.029"
          y="-20.594"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="17.515"></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_5828_90518"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5828_90518"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default TimeSvg;
