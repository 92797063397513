import * as React from 'react';

export const WalletIcon = React.forwardRef<SVGSVGElement, React.ComponentPropsWithoutRef<'svg'>>(
  (props, ref) => {
    return (
      <svg
        ref={ref}
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M23.2592 8.88625H22.0413V7.40109C22.0413 7.39816 22.0384 7.39502 22.0384 7.39209C22.0323 6.02097 21.0872 4.87508 19.8154 4.54502L19.8151 0.741003C19.8151 0.332937 19.4851 0 19.0741 0H2.96407C2.19606 0 1.43411 0.314942 0.867004 0.867004C0.314948 1.41906 0 2.18117 0 2.95507V16.2869C0 17.9158 1.32906 19.2419 2.96401 19.2419L19.08 19.2421C19.872 19.2421 20.616 18.9331 21.1741 18.3751C21.7352 17.8172 22.0411 17.0731 22.0411 16.2871V16.2841V14.8082H23.259C23.6671 14.8082 24 14.4752 24 14.0672V9.62722C24 9.21601 23.67 8.886 23.259 8.886L23.2592 8.88625ZM1.9082 1.92301C2.19322 1.64406 2.58015 1.48209 2.96415 1.48209H18.3392V4.44004H2.96415C2.14509 4.44004 1.48215 3.77393 1.48215 2.95804C1.48215 2.5709 1.63805 2.19004 1.90821 1.92303L1.9082 1.92301ZM20.5624 16.2872C20.5624 16.6772 20.4094 17.0462 20.1273 17.3283C19.8484 17.6072 19.4763 17.7634 19.0804 17.7634L2.96442 17.7631C2.14536 17.7631 1.48241 17.1031 1.48241 16.2901V5.50509C1.9204 5.76018 2.42136 5.91901 2.96442 5.91901H19.0804C19.8963 5.91901 20.5624 6.58512 20.5624 7.40102V7.40395V8.88301H17.4364C16.6443 8.88301 15.9033 9.19209 15.3513 9.74391C14.7902 10.2928 14.4814 11.0399 14.4814 11.841C14.4814 13.476 15.8075 14.805 17.4393 14.805H20.5653V16.2809C20.5624 16.2841 20.5624 16.2841 20.5624 16.2872L20.5624 16.2872ZM22.5184 13.3261H17.4364C16.6234 13.3261 15.9605 12.66 15.9605 11.8412C15.9605 11.4421 16.1105 11.0732 16.3924 10.7972C16.6745 10.5151 17.0373 10.3652 17.4364 10.3652H22.5215L22.5213 13.3261L22.5184 13.3261Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);

WalletIcon.displayName = 'WalletIcon';
