import * as React from 'react';
import Image, { type StaticImageData } from 'next/image';

import { cx } from '@/core/utils';
import { Arena } from '@/game/components/Search';

import { ArenaShuffleFrame } from './ArenaShuffleFrame';

type ArenaShuffleItemProps = {
  arenas: (Arena & {
    image: StaticImageData;
  })[];
  activeArenaId: number;
  className?: string;
};

export function ArenaShuffleItem(props: ArenaShuffleItemProps) {
  const { arenas, activeArenaId, className } = props;

  return (
    <ArenaShuffleFrame className={cx('h-auto w-full overflow-clip')}>
      <div className={cx('flex h-full flex-col', className)}>
        {Array.from({ length: 10 }).map((_, i) => {
          const arr = arenas.map((arena) => ({
            ...arena,
            rand: Math.random(),
          }));

          const pick = arr.find((item) => item.id === activeArenaId)!;
          const filteredArr = arr
            .filter((item) => item.id !== activeArenaId)
            .sort((a, b) => a.rand - b.rand);

          return (
            <React.Fragment key={i}>
              {[...filteredArr, pick].map((item, i) => (
                <div
                  key={i}
                  className={cx('flex h-full w-full shrink-0 items-center justify-center')}
                >
                  <Image
                    src={item.image}
                    alt={item.name}
                    width={435}
                    height={295}
                    className={cx('h-full w-full object-cover object-center')}
                  />
                </div>
              ))}
            </React.Fragment>
          );
        })}
      </div>
    </ArenaShuffleFrame>
  );
}
