'use client';

import { Dispatch, SetStateAction, useState, useCallback } from 'react';
import Image, { StaticImageData } from 'next/image';
import clsx from 'clsx';
import { useLottie } from 'lottie-react';

import { Tooltip } from '@/ui';
import arenaBoost from '@/assets/images/boosters/arena-boost.png';
import healthBoost from '@/assets/images/boosters/health-boost.png';
import shieldBoost from '@/assets/images/boosters/shield-boost.png';
import shieldAnimation from '@/assets/images/lottie/card-booster/shield-pill.json';
import healthAnimation from '@/assets/images/lottie/card-booster/health-pill.json';

import { GameSteps } from './Play';

import { useArenaStore } from '../store';

export type Booster = {
  logo: StaticImageData;
  title: string;
  description: string;
  id: number;
};

type BoostersProps = {
  isAnimated: boolean;
  isAttacker: boolean;
  unusedBoosters: number[] | undefined;
  currentGameStep: GameSteps;
  setIsAnimated: Dispatch<SetStateAction<boolean>>;
};

export function Boosters(props: BoostersProps) {
  const { isAnimated, isAttacker, unusedBoosters, currentGameStep, setIsAnimated } = props;
  const [animatedBoosterId, setAnimatedBoosterId] = useState<number | null>(null);

  const { activeBlind, activeBooster, setActiveBooster } = useArenaStore();

  const getActiveAnimation = (id: number) => {
    switch (id) {
      case 1:
        return healthAnimation;
      case 3:
        return shieldAnimation;
      default:
        return '';
    }
  };

  const boosterOptions = {
    animationData: animatedBoosterId && getActiveAnimation(animatedBoosterId),
    loop: !isAnimated,
    onComplete: () => {
      setIsAnimated(false);
    },
  };

  const { View: Booster } = useLottie(boosterOptions);

  const BOOSTERS: Booster[] = [
    {
      logo: healthBoost,
      title: 'ENDURANCE',
      description: 'Replenishes the endurance for the next move',
      id: 1,
    },
    {
      logo: arenaBoost,
      title: 'ARENA',
      description: 'Disables the arena effect for your own card',
      id: 2,
    },
    {
      logo: shieldBoost,
      title: 'SHIELD',
      description: 'Protects your card for the next round',
      id: 3,
    },
  ];

  const activateBooster = useCallback(
    (id: number) => {
      if (activeBooster === id) {
        setActiveBooster(0);
        setAnimatedBoosterId(null);
        setIsAnimated(false);

        return;
      }

      setActiveBooster(id);
      setAnimatedBoosterId(id);
      setIsAnimated(true);
    },
    [activeBooster]
  );

  const getIsBoosterUnused = (id: number) => {
    return unusedBoosters?.includes(id);
  };

  return (
    <>
      {isAnimated && (
        <div
          className={clsx(
            'absolute z-40 overflow-hidden',
            !isAttacker
              ? 'right-[400px] rotate-180 lg:bottom-[25%] xl:bottom-40 2xl:bottom-28'
              : 'left-[270px] xl:top-40 2xl:top-28'
          )}
        >
          <div className="lg:w-[45%] xl:w-[60%] 2xl:w-[100%]">{Booster}</div>
        </div>
      )}
      {BOOSTERS.map((booster) => (
        <Tooltip
          key={booster.id}
          content={
            <div className="space-y-4">
              <h1 className="font-bold">{booster.title} Booster</h1>
              <p className="font-display font-normal text-white/50">{booster.description}</p>
            </div>
          }
        >
          <button
            className={clsx(
              'flex flex-col items-center space-y-2',
              'disabled:cursor-not-allowed disabled:opacity-50 disabled:saturate-0',
              getIsBoosterUnused(booster.id)
                ? [
                    booster.id === 1 && 'hover:boost-glow-green',
                    booster.id === 2 && 'hover:boost-glow-purple-700',
                    booster.id === 3 && 'hover:boost-glow-blue-300',
                  ]
                : 'cursor-not-allowed opacity-50 saturate-0',
              activeBooster === booster.id && [
                booster.id === 1 && 'boost-glow-green',
                booster.id === 2 && 'boost-glow-purple-700',
                booster.id === 3 && 'boost-glow-blue-300',
              ]
            )}
            onClick={() => activateBooster(booster.id)}
            disabled={currentGameStep === 'enemy turn' || !getIsBoosterUnused(booster.id)}
          >
            <Image src={booster.logo} alt="Booster" />
            <span
              className={clsx(
                'h-9 w-full bg-red/30 p-1 font-display text-cyan',
                getIsBoosterUnused(booster.id) &&
                  'bg-red-500 border-2 border-red-900 bg-opacity-60 glow-red-900'
              )}
            >
              {Number(activeBlind.prize) / 10} Ξ
            </span>
          </button>
        </Tooltip>
      ))}
    </>
  );
}
