export function ClaimPrize() {
  return (
    <svg
      width="480"
      height="369"
      viewBox="0 0 480 369"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M422.148 359.958H9.04297V14.4106H63.86L79.1697 29.7203H461.912L479.739 47.5474V302.341L422.123 359.958H422.148ZM11.6374 357.389H421.095L477.196 301.288V48.6263L460.885 32.2891H78.1165L62.8068 16.9794H11.6374V357.389V357.389Z"
        fill="#991D26"
      />
      <path d="M10.3242 347.217H72.1539L84.6636 358.673H10.3242V347.217Z" fill="#991D26" />
      <path
        d="M450.997 25.4047C452.728 25.4047 454.131 24.0016 454.131 22.2708C454.131 20.54 452.728 19.137 450.997 19.137C449.266 19.137 447.863 20.54 447.863 22.2708C447.863 24.0016 449.266 25.4047 450.997 25.4047Z"
        fill="#991D26"
      />
      <path
        d="M439.79 25.4047C441.521 25.4047 442.924 24.0016 442.924 22.2708C442.924 20.54 441.521 19.137 439.79 19.137C438.059 19.137 436.656 20.54 436.656 22.2708C436.656 24.0016 438.059 25.4047 439.79 25.4047Z"
        fill="#991D26"
      />
      <path
        d="M425.457 22.2708C425.457 20.5241 426.87 19.137 428.591 19.137C430.312 19.137 431.725 20.5498 431.725 22.2708C431.725 23.9919 430.312 25.4047 428.591 25.4047C426.87 25.4047 425.457 23.9919 425.457 22.2708Z"
        fill="#991D26"
      />
      <path
        opacity="0.15"
        d="M24.7812 50.2448H456.407L466.322 60.1601V290.499L413.56 343.236H100.944L88.3833 330.674H24.7812V50.2448Z"
        fill="#991D26"
      />
      <path d="M60.9542 0H10.3242V6.29337H60.9542V0Z" fill="#991D26" />
      <path d="M10.3263 358.674H0V369H10.3263V358.674Z" fill="#991D26" />
      <path
        d="M37.7551 353.947H36.445V353.279H35.7772V353.947H34.4414V353.279H35.1093V352.611H35.7772V351.943H36.445V352.611H37.1129V353.279H37.7808V353.947H37.7551Z"
        fill="black"
      />
      <path
        d="M43.2456 353.947H41.9099V353.279H41.242V353.947H39.9062V353.279H40.5741V352.611H41.242V351.943H41.9099V352.611H42.5777V353.279H43.2456V353.947V353.947Z"
        fill="black"
      />
      <path
        d="M48.73 353.947H47.3942V353.279H46.7263V353.947H45.3906V353.279H46.0585V352.611H46.7263V351.943H47.3942V352.611H48.0621V353.279H48.73V353.947V353.947Z"
        fill="black"
      />
      <path
        d="M54.1948 353.947H52.8591V353.279H52.1912V353.947H50.8555V353.279H51.5234V352.611H52.1912V351.943H52.8591V352.611H53.527V353.279H54.1948V353.947V353.947Z"
        fill="black"
      />
      <path
        d="M342.05 336.069H340.715V335.401H340.047V330.777H340.715V330.109H342.05V330.777H341.383V335.401H342.05V336.069Z"
        fill="#991D26"
      />
      <path
        d="M348.19 335.401H346.88V333.423H344.902V335.401H343.566V330.777H344.902V332.755H346.88V330.777H348.19V335.401Z"
        fill="#991D26"
      />
      <path
        d="M353.663 335.401H352.353V334.065H350.375V335.401H349.039V332.087H349.707V331.419H350.375V330.751H352.353V331.419H353.021V332.087H353.688V335.401H353.663ZM352.353 333.423V332.087H351.685V331.419H351.017V332.087H350.349V333.423H352.327H352.353Z"
        fill="#991D26"
      />
      <path
        d="M359.134 335.401H355.82V334.733H355.152V334.091H354.484V332.113H355.152V331.445H355.82V330.777H359.134V331.445H356.488V332.113H355.82V334.091H356.488V334.759H357.824V333.449H357.156V332.781H359.134V335.427V335.401Z"
        fill="#991D26"
      />
      <path d="M364.632 336.069H360.008V335.401H364.632V336.069Z" fill="#991D26" />
      <path
        d="M370.096 335.401H368.761V333.423H366.783V335.401H365.473V330.777H366.783V332.755H368.761V330.777H370.096V335.401Z"
        fill="#991D26"
      />
      <path
        d="M375.571 335.401H374.236V334.065H372.258V335.401H370.922V332.087H371.59V331.419H372.258V330.751H374.236V331.419H374.903V332.087H375.571V335.401ZM374.236 333.423V332.087H373.568V331.419H372.9V332.087H372.232V333.423H374.21H374.236Z"
        fill="#991D26"
      />
      <path
        d="M381.04 335.401H377.726V334.733H377.059V334.091H376.391V332.113H377.059V331.445H377.726V330.777H381.04V331.445H378.394V332.113H377.726V334.091H378.394V334.759H379.73V333.449H379.062V332.781H381.04V335.427V335.401Z"
        fill="#991D26"
      />
      <path
        d="M382.562 335.401H383.23V330.777H382.562V330.109H383.898V330.777H384.566V335.401H383.898V336.069H382.562V335.401Z"
        fill="#991D26"
      />
      <path
        d="M390.681 334.733V335.401H388.703V334.733H388.035V334.065H387.367V332.087H388.035V331.419H388.703V334.065H389.371V334.733H390.707H390.681ZM391.991 334.065H391.323V334.733H390.655V332.087H389.987V331.419H388.652V330.751H390.63V331.419H391.297V332.087H391.965V334.065H391.991Z"
        fill="#991D26"
      />
      <path
        d="M397.485 334.733H396.817V335.401H393.504V334.733H392.836V334.065H394.146V334.733H396.15V333.423H394.146V332.755H394.814V332.087H395.482V331.419H393.504V330.751H397.485V331.419H396.817V332.087H396.15V332.755H396.817V333.423H397.485V334.733Z"
        fill="#991D26"
      />
      <path
        d="M399.004 332.755H398.336V331.419H399.004V330.751H401.65V331.419H402.317V332.755H401.65V331.419H399.672V332.087H400.34V332.755H401.65V333.423H402.985V334.733H402.317V335.401H399.004V334.733H398.336V333.423H399.004V332.755V332.755ZM399.004 334.733H401.65V334.065H400.34V333.397H399.004V334.707V334.733Z"
        fill="#991D26"
      />
      <path
        d="M408.423 334.091H407.755V335.427H406.419V334.091H403.773V332.781H404.441V332.113H405.109V331.445H405.777V330.777H407.755V333.423H408.423V334.091ZM406.445 333.423V332.087H405.777V332.755H405.109V333.423H406.419H406.445Z"
        fill="#991D26"
      />
    </svg>
  );
}
