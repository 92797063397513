'use client';

import { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';

import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

export type UsePlayerTwoTopCardProps = {
  gameId: number;
  secret: string;
  isEnabled?: boolean;
};

export function usePlayerTwoTopCard(props: UsePlayerTwoTopCardProps) {
  const { gameId, secret, isEnabled = false } = props;
  const [playerTwoTopCard, setPlayerTwoTopCard] = useState<number | null>(null);
  const [isPlayerTwoTopCardError, setIsPlayerTwoTopCardError] = useState<boolean>();
  const [isPlayerTwoTopCardLoading, setIsPlayerTwoTopCardLoading] = useState<boolean>();

  const { data, isError, isLoading, refetch } = useContractRead({
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'getPlayerTwoTopCard',
    enabled: isEnabled,
    args: [BigInt(gameId), BigInt(secret)],
  });

  useEffect(() => {
    isError && setIsPlayerTwoTopCardError(isError);
    isLoading && setIsPlayerTwoTopCardLoading(isLoading);
    if (data) {
      setPlayerTwoTopCard(Number(data as bigint));
    }
  }, [data, isError, isLoading]);

  return {
    isPlayerTwoTopCardError,
    isPlayerTwoTopCardLoading,
    playerTwoTopCard,
    refetch,
  };
}
