import { useContractWrite, usePrepareContractWrite } from 'wagmi';

import { useArenaStore } from '@/game';
import { CONTRACTS, BLAST_SEPOLIA_ID, DEGEN_WARS_ABI } from '@/core/lib';

import { GameStatuses } from '../queries/useGame';

export type UseExpireGameProps = {
  gameId: number;
  status: GameStatuses;
};

export function useExpireGame(props: UseExpireGameProps) {
  const { gameId, status } = props;

  const { isExpired } = useArenaStore();

  const { config, refetch, isRefetching } = usePrepareContractWrite({
    chainId: BLAST_SEPOLIA_ID,
    address: CONTRACTS[BLAST_SEPOLIA_ID].DEGEN_WARS,
    abi: DEGEN_WARS_ABI,
    functionName: 'expireGame',
    enabled:
      status === GameStatuses.Created ||
      status === GameStatuses.Joined ||
      (isExpired && status === GameStatuses.Started),
    args: [BigInt(gameId)],
  });

  return {
    ...useContractWrite(config),
    refetch,
    isRefetching,
  };
}
