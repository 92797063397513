'use client';

import * as React from 'react';
import { Dialog } from '@headlessui/react';

import { cx } from '@/core/utils';

type FeesDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export function FeesDialog(props: FeesDialogProps) {
  const { isOpen, handleClose } = props;

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className="fixed inset-0 z-10 bg-black/50 backdrop-blur" aria-hidden="true" />

      <div className="fixed inset-0 z-20 flex w-screen items-center justify-center p-4">
        <Dialog.Panel
          className={cx('mx-auto max-w-md rounded border border-red bg-black p-6 shadow-2xl')}
        >
          <div className="flex-col items-center justify-center">
            <Dialog.Title className={cx('pb-6 text-center text-3xl font-bold text-neutral-400')}>
              {`Notice: Initial Transaction Fees`}
            </Dialog.Title>
            <div className="mx-auto mb-7 flex w-4/5">
              <span className="text-center font-display text-base font-normal text-white [text-wrap:pretty]">
                {`The presented fees in the MetaMask tab include the selected blind and the estimated gas fees.`}
              </span>
            </div>

            <div className={cx('flex gap-2')}>
              <button
                className={cx(
                  'mb-3 h-10 w-full border border-yellow bg-yellow font-display text-base font-normal text-black opacity-90',
                  'hover:opacity-100'
                )}
                onClick={handleClose}
              >
                {`I understand`}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
